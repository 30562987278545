import {
  CartItemUpdate,
  Extra,
  ExtraModels,
  ProductEntity
} from '../../types/type';
import {
  addToCart,
  addToWishlist,
  updateCartItemQuantity
} from '../../api/CartApi';
import {
  addToCartWithoutLogin,
  createAddToCartPayload
} from '../../redux/actions/cart';
import {
  getCartCountAction,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import {
  getProductById,
  getSimilarProductsById
} from '../../api/ProductScreen';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Accordion } from '../../components/common/Accordion/Accordion';
import { config as CONFIG } from '../../config/config';
import Carousel from 'react-multi-carousel';
import CountStepper from '../../components/common/CountStepper/CountStepper';
import { CustomButtonGroupAsArrows } from '../../components/CategoryComponent/CategoryComponent';
import { Helmet } from 'react-helmet';
import ProductHeader from '../../components/ProductListing/ProductHeader';
import { RootState } from '../../redux/reducers';
import SimilarProductCard from './SimilarProductCard';
import appconfig from '../../config.json';
import { checkouts } from '../../assets/images/product-screen';
import { getLoginCart } from '../../redux/actions/loginCart';
import { getPaymentOptions } from '../../api/company';
import icons from '../../assets/icons';
import { openLoginModal } from '../../redux/actions/modals';
import reviews from '../../assets/images/landing-page/reviews.svg';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/WindowDimensionsHook';

const ProductScreen = (props: any) => {
  const [responsiveness, setresponsiveness] = useState({
    monitor: {
      breakpoint: { max: 5000, min: 1920 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1920, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  });
  const params = useParams();
  const { width } = useWindowDimensions();
  /**
   * setproduct - function to set product in state
   */
  const [product, setproduct] = useState<ProductEntity>();
  /**
   * setselectedIndex - function to set index of image carousel array
   */
  const [selectedIndex, setselectedIndex] = useState(0);
  /**
   * setproductQuantity - function to set quantity of product
   */
  const [productQuantity, setproductQuantity] = useState(1);
  /**
   * setsimilarProducts - function to set array of similar products
   */
  const [similarProducts, setsimilarProducts] = useState([]);
  const [paymentOption, setpaymentOption] = useState<any>({});
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [extraModels, setextraModels] = useState<Array<ExtraModels>>([]);
  const [selectedExtras, setselectedExtras] = useState<Array<Extra>>([]);
  const [extrasPrice, setextrasPrice] = useState(0);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const userCart = useSelector((state: RootState) => state.cart);
  const config = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();

  /**
   * getProduct - async function to call api for getting details of given product
   */
  const getProduct = async () => {
    try {
      if (params.id) {
        const data = await getProductById(params.id, CONFIG.COMPANY_NO);
        setproduct({ ...data });
        setIsWishlisted(data.wishlist);
        if (data.extraModels.length !== 0) {
          setextraModels(data.extraModels);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setselectedIndex(0);
    /**
     * getPaymentOption - function to get payment options for given company
     */
    const getPaymentOption = async () => {
      try {
        const data = await getPaymentOptions();
        setpaymentOption(data);
      } catch (err) {
        console.error(err);
      }
    };
    /**
     * getSimilarProducts - function to fetch similar products from api call
     */
    const getSimilarProducts = async () => {
      try {
        if (params.id) {
          const data = await getSimilarProductsById(
            params.id,
            CONFIG.COMPANY_NO
          );
          setsimilarProducts(data['data']['products']);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getProduct();
    getSimilarProducts();
    getPaymentOption();
  }, [params]);

  /**
   * To set default selected add ons
   */
  useEffect(() => {
    if (extraModels.length !== 0) {
      const selected = extraModels.map((model: any) => {
        return model.groupValue[0];
      });
      setselectedExtras(selected);
    }
  }, [extraModels]);

  /**
   * to toggle ad ons price
   */
  useEffect(() => {
    let cost = 0;
    if (selectedExtras.length !== 0) {
      selectedExtras.forEach((extra: any) => {
        cost += parseInt(extra.value);
      });
    }
    setextrasPrice(cost);
  }, [selectedExtras]);

  /**
   * changeSelectedIndex - function to change selected index
   * @param {string} direction - increase or decrease the index
   */
  const changeSelectedIndex = (direction: string) => {
    switch (direction) {
      case 'previous': {
        if (selectedIndex !== 0) {
          setselectedIndex((selectedIndex) => selectedIndex - 1);
        }
        break;
      }
      case 'next': {
        if (product) {
          if (selectedIndex !== product?.subImageUrl.length - 1) {
            setselectedIndex((selectedIndex) => selectedIndex + 1);
          }
        }
        break;
      }
      default: {
        setselectedIndex(selectedIndex);
      }
    }
  };

  /**
   * changeProductQuantity - function to change quantity of product
   * @param {string} action - increase or decrease the quantity
   */
  const changeProductQuantity = (action: string) => {
    switch (action) {
      case 'increase': {
        setproductQuantity((productQuantity) => productQuantity + 1);
        break;
      }
      case 'decrease': {
        if (productQuantity > 1) {
          setproductQuantity((productQuantity) => productQuantity - 1);
        }
        break;
      }
      default: {
        setproductQuantity(productQuantity);
      }
    }
  };

  /**
   * wishList - Function to add product in wishlist
   */
  const wishList = async () => {
    if (isLoggedIn) {
      if (product) {
        const data = await addToWishlist(product?.id);
        if (data) {
          toast.success('Item added to wishlist');
          dispatch(getWishListCountAction());
          getProduct();
        }
      }
    } else {
      dispatch(openLoginModal());
    }
  };

  /**
   * wishList - Function to remove product from wishlist
   */
  const removeFromWishlist = async () => {
    const requestBody: CartItemUpdate = {
      cart: false,
      extra: [],
      product: product!.id,
      status: true,
      wishlist: true
    };
    const res = await updateCartItemQuantity(requestBody);
    if (res.status === 200) {
      toast.success('Item removed from wishlist');
      dispatch(getWishListCountAction());
    } else {
      toast.error('Something went wrong!');
    }
  };

  /**
   * cart - Function to add product in cart
   */
  const cart = async () => {
    if (isLoggedIn) {
      if (productQuantity > 0 && product) {
        const data = await addToCart(
          product?.id,
          productQuantity,
          selectedExtras
        );
        if (data) {
          toast.success('Item added to cart');
          dispatch(getCartCountAction());
          dispatch(getLoginCart());
          getProduct();
        }
      }
    } else {
      if (userCart?.length) {
        if (!userCart.some((payload) => payload.product === product?.id)) {
          dispatch(
            addToCartWithoutLogin(
              createAddToCartPayload(
                product?.id || '',
                productQuantity,
                [],
                product?.productName || '',
                product?.compressProductImageUrl || '',
                product?.afterDiscountPrice || 0
              )
            )
          );
          toast.success('Item added to cart');
        } else {
          toast.success('Item already exists in your cart');
        }
      } else {
        dispatch(
          addToCartWithoutLogin(
            createAddToCartPayload(
              product?.id || '',
              productQuantity,
              [],
              product?.productName || '',
              product?.compressProductImageUrl || '',
              product?.afterDiscountPrice || 0
            )
          )
        );
        toast.success('Item added to cart');
      }
    }
  };

  /**
   * changeExtra - function to toggle selection of extra add ons
   * @param {Extra} - add on
   */
  const changeExtra = (extra: Extra) => {
    if (!extra.allowMultiSelect) {
      const filtered = [
        ...selectedExtras.filter((e: any) => e.type !== extra.type),
        extra
      ];
      setselectedExtras(filtered);
    } else {
      setselectedExtras((selectedExtras: Array<Extra>) => [
        ...selectedExtras,
        extra
      ]);
    }
  };

  return (
    <>
      <Helmet>
        <title>{product?.productName}</title>
        <meta name="title" content={product?.productName} />
        <meta name="keywords" content={product?.searchWords || ''} />
        <meta property="og:image" content={product?.compressProductImageUrl} />
        <meta property="og:title" content={product?.productName} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet>
      <section className="">
        {product ? (
          <>
            <ProductHeader
              breadcrumbArray={[
                'home',
                product.categoryName,
                product.productName
              ]}
              productCategory={product.categoryName}
            />
            <section className="grid grid-cols-1 grid-rows-12 lg:grid-cols-2 md:grid-cols-2 pb-20 ">
              <section className="h-full lg:mt-10">
                <section className="grid grid-rows-12 grid-cols-1 lg:grid-rows-1 lg:grid-cols-12 lg:gap-4 px-5 lg:px-12">
                  <section className="ipad-lg:h-104 col-span-3 space-y-1 flex flex-row h-full lg:flex-col ">
                    <section className="hidden lg:flex space-x-2 mb-3 justify-center flex-row mt-5">
                      <section
                        onClick={() => changeSelectedIndex('previous')}
                        className="bg-white grid place-items-center space-x-2 cursor-pointer rounded-full h-10 w-10">
                        {selectedIndex === 0 ? (
                          <img
                            className="opacity-50"
                            src={icons.upArrow}
                            alt=""></img>
                        ) : (
                          <img src={icons.upArrow} alt=""></img>
                        )}
                      </section>
                      <section
                        onClick={() => changeSelectedIndex('next')}
                        className="bg-white grid place-items-center cursor-pointer rounded-full h-10 w-10">
                        {selectedIndex === product.subImageUrl.length - 1 ? (
                          <img
                            className="opacity-50"
                            src={icons.downArrow}
                            alt=""></img>
                        ) : (
                          <img src={icons.downArrow} alt=""></img>
                        )}
                      </section>
                    </section>
                    <section className="grid place-items-center mx-auto">
                      <section
                        className={`grid grid-cols-6 grid-rows-1 gap-1 lg:grid-cols-1 lg:grid-rows-4 place-items-center`}>
                        <section
                          onClick={() => changeSelectedIndex('previous')}
                          className="h-10 w-10 lg:hidden grid place-items-center  cursor-pointer ">
                          {selectedIndex === 0 ? (
                            <img
                              className="opacity-50"
                              src={icons.leftArrow}
                              alt=""></img>
                          ) : (
                            <img src={icons.leftArrow} alt=""></img>
                          )}
                        </section>
                        {product.subImageUrl.map((url: string, index: any) =>
                          index < 4 ? (
                            <section
                              key={index}
                              className={
                                index === 0 ? 'col-start-2 lg:col-start-1' : ''
                              }>
                              <img
                                onClick={() => setselectedIndex(index)}
                                key={index}
                                className={
                                  'bg-white cursor-pointer w-20 h-24 mt-3' +
                                  (index === selectedIndex
                                    ? ''
                                    : ' opacity-50')
                                }
                                src={url}
                                alt=""
                              />
                            </section>
                          ) : null
                        )}
                        <section
                          onClick={() => changeSelectedIndex('next')}
                          className="h-10 w-10 col-start-6 lg:hidden grid place-items-center cursor-pointer">
                          {selectedIndex === product.subImageUrl.length - 1 ? (
                            <img
                              className="opacity-50"
                              src={icons.rightArrow}
                              alt=""></img>
                          ) : (
                            <img src={icons.rightArrow} alt=""></img>
                          )}
                        </section>
                      </section>
                    </section>
                  </section>
                  <section className="h-96 lg:h-104 mt-5 overflow-hidden order-first lg:order-last row-span-10 lg:col-span-9">
                    <section
                      style={{
                        background: `url(${product.subImageUrl[selectedIndex]})`
                      }}
                      className="grid grid-cols-12 place-items-center bg-center bg-contain bg-white mx-auto bg-no-repeat h-full w-full border">
                      <section
                        onClick={() => changeSelectedIndex('previous')}
                        className="h-10 w-10 col-span-2 grid place-items-center  cursor-pointer border-0">
                        {selectedIndex === 0 ? (
                          <img
                            className="opacity-50 hidden lg:block"
                            src={icons.leftArrow}
                            alt=""></img>
                        ) : (
                          <img
                            className="hidden lg:block"
                            src={icons.leftArrow}
                            alt=""></img>
                        )}
                      </section>
                      <section className="col-span-8 bg-cover"></section>
                      <section
                        onClick={() => changeSelectedIndex('next')}
                        className="h-10 w-10 col-span-2 grid place-items-center cursor-pointer  border-0 ">
                        {selectedIndex === product.subImageUrl.length - 1 ? (
                          <img
                            className="opacity-50 hidden lg:block"
                            src={icons.rightArrow}
                            alt=""></img>
                        ) : (
                          <img
                            className="hidden lg:block"
                            src={icons.rightArrow}
                            alt=""></img>
                        )}
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section className="h-auto">
                <section>
                  <section className="hidden md:block lg:block md:mt-5 lg:mt-14 lg:mx-8">
                    <h1
                      className="text-text-title text-xl font-bold"
                      dangerouslySetInnerHTML={{
                        __html: product['productName']
                      }}></h1>
                    <h2 className="text-text-title text-xl mt-8 mb-4">
                      DESCRIPTION
                    </h2>
                    <section
                      className="mb-10"
                      dangerouslySetInnerHTML={{
                        __html: product['description']
                      }}></section>
                    <hr />
                  </section>
                  <section className="mt-2 px-8 lg:px-0 lg:mx-8">
                    {extraModels.length !== 0
                      ? extraModels?.map(
                          (model: ExtraModels, index: number) => {
                            return (
                              <section key={index}>
                                <span className="text-semibold text-lg">
                                  {model.type}
                                </span>
                                <br />
                                <section className="flex flex-row mt-2 align-middle">
                                  {!model.allowMultiSelect
                                    ? model.groupValue.map(
                                        (value: Extra, index: number) => {
                                          return (
                                            <article key={index}>
                                              <input
                                                className=""
                                                type="radio"
                                                name="addType"
                                                value={value.name}
                                                checked={selectedExtras.includes(
                                                  value
                                                )}
                                                onChange={() => {
                                                  changeExtra(value);
                                                }}
                                              />
                                              <span className="pl-1 pr-3 text-md">
                                                {value.name}
                                              </span>
                                            </article>
                                          );
                                        }
                                      )
                                    : null}
                                </section>
                              </section>
                            );
                          }
                        )
                      : null}
                  </section>
                  <section className="hidden md:block lg:block lg:mx-8">
                    <section className="flex space-x-5 w-full place-items-center h-20 flex-row mt-5">
                      <span className="text-text-title text-lg lg:text-2xl font-bold">
                        Rs{' '}{ product.afterDiscountPrice}
                        {/* {(product.productPrice + extrasPrice) *
                          productQuantity -
                          (((product.productPrice + extrasPrice) *
                            product.discount) /
                            100) *
                            productQuantity}*/}{' '} 
                      </span>
                      {product.discount > 0 ? (
                        <del className="text-text-muted text-lg lg:text-xl">
                            &#8377;{product?.productPrice}
                        </del>
                      ) : null}
                      
                      <CountStepper
                        setCount={setproductQuantity}
                        count={productQuantity}
                        increaseCount={() => changeProductQuantity('increase')}
                        reduceCount={() => changeProductQuantity('decrease')}
                      />
                      <button
                        onClick={() => cart()}
                        disabled={productQuantity === 0}
                        className="bg-primary ml-5 text-white px-3 lg:px-6 py-2 sm:w-auto w-96 hover:bg-text-title disabled:opacity-50 disabled:cursor-default disabled:hover:bg-primary">
                        Add to cart
                      </button>
                      <article className="w-8 h-8 overflow-hidden hidden bg-white md:grid place-items-center hover:bg-primary cursor-pointer transition-colors duration-150 mx-1 hover:fill-white hover:stroke-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          onClick={() => {
                            if (isWishlisted) {
                              setIsWishlisted(!isWishlisted);
                              removeFromWishlist();
                            } else {
                              setIsWishlisted(isWishlisted);
                              wishList();
                            }
                          }}
                          className={`cursor-pointer hover:fill-white hover:stroke-white ${
                            isWishlisted ? 'fill-red-500 stroke-red-500' : ''
                          }`}>
                          <path
                            id={`wishlist_path_${
                              product?.product_id || product?.id
                            }`}
                            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                        </svg>
                      </article>
                    </section>
                    {/* <section className="grid mt-5 mb-6">
                  <button className="bg-text-base p-3 text-white">
                    Buy Now
                  </button>
                </section> */}
                    <hr />
                    <section className="grid grid-cols-3 gap-2 mt-5 lg:mt-16">
                      <section className="flex flex-row h-8">
                        <img className="mr-5" src={icons.delivery} alt="" />
                        <span className="text-sm">Shipping and Delivery</span>
                      </section>
                      <section className="flex flex-row h-8">
                        <img className="mr-5" src={icons.return} alt="" />
                        <span className="text-sm">Returns and Exchanges</span>
                      </section>
                      <section className="flex flex-row h-8">
                        <img className="mr-5" src={icons.ask} alt="" />
                        <span className="text-sm">Ask a Question</span>
                      </section>
                    </section>
                    <section className="grid grid-cols-2 mt-10">
                      <span className="text-sm">GUARANTEED SAFE CHECKOUT</span>
                    </section>
                    <section className="flex flex-row justify-center space-x-3 mx-auto mt-8 mb-6">
                      {paymentOption?.googlePaySupported ? (
                        <img src={checkouts[0]} alt="" />
                      ) : null}
                      {paymentOption?.razorPaySupported ? (
                        <img src={checkouts[1]} alt="" />
                      ) : null}
                      {!paymentOption?.payPalSupported ? (
                        <img src={checkouts[2]} alt="" />
                      ) : null}
                      {paymentOption?.stripeSupported ? (
                        <img src={checkouts[3]} alt="" />
                      ) : null}
                    </section>
                    <hr />
                  </section>
                </section>
                <section className="lg:px-8">
                  <section className="lg:hidden md:hidden mt-10 px-8">
                    <section className="mb-10">
                      <h3
                        className="text-text-muted"
                        dangerouslySetInnerHTML={{
                          __html: product['productName']
                        }}></h3>
                      <span className="text-text-title text-2xl font-bold">
                        Rs{' '}{product.afterDiscountPrice}
                        {/* {(product.productPrice + extrasPrice) *
                          productQuantity -
                          (((product.productPrice + extrasPrice) *
                            product.discount) /
                            100) *
                            productQuantity}*/}{' '} 
                        <span className="text-text-muted ml-4 font-thin  line-through">
                          Rs{' '}
                          {(product.productPrice + extrasPrice) *
                            productQuantity}
                        </span>
                      </span>
                      <p className="text-green-500 mt-2 text-xs">
                        {product.available ? 'IN STOCK' : null}
                      </p>
                      <section className="flex flex-row items-center h-10 space-x-2">
                        <CountStepper
                          setCount={setproductQuantity}
                          count={productQuantity}
                          increaseCount={() =>
                            changeProductQuantity('increase')
                          }
                          reduceCount={() => changeProductQuantity('decrease')}
                        />
                        <button
                          onClick={() => cart()}
                          disabled={productQuantity === 0}
                          className="bg-primary w-32 ml-3 lg:w-auto text-xs lg:text-base text-white px-1 lg:px-6  disabled:opacity-50 disabled:cursor-default disabled:hover:bg-primary py-3 hover:bg-text-title">
                          Add to cart
                        </button>
                        <article className="w-8 h-8 overflow-hidden bg-white md:grid place-items-center flex hover:bg-primary cursor-pointer transition-colors duration-150 mx-3 hover:fill-white hover:stroke-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            onClick={() => {
                              if (isWishlisted) {
                                setIsWishlisted(!isWishlisted);
                                removeFromWishlist();
                              } else {
                                setIsWishlisted(isWishlisted);
                                wishList();
                              }
                            }}
                            className={`cursor-pointer hover:fill-white hover:stroke-white ${
                              isWishlisted ? 'fill-red-500 stroke-red-500' : ''
                            }`}>
                            <path
                              id={`wishlist_path_${
                                product?.product_id || product?.id
                              }`}
                              d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                          </svg>
                        </article>
                      </section>
                    </section>
                  </section>
                  <section className="container">
                    <Accordion
                      className="lg:hidden md:hidden w-full"
                      title="DESCRIPTION"
                      noTextPadding={width > 767 ? true : false}
                      html={product.description}
                      content=""
                      isOpen
                    />
                    <Accordion
                      title="RETURN & EXCHANGE POLICY"
                      isOpen
                      noTextPadding={width > 767 ? true : false}
                      content={
                         <p className="ml-4" 
                          dangerouslySetInnerHTML={{
                           __html: config?.isConfigLoaded
                                ? config.parameters['return_policy']
                                : null
                         }}></p>
                      }
                    />
                    <Accordion
                      title="SHIPPING & DELIVERY POLICY"
                      isOpen
                      noTextPadding={width > 767 ? true : false}
                      content={
                        <p className="ml-4" 
                          dangerouslySetInnerHTML= {{
                          __html:config?.isConfigLoaded
                            ? config.parameters['shipping_policy']
                            : null}}>
                        </p>
                      }
                    />
                  </section>
                  <hr />
                </section>
              </section>
            </section>
          </>
        ) : (
          <>
            <ProductHeader productCategory={''} />
            <section className="grid grid-cols-1 grid-rows-12 lg:grid-cols-2 md:grid-cols-2 bg-bg-primary">
              <section className="h-full lg:mt-20">
                <section className="grid grid-rows-12 grid-cols-1 lg:grid-rows-1 lg:grid-cols-12 lg:gap-4 px-5 lg:px-12">
                  <section className="lg:h-104 col-span-3 space-y-1 flex flex-row h-full lg:flex-col ">
                    <section className="hidden lg:flex 2space-x-2 mb-3 justify-center flex-row">
                      <section
                        onClick={() => changeSelectedIndex('previous')}
                        className="bg-white grid place-items-center cursor-pointer rounded-full h-10 w-10">
                        {selectedIndex === 0 ? (
                          <img
                            className="opacity-50"
                            src={icons.upArrow}
                            alt=""></img>
                        ) : (
                          <img src={icons.upArrow} alt=""></img>
                        )}
                      </section>
                      <section
                        onClick={() => changeSelectedIndex('next')}
                        className="bg-white grid place-items-center cursor-pointer rounded-full h-10 w-10">
                        <img
                          className="opacity-50"
                          src={icons.downArrow}
                          alt=""></img>
                      </section>
                    </section>
                    <section
                      className={`grid grid-cols-6 grid-rows-1 gap-1 lg:grid-cols-1 lg:grid-rows-4 place-items-center`}>
                      <section
                        onClick={() => changeSelectedIndex('previous')}
                        className="h-10 w-10 lg:hidden grid place-items-center cursor-pointer">
                        {selectedIndex === 0 ? (
                          <img
                            className="opacity-50"
                            src={icons.leftArrow}
                            alt=""></img>
                        ) : (
                          <img src={icons.leftArrow} alt=""></img>
                        )}
                      </section>
                      <section
                        onClick={() => changeSelectedIndex('next')}
                        className="h-10 w-10 lg:hidden grid place-items-center cursor-pointer">
                        <img
                          className="opacity-50"
                          src={icons.rightArrow}
                          alt=""></img>
                      </section>
                    </section>
                  </section>
                  <section className="h-96 lg:h-104 mt-5 order-first lg:order-last row-span-10 lg:col-span-9">
                    <section className="grid grid-cols-12 text-gray-400 bg-gray-400 animate-pulse place-items-center h-full w-full border">
                      {/* <section
                      onClick={() => changeSelectedIndex('previous')}
                      className="h-10 w-10 col-span-2 grid place-items-center rounded-full cursor-pointer border">
                      {selectedIndex === 0 ? (
                        <img
                          className="opacity-50"
                          src={icons.leftArrow}
                          alt=""></img>
                      ) : (
                        <img src={icons.leftArrow} alt=""></img>
                      )}
                    </section>
                    <section className="col-span-8 h-96 bg-gray-400 animate-pulse "></section>
                    <section
                      onClick={() => changeSelectedIndex('next')}
                      className="h-10 w-10 col-span-2 grid place-items-center cursor-pointer rounded-full border">
                      <img
                        className="opacity-50"
                        src={icons.rightArrow}
                        alt=""></img>
                    </section> */}
                    </section>
                  </section>
                </section>
              </section>
              <section className="h-auto">
                <section className="hidden md:block lg:block lg:mt-20 lg:mx-8">
                  <h1
                    className="text-xl text-gray-400 bg-gray-400 animate-pulse"
                    dangerouslySetInnerHTML={{
                      __html: 'Trial Product'
                    }}></h1>
                  <h2 className="text-gray-400 bg-gray-400 animate-pulse mt-8 mb-4">
                    DESCRIPTION
                  </h2>
                  <section className=" text-gray-400 bg-gray-400 animate-pulse mb-10">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Harum facilis, similique soluta corporis quis aspernatur
                    nobis officiis tempore eum nam esse dolorum molestias fugit
                    voluptatibus alias beatae quia natus ducimus!
                  </section>
                  <hr />
                  <section className="flex space-x-5 w-full place-items-center flex-row mt-5">
                    <span className="text-gray-400 bg-gray-400 animate-pulse text-2xl font-bold">
                      Rs 250
                    </span>
                    <CountStepper
                      setCount={setproductQuantity}
                      count={productQuantity}
                      increaseCount={() => changeProductQuantity('increase')}
                      reduceCount={() => changeProductQuantity('decrease')}
                    />
                    <button
                      onClick={() => cart()}
                      disabled={productQuantity === 0}
                      className="bg-primary ml-5 text-white px-6 py-2 sm:w-auto w-96 hover:bg-text-title disabled:opacity-50 disabled:cursor-default disabled:hover:bg-primary">
                      Add to cart
                    </button>
                    <img
                      onClick={() => wishList()}
                      className="cursor-pointer bg-white hover:bg-bg-primary-light ml-5 border"
                      src={icons.wishlist}
                      alt=""
                    />
                  </section>
                  {/* <section className="grid mt-5 mb-6">
                  <button className="bg-text-base p-3 text-white">
                    Buy Now
                  </button>
                </section> */}
                  <hr />
                  <section className="grid grid-cols-3 mt-10">
                    <section className="flex flex-row">
                      <img className="mr-5" src={icons.delivery} alt="" />
                      <span className="text-sm">Shipping and Delivery</span>
                    </section>
                    <section className="flex flex-row">
                      <img className="mr-5" src={icons.return} alt="" />
                      <span className="text-sm">Returns and Exchanges</span>
                    </section>
                    <section className="flex flex-row">
                      <img className="mr-5" src={icons.ask} alt="" />
                      <span className="text-sm">Ask a Question</span>
                    </section>
                  </section>
                  <section className="grid grid-cols-2 mt-10">
                    <span className="text-sm">GUARANTEED SAFE CHECKOUT</span>
                    <span className="pt-3">
                      <hr />
                    </span>
                  </section>
                  <section className="grid grid-cols-7 grid-rows-1 mt-8 mb-6">
                    {checkouts.map((checkout, index) => (
                      <img src={checkout} key={index} alt="" />
                    ))}
                  </section>
                  <hr />
                </section>
                <section className="lg:px-8">
                  <section className="lg:hidden md:hidden mt-10 px-8">
                    <section className="mb-10">
                      <h3 className="text-gray-400 bg-gray-400 animate-pulse">
                        Product name
                      </h3>
                      <span className="text-gray-400 bg-gray-400 animate-pulse text-2xl font-bold">
                        Rs 250
                      </span>
                      <section className="flex flex-row space-x-2">
                        <CountStepper
                          count={productQuantity}
                          setCount={setproductQuantity}
                          increaseCount={() =>
                            changeProductQuantity('increase')
                          }
                          reduceCount={() => changeProductQuantity('decrease')}
                        />
                        <button
                          onClick={() => cart()}
                          disabled={productQuantity === 0}
                          className="bg-transparent text-primary border border-primary w-32 lg:w-auto text-sm lg:text-base   px-6  disabled:opacity-50 disabled:cursor-default hover:bg-bg-primary-light py-2">
                          Buy Now
                        </button>
                        <button
                          onClick={() => cart()}
                          disabled={productQuantity === 0}
                          className="bg-primary w-32 lg:w-auto text-xs lg:text-base   text-white px-6  disabled:opacity-50 disabled:cursor-default disabled:hover:bg-primary py-2 hover:bg-text-title">
                          Add to cart
                        </button>
                      </section>
                    </section>
                  </section>
                  <Accordion
                    title="RETURN & EXHANGE POLICY"
                    isOpen
                    content={
                      <p className="ml-6">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quo et adipisci facilis. Ipsum, assumenda. Minus
                        dolor esse, repudiandae accusamus veritatis blanditiis
                        doloribus fugit quia dolorum molestias temporibus?
                        Praesentium, ipsum delectus!
                      </p>
                    }
                  />
                  <Accordion
                    title="SHIPPING & DELIVERY POLICY"
                    isOpen
                    content={
                      <p className="pl-10">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Non quasi delectus doloribus nemo quaerat
                        adipisci autem laboriosam, ad commodi rerum modi nobis
                        iure, aliquid alias consectetur quas necessitatibus
                        deleniti harum!
                      </p>
                    }
                  />
                  <Accordion
                    title="REVIEWS"
                    isOpen
                    content={
                      <section className="mt-5">
                        <p className="mt-3">
                          <section className="flex flex-row justify-between">
                            <h3 className="font-thin mt-3">Customer Name</h3>
                            <img src={reviews} alt="" />
                          </section>
                          <p className="font-thin mt-4">
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Fuga praesentium nobis minus numquam quia
                            amet ducimus optio cumque qui.
                          </p>
                        </p>
                        <p className="mt-3">
                          <section className="flex flex-row justify-between">
                            <h3 className="font-thin mt-3">Customer Name</h3>
                            <img src={reviews} alt="" />
                          </section>
                          <p className="font-thin mt-4">
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Fuga praesentium nobis minus numquam quia
                            amet ducimus optio cumque qui.
                          </p>
                        </p>
                      </section>
                    }
                  />
                  <hr />
                </section>
              </section>
            </section>
          </>
        )}
        {similarProducts && similarProducts?.length > 0 ? (
          <section className="w-11/12 mx-auto mt-10 bg-bg-primary px-5 pb-20">
            <h3 className="text-thin">YOU MAY ALSO LIKE</h3>
            <section className="relative flex items-center ml-0 ipad-lg:ml-6 pt-10 mt-5">
              <Carousel
                responsive={responsiveness}
                autoPlaySpeed={3000}
                className="border border-gray-50/5"
                infinite
                draggable
                swipeable
                keyBoardControl
                customButtonGroup={<CustomButtonGroupAsArrows />}
                arrows={false}
                renderButtonGroupOutside
                itemClass="snap-center ml-5"
                containerClass="snap-x"
                centerMode={false}>
                {similarProducts?.map((product: any, index: number) => (
                  <SimilarProductCard product={product} key={index} />
                ))}
              </Carousel>
            </section>
          </section>
        ) : null}
      </section>
    </>
  );
};

export default ProductScreen;
