import axios from 'axios';
import { config } from '../../config/config';
export const getSeo = async (url: string) => {
  let res = await axios.get(`${config.AI_BASE_URL}/public/seo`, {
    params: {
      companyNo: config.COMPANY_NO,
      page_url: url
    }
  });
  return await res.data;
};
