import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import CartPage from '../../screens/Cart/CartPage';
import DropDownList from '../common/DropdownList/DropDownList';
import OutsideWrapper from '../common/OutsideWrapper/OutsideWrapper';
import { RootState } from '../../redux/reducers';
import WishListPage from '../../screens/WishList/WishListPage';
import { company_no } from '../../api/api';
import { openLoginModal } from '../../redux/actions/modals';
import { searchProducts } from '../../api/ProductListing';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

const BottomBar = () => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const counts = useSelector((state: RootState) => state.counts);
  const [searchBarVisible, setsearchBarVisible] = useState(false);
  const userCart = useSelector((state: RootState) => state.cart);
  const [isDropDownVisible, setisDropDownVisible] = useState(false);
  const [dropownData, setdropownData] = useState<any>([]);
  /**setsearchInput - function to set state of input */
  const [searchInput, setsearchInput] = useState('');

  /**debounced search term */
  const debouncedSearchCall = useDebounce(searchInput, 400);

  const isWholesale = useSelector((state: RootState) => state.isWholesale);

  /**useEffect - makes an api call after designated debounce time to search products  */
  useEffect(() => {
    if (typeof debouncedSearchCall[0] === 'string' && debouncedSearchCall[0]) {
      searchProducts(
        company_no,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'productName',
        'ASC',
        10,
        0,
        isWholesale,
        debouncedSearchCall[0]
      )
        .then((res) => {
          if (res['product']) {
            setdropownData([...res['product']]);
            if (debouncedSearchCall[0] === '') {
              setisDropDownVisible(false);
            } else {
              setisDropDownVisible(true);
            }
          } else {
            setisDropDownVisible(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
    }
  }, [debouncedSearchCall[0], isWholesale]);
  
  /**
   * changeHandler - function to handle change event of input element
   * @param event - React ChangeEvent
   */
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchInput(event.target.value);
    if (event.target.value === '') {
      setisDropDownVisible(false);
    }
  };
  const dispatch = useDispatch();
  let navigate = useNavigate();

  /**
   * navigateToProduct - function to pass down to child dropdown
   * @param {string} path - path to navigate to
   */
  const navigateToProduct = (path: string) => {
    navigate(path);
    setisDropDownVisible(false);
  };

  return (
    <>
      <div className="fixed bottom-0 w-full bg-white flex lg:hidden items-center justify-around h-14 border-t z-[1000]">
        <span
          onClick={() => {
            setsearchBarVisible(!searchBarVisible);
          }}
          className="lg:hidden cursor-pointer">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2c2c2c"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg> */}
          <svg
            version="1.1"
            id="icon_action_search_24px"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            x="0px"
            y="0px"
            viewBox="0 0 24 24">
            <circle className="st0" cx="11" cy="11" r="7" />
            <line className="st0" x1="21.2" y1="21.2" x2="16.8" y2="16.8" />
            <g>
              {/* <line className="st1" x1="11" y1="14.4" x2="11" y2="7.9" /> */}
              {/* <line className="st1" x1="7.7" y1="11.1" x2="14.3" y2="11.1" /> */}
            </g>
          </svg>
        </span>
        <span
          onClick={() => {
            if (isLoggedIn) {
              navigate('/profile');
            } else {
              dispatch(openLoginModal());
            }
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="cursor-pointer">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </span>
        <span className="flex flex-row">
          <WishListPage />
          {counts?.wishlistCount?.length && isLoggedIn ? (
            <span className="relative -top-2 right-3 h-4 w-4 inline-flex items-center justify-center text-xxs font-bold leading-none text-red-100 bg-primary rounded-full">
              {counts?.wishlistCount?.length}
            </span>
          ) : null}
        </span>
        <span className="flex flex-row relative z-0">
          <CartPage />
          {(counts?.cartCount?.ProductQuantity && isLoggedIn) ||
          (!isLoggedIn && userCart?.length) ? (
            <span className="absolute h-4 w-4 inline-flex items-center justify-center text-xxs text-white -top-2 -right-2 bg-primary rounded-full">
              {isLoggedIn
                ? counts?.cartCount?.ProductQuantity
                : userCart?.length}
            </span>
          ) : null}
        </span>
      </div>
      {searchBarVisible ? (
        <section
          className={`flex justify-center min-h-full min-w-full bg-black fixed bg-opacity-60 inset-0 z-40 ease-in-out transition-all duration-700 ${
            searchBarVisible
              ? 'translate-y-16 opacity-100'
              : '-translate-y-16 opacity-0'
          }`}>
          <section className="bg-white h-20 w-full py-3 px-5 grid place-items-center">
            <section className="flex relative w-full cursor-pointer justify-center items-center mx-16">
              <input
                value={searchInput}
                onChange={(e) => changeHandler(e)}
                onFocus={() => {
                  if (searchInput) {
                    setisDropDownVisible(true);
                  }
                }}
                type="text"
                placeholder="Search for product"
                className="border w-full px-5 py-2 placeholder-text-muted text-sm focus:outline-none focus:ring-primary focus:ring-1"
              />
              {searchInput === '' ? (
                // <svg
                //   className="absolute right-14 mx-5"
                //   id="icon_action_search_24px"
                //   data-name="icon/action/search_24px"
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="24"
                //   height="24"
                //   viewBox="0 0 24 24">
                //   <rect id="Boundary" width="24" height="24" fill="none" />
                //   <path
                //     id="_Color"
                //     data-name=" ↳Color"
                //     d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z"
                //     transform="translate(3 3)"
                //   />
                // </svg>
                <svg
                  className="absolute right-14 mx-5"
                  version="1.1"
                  id="icon_action_search_24px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  x="0px"
                  y="0px"
                  viewBox="0 0 24 24">
                  <circle className="st0" cx="11" cy="11" r="7" />
                  <line
                    className="st0"
                    x1="21.2"
                    y1="21.2"
                    x2="16.8"
                    y2="16.8"
                  />
                  <g>
                    {/* <line className="st1" x1="11" y1="14.4" x2="11" y2="7.9" />
                    <line
                      className="st1"
                      x1="7.7"
                      y1="11.1"
                      x2="14.3"
                      y2="11.1"
                    /> */}
                  </g>
                </svg>
              ) : (
                <svg
                  className="absolute right-14 mx-4 cursor-pointer"
                  onClick={() => {
                    setsearchInput('');
                    setisDropDownVisible(false);
                  }}
                  id="icon_navigation_close_24px"
                  data-name="icon/navigation/close_24px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <rect id="Boundary" width="24" height="24" fill="none" />
                  <path
                    id="_Color"
                    data-name=" ↳Color"
                    d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                    transform="translate(5 5)"
                  />
                </svg>
              )}
              <svg
                onClick={() => {
                  setsearchBarVisible(false);
                  setsearchInput('');
                  setisDropDownVisible(false);
                }}
                className="relative mx-4 cursor-pointer"
                id="icon_navigation_close_24px"
                data-name="icon/navigation/close_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <rect id="Boundary" width="24" height="24" fill="none" />
                <path
                  id="_Color"
                  data-name=" ↳Color"
                  d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                  transform="translate(5 5)"
                />
              </svg>
            </section>

            <DropDownList
              list={dropownData}
              isVisible={isDropDownVisible}
              onClickNavigate={navigateToProduct}
              extraClasses=""
              afterRedirect={() => setsearchBarVisible(false)}
            />
          </section>
        </section>
      ) : null}
    </>
  );
};

export default BottomBar;
