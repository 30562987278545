import { Dispatch, SetStateAction } from 'react';

interface Props {
  reduceCount: () => void;
  increaseCount: () => void;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
}

const CountStepper = ({
  reduceCount,
  increaseCount,
  count,
  setCount
}: Props) => {
  return (
    <section className="grid grid-cols-3 w-28 lg:w-36  h-10 pb-0 border bg-white">
      <section
        onClick={() => reduceCount()}
        className="cursor-pointer grid place-items-center text-2xl border-r border-text-muted border-opacity-50 hover:bg-bg-primary-light">
        {' '}
        -{' '}
      </section>
      <input
        type="number"
        className="text-center"
        onChange={(e) => {
          setCount(parseInt(e.target.value) || 0);
        }}
        value={count}
      />
      <section
        onClick={() => increaseCount()}
        className="cursor-pointer grid place-items-center border-l border-text-muted border-opacity-50 text-2xl hover:bg-bg-primary-light">
        {' '}
        +{' '}
      </section>
    </section>
  );
};

export default CountStepper;
