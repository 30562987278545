import { Action } from "../../types/type";
import { SET_BLOGS, SET_TESTIMONIALS } from "../actions/actionTypes";

const initialState = {
    testimonials : [] as any,
    blogs: [] as any,
}

/**
 * othersReducer - reducer to handle data regarding miscellanous company configs
 * @param state 
 * @param {Action} action - dispatched action 
 * @returns state 
 */
export const othersReducer = (state=initialState,action:Action) => {
    if(typeof action.type !== undefined){
        switch(action.type){
            case SET_TESTIMONIALS: {
                return {...state,testimonials : [...action.payload]}
            }
            case SET_BLOGS: {
                return {...state,blogs : [...action.payload]}
            }
            default: 
                return state
        }
    }
}