import { Helmet } from 'react-helmet';
import ProductHeader from '../../components/ProductListing/ProductHeader';
import config from '../../config.json';
import { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .name
          }
        </title>
        <meta
          name="title"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.title
          }
        />
        <meta
          name="description"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.description
          }
        />
        <meta
          name="keywords"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.keywords
          }
        />
        <meta
          property="og:type"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.type
          }
        />
        <meta
          property="og:title"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.title
          }
        />
        <meta
          property="og:url"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.url
          }
        />
        <meta
          property="og:image"
          content={
            config.pages.filter((page) => page.name === 'Privacy Policy')[0]
              .seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}
      <ProductHeader
        breadcrumbArray={['home', 'privacy policy']}
        productCategory="Privacy Policy"
      />
      <section className="container my-7 p-10">
        <div
          dangerouslySetInnerHTML={{
            __html: config.pages.filter(
              (page) => page.name === 'Privacy Policy'
            )[0].content
          }}></div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
