const ProductListLoading = () => {
  return (
    <section className="grid auto-rows-auto grid-cols-1 md:grid-cols-3 lg:grid-cols-4 md:border border p-2 md:my-16 md:bg-white">
      <article className="border md:border-0  md:max-w-xs md:border-r grid object-cover place-items-center bg-gray-400 animate-pulse h-56"></article>
      <article className="md:ml-10 mt-4 md:mt-16 md:pr-5 col-span-2 lg:col-span-3">
        <p className="text-gray-400 text-xs bg-gray-400 animate-pulse font-bold pointer-events-none text-opacity-60">
          Product Name
        </p>
        <article className="space-x-2 md:space-x-0 flex font-bold mt-2">
          <p className="bg-gray-400 text-gray-400 animate-pulse mt-2 pointer-events-none">
            &#8377;100
          </p>
          <del className="text-gray-400 bg-gray-400 animate-pulse md:hidden pointer-events-none">
            &#8377;100
          </del>
        </article>
        <p className="text-gray-400 bg-gray-400 animate-pulse mt-2 font-normal text-sm pointer-events-none">
          Description
        </p>
        <span className="flex space-x-4 my-4">
          <button className="bg-gray-400 text-gray-400 animate-pulse px-6 py-2 pointer-events-none">
            Add to cart
          </button>
          <button className="bg-gray-400 text-gray-400 animate-pulse pointer-events-none">
            Add to cart
          </button>
          <button className="bg-gray-400 text-gray-400 animate-pulse pointer-events-none">
            Add to cart
          </button>
        </span>
      </article>
    </section>
  );
};

export default ProductListLoading;
