import { Helmet } from 'react-helmet';
import ProductHeader from '../../components/ProductListing/ProductHeader';
import CONFIG from '../../config.json';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

const ShippingPolicy = () => {
  const config = useSelector((state: RootState) => state.config);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .name
          }
        </title>
        <meta
          name="title"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.title
          }
        />
        <meta
          name="description"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.description
          }
        />
        <meta
          name="keywords"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.keywords
          }
        />
        <meta
          property="og:type"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.type
          }
        />
        <meta
          property="og:title"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.title
          }
        />
        <meta
          property="og:url"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.url
          }
        />
        <meta
          property="og:image"
          content={
            CONFIG.pages.filter((page) => page.name === 'Shipping Policy')[0]
              .seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}
      <ProductHeader
        breadcrumbArray={['home', 'shipping policy']}
        productCategory="Shipping Policy"
      />
      <section className="container my-7 p-10">
        <div
          dangerouslySetInnerHTML={{
            __html: config?.isConfigLoaded
              ? config.parameters['shipping_policy']
              : CONFIG.pages.filter(
                  (page) => page.name === 'Shipping Policy'
                )[0].content
          }}></div>
      </section>
    </>
  );
};

export default ShippingPolicy;
