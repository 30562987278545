import { Address, PostAddress } from '../../types/type';
import {
  addUserAddress,
  editUserAddress,
  getCityAndStateFromPin
} from '../../api/Profile';
import { useCallback, useEffect, useState } from 'react';

import { RootState } from '../../redux/reducers';
import icons from '../../assets/icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

interface Props {
  address?: Address;
  isEdit: boolean;
  isFirstAddress: boolean;
  closeForm: () => void;
  onSuccess: () => void;
  userAddresses: Address[];
}

/**
 * Initial address object
 */
const initAddress: Address = {
  addressLine1: '',
  addressLine2: '',
  addressType: 'Home',
  city: '',
  country: '',
  defaultAddress: false,
  fullName: '',
  landmark: '',
  latitude: 0,
  longitude: 0,
  mobileNumber: '',
  state: '',
  zipCode: '',
  alternateMobileNumber: '',
  alternateName: '',
  billingUserAddress: '',
  id: null,
  userId: 0
};

const AddressForm = (props: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const [address, setaddress] = useState<Address>({
    ...initAddress,
    userId: user.id
  });
  const [isPincodeError, setisPincodeError] = useState(false);

  /**
   * Function to set address received from props
   */
  useEffect(() => {
    if (props.address !== undefined) {
      setaddress({ ...props.address });
    }
  }, [props.address]);

  /**
   * useEffect - calls geocode api for fetching state and city
   */
  useEffect(() => {
    if (address?.zipCode.length === 6) {
      getCityAndStateFromPin(address.zipCode).then((res: any) => {
        if (res.status === 'OK') {
          setisPincodeError(false);
          let addressObj: any[] = [];
          res.results[0].address_components.forEach((addItem:any) => {
            if(addItem.types[0] === 'administrative_area_level_2' || addItem.types[0] === 'locality') {
              addressObj[0] = addItem.long_name}
              if(addItem.types[0] === 'administrative_area_level_1') {
                addressObj.push(addItem.long_name)}
              if(addItem.types[0] === 'country') {
                addressObj.push(addItem.long_name)}
          })
          setaddress((address) => {
            return {
              ...address,
              city: addressObj[0],
              country: addressObj[2],
              state:addressObj[1]
            };
          });
        } else {
          setisPincodeError(true);
          setaddress((address) => {
            return {
              ...address,
              city: '',
              country: '',
              state: ''
            };
          });
        }
      });
    }
  }, [address.zipCode]);

  // This function set value on input change.
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setaddress((previousState) => {
      return { ...previousState, [name]: value };
    });
  }, []);

  const onRadioButtonHandleChange = (value: string) => {
    setaddress({
      ...address,
      addressType: value
    });
  };

  /**
   * validateForm - function to validate address form
   * @returns {boolean} result indicating validity of form
   */
  const validateForm = (): boolean => {
    let result = false;
    if (address.zipCode.length !== 6) {
      toast.error('Please enter a valid zip code');
    } else if (address.addressLine1.length === 0) {
      toast.error('Please enter a valid address');
    } else if (address.city.length === 0) {
      toast.error('Please enter a valid city');
    } else if (address.country.length === 0) {
      toast.error('Please enter a valid country');
    } else if (
      address.mobileNumber.length > 0 &&
      address.mobileNumber.length < 10
    ) {
      toast.error('Please enter a valid mobile number');
    } else {
      result = true;
    }
    return result;
  };

  /**
   * onSubmitForm - function to handle submitting the form
   */
  const onSubmitForm = () => {
    if (validateForm()) {
      if (props.isEdit) {
        editAddress();
      } else {
        addAddress();
      }
    }
  };

  /**
   * editAddress - async function to call api to edit a given address
   */
  const editAddress = async () => {
    const data = await editUserAddress({ ...address });
    if (data) {
      toast.success('Address Edited successfully');
    }
    props.onSuccess();
  };

  /**
   * toPostAddress - converts an object of type Address to a type PostAddress for proper payload
   * @returns {PostAddress} required object in given format
   */
  const toPostAddress = ({
    addressLine1,
    addressLine2,
    addressType,
    city,
    country,
    defaultAddress,
    fullName,
    id,
    landmark,
    latitude,
    longitude,
    mobileNumber,
    state,
    zipCode
  }: Address): PostAddress => ({
    addressLine1,
    addressLine2,
    addressType,
    city,
    country,
    defaultAddress,
    fullName,
    id,
    landmark,
    latitude,
    longitude,
    mobileNumber,
    state,
    zipCode
  });

  /**
   * addAddres - async function to call api for adding a new address
   */
  const addAddress = async () => {
    const data = await addUserAddress(toPostAddress(address));
    if (data) {
      toast.success('Address Added successfully');
    }
    props.onSuccess();
  };
  return (
    <section className="border border-opacity-50 px-5 md:px-0">
      <section className="bg-white p-6">
        <section className="flex flex-row-reverse">
          <section
            onClick={() => props.closeForm()}
            className="cursor-pointer">
            <img src={icons.close} alt="" />
          </section>
        </section>
        <section className="text-2xl mb-6 font-bold ">
          Shipping address
        </section>
        <section className="mb-6">
          <div className="text-text-base">Full name</div>
          <div>
            <input
              className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
              type="text"
              name="fullName"
              value={address.fullName ? address.fullName : ''}
              onChange={handleOnChange}
            />
          </div>
        </section>
        <section className="mb-6">
          <div className="text-text-base">Address</div>
          <div>
            <input
              className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
              type="text"
              name="addressLine1"
              value={address.addressLine1 ? address.addressLine1 : ''}
              onChange={handleOnChange}
            />
          </div>
        </section>

        <section className="grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 mb-6">
          <section className="lg:col-auto">
            <div className="text-text-base">Postal code</div>
            <div>
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                type="number"
                name="zipCode"
                value={address.zipCode ? address.zipCode : ''}
                onChange={handleOnChange}
              />
              <span className="text-red-500 text-sm" hidden={!isPincodeError}>
                Please enter a valid pincode
              </span>
            </div>
          </section>
          <section className="col-span-2">
            <div className="text-text-base">Phone</div>
            <div>
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                type="text"
                name="mobileNumber"
                value={address.mobileNumber ? address.mobileNumber : ''}
                onChange={handleOnChange}
              />
            </div>
          </section>
          <section>
            <div className="text-text-base">Country</div>
            <div>
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                type="text"
                name="country"
                value={address.country ? address.country : ''}
                onChange={handleOnChange}
                disabled
              />
            </div>
          </section>
          <section>
            <div className="text-text-base">State</div>
            <div>
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                type="text"
                name="state"
                value={address.state ? address.state : ''}
                onChange={handleOnChange}
                disabled
              />
            </div>
          </section>
          <section>
            <div className="text-text-base">City</div>
            <div>
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                type="text"
                name="city"
                value={address.city ? address.city : ''}
                onChange={handleOnChange}
                disabled
              />
            </div>
          </section>
        </section>
        <section className="flex flex-col space-y-3 mb-6 items-baseline">
          <section className="text-lg lg:text-xl font-bold pr-4">
            Type of Address
          </section>
          <section className="flex-grow">
            <input
              className="align-middle"
              type="radio"
              fong-0="true"
              name="addType"
              value="Home"
              defaultChecked={address.addressType === 'Home'}
              onChange={(e) => {
                onRadioButtonHandleChange(e.target.value);
              }}
            />
            <span className="pl-1 pr-3 text-sm"> HOME</span>
            <input
              className="align-middle"
              type="radio"
              name="addType"
              value="Office"
              defaultChecked={address.addressType === 'Office'}
              onChange={(e) => {
                onRadioButtonHandleChange(e.target.value);
              }}
            />
            <span className="pl-1 pr-3 text-sm">OFFICE</span>
            <input
              className="align-middle"
              type="radio"
              name="addType"
              value="Other"
              defaultChecked={address.addressType === 'Other'}
              onChange={(e) => {
                onRadioButtonHandleChange(e.target.value);
              }}
            />
            <span className="pl-1 pr-3 text-sm">OTHER</span>
          </section>
        </section>
        <section className="mb-6">
          <input
            className="align-middle cursor-pointer"
            type="checkbox"
            name="defaultAddress"
            checked={
              props.isFirstAddress
                ? true
                : address.defaultAddress
                ? address.defaultAddress
                : false
            }
            onChange={(e) => {
              if (props.userAddresses.some((e) => e.defaultAddress === true)) {
                if (props.userAddresses.some((e) => e.id === address.id)) {
                  toast.error('Need atleast one default address');
                  return;
                }
                setaddress({ ...address, defaultAddress: true });
              } else {
                setaddress({ ...address, defaultAddress: e.target.checked });
              }
            }}
          />
          <span className="text-xs ml-3">Mark default address</span>
        </section>
        <p className="flex flex-row mt-4 h-11 space-x-2">
          <button
            onClick={onSubmitForm}
            className="bg-primary hover:bg-text-title text-white px-5 text-center border">
            {props.isEdit ? 'Save' : 'Add'}
          </button>
        </p>
      </section>
    </section>
  );
};

export default AddressForm;
