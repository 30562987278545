import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Address } from '../../types/type';
import AddressForm from './AddressForm';
import AddressesList from './AddressesList';
import OrderHistory from './OrderHistory';
import ProfileDetails from './ProfileDetails';
import { RootState } from '../../redux/reducers';
import { getAllAddresses } from '../../api/Profile';
import { logout } from '../../redux/actions/auth';
import { resetCounts } from '../../redux/actions/actionCreators';
import { resetLoginCart } from '../../redux/actions/loginCart';
import { setWholesale } from '../../redux/actions/productListing';
import { toast } from 'react-toastify';
import { unsetQuickViewProduct } from '../../redux/actions/modals';
import { useNavigate } from 'react-router-dom';

const initAddress: Address = {
  addressLine1: '',
  addressLine2: '',
  addressType: 'Home',
  city: '',
  country: '',
  defaultAddress: false,
  fullName: '',
  landmark: '',
  latitude: 0,
  longitude: 0,
  mobileNumber: '',
  state: '',
  zipCode: '',
  alternateMobileNumber: '',
  alternateName: '',
  billingUserAddress: '',
  id: null,
  userId: 0
};

const Profile = () => {
  /**
   * setisAddressVisible - function to toggle components
   */
  const [visibleComponent, setvisibleComponent] = useState(0);
  /**
   * setuserAddresses - function to store addresses in state
   */
  const [userAddresses, setuserAddresses] = useState<Address[]>([]);
  const [isAddressFormVisible, setisAddressFormVisible] = useState(false);
  const [selectedAddress, setselectedAddress] = useState<Address>(initAddress);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const [isFormEdit, setisFormEdit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFirstAddress, setIsFirstAddress] = useState(false);
  /**
   * getAddressOfUser - fetches address details of user
   */
  const getAddressOfUser = async () => {
    const data = await getAllAddresses();
    if (!data.length) {
      setIsFirstAddress(true);
      setuserAddresses([...data]);
    } else {
      setuserAddresses([...data]);
      data.length > 0 ? setIsFirstAddress(false) : setIsFirstAddress(true);
    }
  };

  /**
   * useEffect - runs when component is mounted and fetches addresses of current user
   */
  useEffect(() => {
    if (!isLoggedIn) {
      toast.error('Please login to continue');
      navigate('/');
    }
    getAddressOfUser();
  }, []);

  const toggleEditForm = (address: Address) => {
    setselectedAddress(address);
    setisFormEdit(true);
  };

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setisAddressFormVisible(false);
    getAddressOfUser();
  };
  return (
    <section className="h-auto pb-20 bg-white">
      <section className="h-40 bg-bg-primary w-full grid place-items-center">
        <h1 className="text-xl md:text-4xl">
          {visibleComponent === 0
            ? 'Profile Details'
            : visibleComponent === 1
            ? 'Order History'
            : 'Address Book'}
        </h1>
      </section>
      <section className="container mt-5 pt-5 md:mt-0 px-0 md:px-10 lg:px-20  grid md:grid md:grid-cols-12 md:gap-4">
        <section className="px-3 md:px-0 md:col-span-5 lg:col-span-3">
          <article className="border border-primary-dark border-opacity-50 grid place-items-center">
            <ul className="w-full text-center bg-white">
              <li
                onClick={() => setvisibleComponent(0)}
                className={
                  `py-4 text-sm md:text-base border-b-2 cursor-pointer hover:bg-bg-primary-light` +
                  (visibleComponent === 0 ? ' bg-bg-primary-light' : '')
                }>
                Profile Details
              </li>
              <li
                onClick={() => setvisibleComponent(1)}
                className={
                  `py-4 text-sm md:text-base border-b-2 cursor-pointer hover:bg-bg-primary-light` +
                  (visibleComponent === 1 ? ' bg-bg-primary-light' : '')
                }>
                Order History
              </li>
              <li
                onClick={() => setvisibleComponent(2)}
                className={
                  `py-4 text-sm md:text-base border-b-2 cursor-pointer hover:bg-bg-primary-light` +
                  (visibleComponent === 2 ? ' bg-bg-primary-light' : '')
                }>
                Manage Address
              </li>
              <li
                onClick={() => {
                  dispatch(logout());
                  dispatch(resetLoginCart());
                  window.scrollTo(0, 0);
                  navigate('/');
                  dispatch(unsetQuickViewProduct());
                  dispatch(setWholesale(false));
                  dispatch(resetCounts());
                  toast.success('Logout successful');
                }}
                className="py-4 text-sm md:text-base  border-b-2 cursor-pointer hover:bg-bg-primary-light">
                Log Out
              </li>
            </ul>
          </article>
        </section>
        <section className="mt-5 md:mt-0  md:col-span-7 lg:col-span-9">
          {visibleComponent === 0 ? (
            <ProfileDetails />
          ) : visibleComponent === 1 ? (
            <OrderHistory userId={4348} />
          ) : visibleComponent === 2 ? (
            <>
              <section className="grid place-items-center md:place-items-start">
                <button
                  onClick={() => {
                    setisAddressFormVisible(true);
                    setselectedAddress(initAddress);
                    setisFormEdit(false);
                  }}
                  className="bg-primary-darker text-sm md:text-base text-white p-4 mb-3">
                  Add New Address
                </button>
              </section>
              {isAddressFormVisible ? (
                isFormEdit ? (
                  <AddressForm
                    closeForm={() => setisAddressFormVisible(false)}
                    isEdit={isFormEdit}
                    isFirstAddress={isFirstAddress}
                    address={selectedAddress}
                    onSuccess={() => onSuccess()}
                    userAddresses={userAddresses}
                  />
                ) : (
                  <AddressForm
                    closeForm={() => setisAddressFormVisible(false)}
                    isEdit={isFormEdit}
                    isFirstAddress={isFirstAddress}
                    onSuccess={() => onSuccess()}
                    userAddresses={userAddresses}
                  />
                )
              ) : null}
              {!isAddressFormVisible || !isFormEdit ? (
                <section className="mx-2 md:px-0 h-104 mt-5 overflow-y-scroll border-b">
                  <AddressesList
                    toggleForm={() => setisAddressFormVisible(true)}
                    onEditClick={toggleEditForm}
                    setIsFirstAddress={setIsFirstAddress}
                    userAddresses={userAddresses}
                    refreshList={() => {
                      getAddressOfUser();
                    }}
                  />
                </section>
              ) : null}
            </>
          ) : null}
        </section>
      </section>
    </section>
  );
};

export default Profile;
