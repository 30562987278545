import {
  setSelectedBrand,
  setSelectedCategory,
  setSelectedSubCategory
} from '../../redux/actions/productListing';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ProductEntity } from '../../types/type';
import ProductGridView from '../../components/ProductListing/ProductGridView';
import ProductHeader from '../../components/ProductListing/ProductHeader';
import ProductListView from '../../components/ProductListing/ProductListView';
import { RootState } from '../../redux/reducers';
import Toolbar from '../../components/ProductListing/Toolbar';
import { company_no } from '../../api/api';
import config from '../../config.json';
import { fetchCategories } from '../../redux/actions/actionCreators';
import { searchProducts } from '../../api/ProductListing';
import { useLocation } from 'react-router-dom';

const ProductListing = () => {
  const params: any = useLocation();
  // States used in the component and API calls
  const [allProducts, setAllProducts] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState<string>('productName');
  const [direction, setDirection] = useState<string>('asc');
  const [hasNext, setHasNext] = useState(true);

  // Redux states
  const productListingConfig = useSelector(
    (state: RootState) => state.productListingConfig
  );
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const categories = useSelector((state: RootState) => state.categories);

  const dispatch = useDispatch();
  /**
   * Function to load more products or less products
   */
  const loadMore = () => {
    setPageSize((page) => page + 10);
  };
  const loadLess = () => {
    setPageSize((page) => page - 10);
  };

  /**
   *
   * @param {number} minPrice minimun Price
   */
  const setMinPriceRange = (minPrice: number) => {
    setMinPrice(minPrice);
  };

  /**
   *
   * @param {number} maxPrice maximum Price
   */
  const setMaxPriceRange = (maxPrice: number) => {
    setMaxPrice(maxPrice);
  };

  /**
   *
   * @param {string} orderBy orderBy
   * @param {string} direction direction
   */
  const setOrderDirectionByValue = (orderBy: string, direction: string) => {
    setOrderBy(orderBy);
    setDirection(direction.toUpperCase());
  };

  /**
   * Async function to get all the products using the states
   */
  const getData = async () => {
    setIsLoading(true);
    let res = await searchProducts(
      company_no,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      orderBy,
      direction,
      pageSize,
      0,
      isWholesale,
      '',
      productListingConfig?.selectedCategory,
      productListingConfig?.selectedSubCategory,
      productListingConfig?.selectedBrand,
      maxPrice,
      minPrice
    );
    if (
      productListingConfig?.selectedSubCategory !== '' &&
      !params.state?.fromAccordian
    ) {
      dispatch(setSelectedSubCategory(''));
    }
    if (!isWholesale) {
      setAllProducts(
        res['product'].filter(
          (product: ProductEntity) => product.wholesaleProduct === false
        )
      );
    } else {
      setAllProducts(res['product']);
    }
    setTotalRecords(res['totalRecords']);
    setHasNext(res['hasNext']);
    setIsLoading(false);
  };

  // Effect to get the products on every parameter change
  useEffect(() => {
    getData();
  }, [
    productListingConfig?.selectedBrand,
    productListingConfig?.selectedCategory,
    minPrice,
    maxPrice,
    pageSize,
    orderBy,
    direction,
    isWholesale,
    categories,
    productListingConfig?.selectedSubCategory
  ]);

  // Effect to set the selected category to 'All' on component unmount
  useEffect(() => {
    setMinPrice(0);
    return () => {
      dispatch(setSelectedBrand('All'));
      dispatch(setSelectedCategory('All'));
      setMinPrice(0);
    };
  }, []);

  // Effect to fetch categories if categories do not exist
  useEffect(() => {
    if (
      categories?.filter((category) => {
        return (
          category.categoryName !== null &&
          category.categoryName !== undefined &&
          category.categoryName !== ''
        );
      })
    ) {
      dispatch(fetchCategories(company_no));
    }
  }, []);

  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${config.companyName} - ${
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .name
          }`}
        </title>
        <meta
          name="title"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.title
          }
        />
        <meta
          name="description"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.description
          }
        />
        <meta
          name="keywords"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.keywords
          }
        />
        <meta
          property="og:type"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.type
          }
        />
        <meta
          property="og:title"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.title
          }
        />
        <meta
          property="og:url"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.url
          }
        />
        <meta
          property="og:image"
          content={
            config.pages.filter((page) => page.name === 'Product Listing')[0]
              .seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}
      <section className="h-full min-h-screen flex flex-col flex-grow">
        <ProductHeader
          totalRecords={totalRecords}
          productCategory={productListingConfig?.selectedCategory}
        />
        <article className="px-5 lg:px-20 flex-grow">
          <Toolbar
            maxPrice={maxPrice}
            minPrice={minPrice}
            setMaxPrice={setMaxPriceRange}
            setMinPrice={setMinPriceRange}
            setOrderDirection={setOrderDirectionByValue}
          />
          {productListingConfig &&
          productListingConfig.selectedView === 'grid' ? (
            <ProductGridView
              allProducts={allProducts}
              loadMore={loadMore}
              loadLess={loadLess}
              isLoading={isLoading}
              hasNext={hasNext}
            />
          ) : (
            <ProductListView
              allProducts={allProducts}
              loadMore={loadMore}
              loadLess={loadLess}
              isLoading={isLoading}
              hasNext={hasNext}
            />
          )}
        </article>
      </section>
    </>
  );
};

export default ProductListing;
