import CONFIG from '../../config.json';
import { ReactComponent as Calendar } from '../../assets/images/Calendar.svg';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const SingleBlog = () => {
  let params = useParams();
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.title}
        </title>
        <meta
          name="title"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.title
          }
        />
        <meta
          name="description"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo
              .description
          }
        />
        <meta
          name="keywords"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo
              .keywords
          }
        />
        <meta
          property="og:type"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.type
          }
        />
        <meta
          property="og:title"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.title
          }
        />
        <meta
          property="og:url"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.url
          }
        />
        <meta
          property="og:image"
          content={
            CONFIG.blogs.filter((blog) => blog.id === params.id)[0].seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}
      <article className="mt-5 px-5 lg:px-20">
        <p className="flex space-x-2">
          <Calendar className="w-6 h-6" />
          <span>
            {
              CONFIG.blogs.find((blog) => {
                if (params.id !== undefined) {
                  return blog.id === params.id;
                }
                return null;
              })?.createdOn
            }
          </span>
        </p>
        <h1 className="text-text-title font-medium my-5 text-3xl">
          {
            CONFIG.blogs.find((blog) => {
              if (params.id !== undefined) {
                return blog.id === params.id;
              }
              return null;
            })?.title
          }
        </h1>
      </article>
      <div className="px-5 lg:px-20 font-poppins-regular flex flex-col lg:flex-row lg:justify-center space-x-0 lg:space-x-8">
        <section className="">
          <div className="overflow-hidden group w-full">
            <img
              src={
                CONFIG.blogs.find((blog) => {
                  if (params.id !== undefined) {
                    return blog.id === params.id;
                  }
                  return null;
                })?.thumbnailUrl
              }
              className="group-hover:scale-110 transition-all duration-500 object-cover w-full h-auto"
              alt=""
            />
          </div>
          <p className="text-text-body text-base mt-2">
            {
              CONFIG.blogs.find((blog) => {
                if (params.id !== undefined) {
                  return blog.id === params.id;
                }
                return null;
              })?.content
            }
          </p>
          <hr className="mt-10 mb-6" />
          <section>
            <button className="uppercase px-3 py-1 rounded-full text-xs text-text-body border border-slate-300">
              new
            </button>
            <hr className="my-6" />
          </section>
          <section className="flex justify-between mb-10 text-text-muted">
            <span className="underline-offset-8 underline hover:text-text-title cursor-pointer">
              Previous post
            </span>
            <span className="underline-offset-8 underline hover:text-text-title cursor-pointer">
              Next post
            </span>
          </section>
        </section>
        <section className="flex justify-end min-w-[30rem]">
          <article className="flex flex-col mb-10 max-w-md">
            <div className="overflow-hidden group">
              <img
                src={
                  CONFIG.blogs.find((blog) => {
                    if (params.id !== undefined) {
                      return blog.id === params.id;
                    }
                    return null;
                  })?.authorImage
                }
                alt={
                  CONFIG.blogs.find((blog) => {
                    if (params.id !== undefined) {
                      return blog.id === params.id;
                    }
                    return null;
                  })?.authorImageAlt
                }
                className="group-hover:scale-105 transition-all duration-500 w-full h-auto object-cover"
              />
            </div>
            <article className="text-center items-center border border-gray-100 pb-10">
              <h1 className="mt-5 text-lg font-medium text-text-title flex flex-col">
                {
                  CONFIG.blogs.find((blog) => {
                    if (params.id !== undefined) {
                      return blog.id === params.id;
                    }
                    return null;
                  })?.author
                }
                <small className="text-text-muted font-thin text-xs">
                  {
                    CONFIG.blogs.find((blog) => {
                      if (params.id !== undefined) {
                        return blog.id === params.id;
                      }
                      return null;
                    })?.authorDesignation
                  }
                </small>
              </h1>
              <p className="text-sm text-text-body my-3">
                {
                  CONFIG.blogs.find((blog) => {
                    if (params.id !== undefined) {
                      return blog.id === params.id;
                    }
                    return null;
                  })?.authorDescription
                }
              </p>
              <img
                src={
                  CONFIG.blogs.find((blog) => {
                    if (params.id !== undefined) {
                      return blog.id === params.id;
                    }
                    return null;
                  })?.authorSignatureImageUrl
                }
                alt=""
                className="mx-auto"
              />
            </article>
          </article>
        </section>
      </div>
    </>
  );
};

export default SingleBlog;
