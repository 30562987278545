import { ProductEntity } from '../../../types/type';
import defaultImage from '../../../assets/images/default-image.png';

interface Props {
  list: Array<ProductEntity>;
  onClickNavigate: (path: string) => void;
  isVisible: boolean;
  extraClasses?: string;
  afterRedirect?: () => void;
}

const DropDownList = (props: Props) => {
  return (
    <>
      {props.isVisible && props.list.length !== 0 ? (
        <div
          className={`w-[90%] h-64 mr-16 absolute top-[3.7rem] overflow-y-scroll shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-[1] ${props.extraClasses}`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}>
          <div className="text-left" role="none">
            {props.list.map((product, index) => (
              <p
                onClick={() => {
                  props.onClickNavigate(`/products/${product.id}`);
                  if (props.afterRedirect !== undefined) {
                    props.afterRedirect();
                  }
                }}
                key={index}
                className="text-gray-700 grid auto-rows-auto grid-cols-4 text-left px-3 hover:bg-bg-primary-light border-b space-x-2 py-3 text-sm cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0">
                <section className="h-8 w-8 col-span-1 grid place-items-center object-cover object-center overflow-hidden">
                  <img
                    src={product.productImageUrl}
                    onError={(e) => {
                      e.currentTarget.src = defaultImage;
                    }}
                    alt=""
                  />
                </section>
                <span className="col-span-3 capitalize line-clamp-2">
                  {product.productName.toLowerCase()}
                </span>
              </p>
            ))}
          </div>
        </div>
      ) : props.isVisible && props.list.length === 0 ? (
        <div
          className="origin-top-right w-[90%] h-64 mr-16 absolute top-[3.7rem] overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-[1]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}>
          <div className="text-left" role="none">
            <p
              className="text-gray-700  px-3  py-3 text-sm "
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0">
              No Products Found
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DropDownList;
