import { Action } from "../../types/type";

/**
 * userReducer - function to handle actions pertaining to user
 * @param {any} state - state passed to the reducer
 * @param {Action} action - action that is dipatched
 * @returns user state
 */
const userReducer = (state={},action:Action) => {
    if(typeof action.type !== undefined){
        switch(action.type){
            case 'SET_USER_DATA':{
                return {...action.payload};
            }
                default:{
                    return state
                }
        }
    }
}
 
export default userReducer;