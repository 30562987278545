import {
  ADD_TO_CART_WITHOUT_LOGIN,
  REMOVE_FROM_CART_WITHOUT_LOGIN,
  RESET_CART,
  UPDATE_CART_WTIHOUT_LOGIN
} from '../actionTypes';

import { AddToCartPayload } from '../../../types/type';

/**
 * addToCartWithoutLogin - function to create an action for adding product to cart
 * @param {AddToCartPayload} payload - payload object to store in state
 * @returns {Action} - action which is dispatched
 */
export const addToCartWithoutLogin = (payload: AddToCartPayload) => {
  return {
    type: ADD_TO_CART_WITHOUT_LOGIN,
    payload: payload
  };
};

/**
 * removeFromCartWithoutLogin - function to create an action that removes product from store
 * @param {string} payload - id of product
 * @returns {Action} - an action to be dispatched
 */
export const removeFromCartWithoutLogin = (payload: string) => {
  return {
    type: REMOVE_FROM_CART_WITHOUT_LOGIN,
    payload: payload
  };
};

/**
 * updateCartWithoutLogin
 * @param {AddToCartPayload} payload - payload to be stored
 * @returns {Action} - returns an action to be dispatched
 */
export const updateCartWithoutLogin = (payload: AddToCartPayload) => {
  return {
    type: UPDATE_CART_WTIHOUT_LOGIN,
    payload: payload
  };
};

/**
 * resetCart - function to create action that resets cart
 * @returns {Action} - action to be dispatched
 */
export const resetReduxCart = () => {
  return {
    type: RESET_CART
  };
};

/**
 * createAddToCartPayload - utility function to create neccessary object
 * @param {string} product - product id
 * @param {number} quantity - quantity
 * @param {Array} extra - array of add ons
 * @param {string} productName - product name
 * @param {string} imageUrl - image url
 * @param {string} productPrice - after discount price of product
 * @returns {AddToCartPayload} object of given type
 */
export const createAddToCartPayload = (
  product: string,
  quantity: number,
  extra: Array<any>,
  productName: string,
  imageUrl: string,
  productPrice: number
) => {
  return {
    product: product,
    quantity: quantity,
    cart: true,
    replace: true,
    status: true,
    extra: extra,
    productName: productName,
    imageUrl: imageUrl,
    productPrice: productPrice
  };
};
