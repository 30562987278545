import { useEffect, useState } from 'react';

import { Address } from '../../types/type';
import { deleteAddress } from '../../api/Profile';

interface Props {
  userAddresses: Array<Address>;
  onEditClick: (address: Address) => void;
  setIsFirstAddress: (isFirstAddress: boolean) => void;
  refreshList: () => void;
  toggleForm: () => void;
}

const AddressesList = ({
  userAddresses,
  onEditClick,
  toggleForm,
  refreshList
}: Props) => {
  /** loading state*/
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (userAddresses.length === 0) {
      setisLoading(true);
    } else {
      setisLoading(false);
    }
  }, [userAddresses]);

  /**
   * deleteAddressApi - async function to delete address
   * @param {number} addressId - id of address
   */
  const deleteAddressApi = async (addressId: number) => {
    const data = await deleteAddress(addressId);
    if (data) {
      setisLoading(true);
      refreshList();
    }
  };

  return (
    <>
      {userAddresses && userAddresses.length
        ? userAddresses.map((address: any, index: number) => (
            <section
              className="border mb-2 border-black border-opacity-10 p-6"
              key={index}>
              {address?.defaultAddress ? (
                <section className="text-base md:text-xl font-bold border-b mt-2 mb-3 pb-2">
                  Default
                </section>
              ) : null}
              <p className="text-base md:text-xl font-thin">
                {address?.fullName}
              </p>
              <p className="text-sm md:text-base font-thin">
                {address.addressLine1}
              </p>
              <p className="text-sm md:text-base font-thin">
                {address?.addressLine2}
              </p>
              <p className="text-sm md:text-base font-thin">
                {address?.zipCode}
              </p>
              <p className="text-sm md:text-base font-thin">
                {address?.addressType}
              </p>
              <p className="flex flex-row mt-4 h-11 space-x-2">
                <button
                  onClick={() => {
                    window.scrollTo(0, 120);
                    onEditClick(address);
                    toggleForm();
                  }}
                  className="px-5 text-md md:text-md text-center  bg-primary text-white hover:bg-text-title">
                  Edit
                </button>
                {!address.defaultAddress ? (
                  <button
                    onClick={() => deleteAddressApi(address.id)}
                    className="bg-white px-3 text-md md:text-md text-center border border-primary hover:bg-primary hover:text-white">
                    Delete
                  </button>
                ) : null}
              </p>
            </section>
          ))
        : null}
    </>
  );
};

export default AddressesList;
