import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Filter from './Filter';
import { RootState } from '../../redux/reducers';
import icons from '../../assets/icons';
import { setSelectedView } from '../../redux/actions/productListing';

const sortFilters = [
  // { name: 'Bestselling', orderBy: '', direction: '' },
  { name: 'Name, A to Z', orderBy: 'productName', direction: 'asc' },
  { name: 'Name, Z to A', orderBy: 'productName', direction: 'desc' },
  { name: 'Price, Low to High', orderBy: 'productPrice', direction: 'asc' },
  { name: 'Price, High to Low', orderBy: 'productPrice', direction: 'desc' }
];
interface ToolbarProps {
  minPrice: number;
  setMinPrice: (minPrice: number) => void;
  maxPrice: number;
  setMaxPrice: (maxPrice: number) => void;
  setOrderDirection: (orderBy: string, direction: string) => void;
}
const Toolbar = ({
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  setOrderDirection
}: ToolbarProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const toggleFilters = () => setIsFiltersOpen(!isFiltersOpen);
  const productListingConfig = useSelector(
    (state: RootState) => state.productListingConfig
  );
  const dispatch = useDispatch();
  return (
    <>
      <section className="flex justify-between mt-10">
        <Filter
          isFiltersOpen={isFiltersOpen}
          toggleFilters={toggleFilters}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
        />
        <article className="flex-1">
          <div className="flex space-x-4 justify-center h-full">
            <img
              className={`cursor-pointer w-5 h-5 my-auto ${
                productListingConfig &&
                productListingConfig.selectedView === 'grid'
                  ? 'opacity-60'
                  : ''
              }`}
              src={icons.rightMenu}
              alt=""
              onClick={() => {
                productListingConfig &&
                  productListingConfig.selectedView === 'grid' &&
                  dispatch(setSelectedView('list'));
                setIsFiltersOpen(false);
              }}
            />
            <img
              className={`cursor-pointer w-5 h-5 my-auto ${
                productListingConfig &&
                productListingConfig.selectedView === 'grid'
                  ? ''
                  : 'opacity-60'
              }`}
              onClick={() => {
                productListingConfig &&
                  productListingConfig.selectedView === 'list' &&
                  dispatch(setSelectedView('grid'));
                setIsFiltersOpen(false);
              }}
              src={icons.gridView}
              alt=""
            />
          </div>
        </article>
        <article className="flex-1">
          <div className="flex justify-end align-middle">
            <label
              htmlFor="sortFilter"
              className="my-auto text-text-muted hidden md:inline-block">
              Sort By:
            </label>
            <select
              name=""
              id="sortFilter"
              onChange={(e) => {
                let index = sortFilters.findIndex(
                  (filter) => filter.name === e.target.value
                );
                setOrderDirection(
                  sortFilters[index].orderBy,
                  sortFilters[index].direction
                );
              }}
              className="bg-transparent focus:outline-none px-4 py-2 text-left">
              {sortFilters.map((singleSortFilter, index) => (
                <option key={index} value={singleSortFilter.name}>
                  {singleSortFilter.name}
                </option>
              ))}
            </select>
          </div>
        </article>
      </section>
    </>
  );
};

export default Toolbar;
