import { ReactComponent as LeftArrow } from '../../assets/icons/leftArrowActive.svg';
import { ReactComponent as LeftBestSeller } from '../../assets/icons/leftbest.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrowActive.svg';
import { ReactComponent as RightBestSeller } from '../../assets/icons/rightbest.svg';

const CustomArrowsSide = ({ next, previous, isBestSeller }: any) => {
  return (
    <>
      {isBestSeller ? (
        <>
          <button
            className="lg:block absolute top-0 left-0 h-full"
            onClick={previous}>
            <LeftBestSeller className=" rounded-full h-10 w-10 bg-slate-300 hover:bg-slate-400 text-opacity-30  border-opacity-100 border-double md:w-[50px] md:h-[50px] relative top-[-1.5rem]" />
          </button>
          <button
            className="lg:block absolute top-0 right-0 h-full"
            onClick={next}>
            <RightBestSeller className="rounded-full h-10 w-10 bg-slate-300 hover:bg-slate-400 text-opacity-30  border-opacity-100 border-double md:w-[50px] md:h-[50px] relative top-[-1.5rem]" />
          </button>
        </>
      ) : (
        <>
          <button className="absolute top-0 -left-9 h-full" onClick={previous}>
            <LeftArrow className="text-opacity-30" />
          </button>
          <button className="absolute top-0 -right-9 h-full" onClick={next}>
            <RightArrow className="text-opacity-30 md" />
          </button>
        </>
      )}
    </>
  );
};
export default CustomArrowsSide;
