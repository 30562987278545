import { RESET_LOGIN_CART, SET_LOGIN_CART } from '../actionTypes';

import { getCartDataList } from '../../../api/CartApi';

/**
 * setLoginCart - action creater
 * @param {any} payload - list of cart items
 * @returns action to be dispatched which sets login cart
 */
export const setLoginCart = (payload: any) => {
  return {
    type: SET_LOGIN_CART,
    payload: payload
  };
};

/**
 * resetLoginCart - action creator to return an action that resets login Cart
 * @returns {Action} - action which resets cart
 */
export const resetLoginCart = () => {
  return {
    type: RESET_LOGIN_CART
  };
};

export const getLoginCart = () => {
  return (dispatch: any) => {
    getCartDataList().then((res) => dispatch(setLoginCart(res)));
  };
};
