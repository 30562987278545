import { Category, SubCategory } from '../../../types/type';
import React, { useEffect, useRef, useState } from 'react';
import {
  setSelectedCategory,
  setSelectedSubCategory
} from '../../../redux/actions/productListing';

import { config } from '../../../config/config';
import defaultImage from '../../../assets/images/default-image.png';
import { getSubCategories } from '../../../api/company';
import icons from '../../../assets/icons';
import { setCategoryMenu } from '../../../redux/actions/drawer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface AccordionProps {
  category?: Category;
  title: string;
  content: React.ReactNode;
  className?: string;
  html?: string;
  closeNavbar?: () => void;
  isOpen?: boolean;
  noTextPadding?: boolean;
  hasImage?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  category,
  content,
  className,
  html,
  title,
  isOpen,
  noTextPadding,
  hasImage,
  closeNavbar = () => {}
}) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const [rotate, setRotate] = useState('duration-700 ease');
  const [subCategories, setsubCategories] = useState<SubCategory[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    /**getSub - async function to get subcategories */
    const getSub = async () => {
      try {
        const data = await getSubCategories(
          config.COMPANY_NO,
          category?.categoryName
        );
        setsubCategories(data['subCategory']);
      } catch (err) {}
    };
    if (category?.subCategoryExists) {
      getSub();
    }
  }, []);
  const contentSpace = useRef(null);

  function toggleAccordion() {
    if (subCategories.length !== 0 || isOpen) {
      setActive(active === false ? true : false);
      // @ts-ignore
      setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
      setRotate(active ? 'duration-700 ease' : 'duration-700 ease rotate-180');
    }
    if (category?.categoryName && !category?.subCategoryExists) {
      dispatch(setSelectedCategory(category?.categoryName));
      dispatch(setSelectedSubCategory('All'));
      dispatch(setCategoryMenu(false));
      navigate('/products');
    }
  }

  return (
    <div className={'flex flex-col  w-full ' + (className ? className : '')}>
      <button
        className="py-3 grid w-full auto-rows-auto grid-cols-10 box-border appearance-none cursor-pointer focus:outline-none"
        onClick={toggleAccordion}>
        <section className="col-span-8 flex flex-row align-middle py-2">
          {/* For hide Category Icon commenting code*/}
          {/* {hasImage ? (
            <img
              className="h-8 w-8 ml-2 my-auto mr-2"
              src={category?.categoryImageURL}
              onError={(e) => {
                e.currentTarget.src = defaultImage;
              }}
              alt=""
            />
          ) : null} */}
          <p
            className={
              ' text-left mt-1 ml-5 font-thin capitalize' +
              (noTextPadding ? '' : ' px-2')
            }>
            {category?.categoryName
              ? category?.categoryName.toLowerCase()
              : title.toLowerCase()}
          </p>
        </section>
        {subCategories.length !== 0 || isOpen ? (
          !active ? (
            <section className="grid justify-items-center place-self-end col-span-2 my-auto mr-2">
              <img className="" src={icons.downArrow} alt="" />
            </section>
          ) : (
            <section className="grid justify-items-center place-self-end col-span-2 my-auto mr-2">
              <img className="" src={icons.upArrow} alt="" />
            </section>
          )
        ) : null}
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto transition-max-height duration-700 ease-in-out">
        {html ? (
          <div className="" dangerouslySetInnerHTML={{ __html: html }}></div>
        ) : (
          <div className="pb-10">
            {subCategories && subCategories.length !== 0 ? (
              <ul className="ml-12">
                {subCategories.map((cat: SubCategory, index: number) => (
                  <li
                    onClick={() => {
                      dispatch(setSelectedCategory(cat.categoryName));
                      dispatch(setSelectedSubCategory(cat.subCategoryName));
                      dispatch(setCategoryMenu(false));
                      window.scrollTo(0, 0);
                      navigate('/products', {
                        state: {
                          fromAccordian: true
                        }
                      });
                    }}
                    className="capitalize text-md cursor-pointer flex flex-row align-middle"
                    key={index}>
                    <img
                      className="h-10 w-10 my-auto"
                      src={cat?.subCategoryImageURL}
                      onError={(e) => {
                        e.currentTarget.src = defaultImage;
                      }}
                      alt=""
                    />
                    <span className="ml-2 text-text-body mt-2">
                      {cat?.subCategoryName.toLowerCase()}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="font-extralight text-sm">
                {content ? content : null}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
