import { LOGIN, LOGOUT, UPDATE_TOKEN } from '../actions/actionTypes';

import { Action } from '../../types/type';

const inititalState = false;

const authReducer = (state = inititalState, { type, payload }: Action) => {
  if (typeof type !== undefined) {
    switch (type) {
      case LOGIN:
        localStorage.setItem('accessToken', payload);
        return true;
      case LOGOUT:
        localStorage.removeItem('accessToken');
        return false;
      case UPDATE_TOKEN:
        localStorage.setItem('accessToken', payload);
        return true;
      default:
        return state;
    }
  }
};

export default authReducer;
