import { fetchProducts, searchProducts } from '../../api/ProductListing';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Button from '../common/Button/Button';
import Carousel from 'react-multi-carousel';
import { ReactComponent as LeftArrow } from '../../assets/icons/Icon updated/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/Icon updated/arrow-right.svg';
import ProductCategoryCard from '../ProductCarousel/ProductCategoryCard';
import { ProductEntity } from '../../types/type';
import { RootState } from '../../redux/reducers';
import { company_no } from '../../api/api';
import { config } from '../../config/config';
import { getSubCategories } from '../../api/company';
import { setSelectedCategory } from '../../redux/actions/productListing';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hooks/WindowDimensionsHook';

interface CategoryComponentProps {
  category: any;
}

export const CustomButtonGroupAsArrows = ({ next, previous }: any) => {
  return (
    <div className="lg:w-auto lg:block absolute pt-5 pb-4 top-0 right-0 space-x-8">
      <button className="" onClick={previous}>
        <LeftArrow className=" rounded-full h-10 w-10 bg-slate-300 hover:bg-slate-400 text-opacity-30" />
      </button>

      <button className="" onClick={next}>
        <RightArrow className="rounded-full h-10 w-10 bg-slate-300 hover:bg-slate-400 text-opacity-30" />
      </button>
    </div>
  );
};

const CategoryComponent = ({ category }: CategoryComponentProps) => {
  const responsiveness = {
    monitor: {
      breakpoint: { max: 5000, min: 1921 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 1920, min: 1025 },
      items: 3,
      paritialVisibilityGutter: 15
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 120
    }
  };
  /**state to indicate whether given category has any products */
  const [isVisible, setisVisible] = useState(false);
  /**state which stores all products retrieved from api call */
  const [products, setproducts] = useState([]);
  /**state to indicate whether api has returned a response or not */
  const [loading, setloading] = useState(true);
  /**state to store subcategories */
  const [subcategories, setsubcategories] = useState<any>([] as any);
  const [subcategoryProducts, setsubcategoryProducts] = useState({});
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * useEffect - calls async function getProducts to get products
   */
  useEffect(() => {
    /**
     * getProducts - async function to fetch all products from a particular category
     */
    const getProducts = async () => {
      if (category) {
        const data = await searchProducts(
          company_no,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'productName',
          'ASC',
          0,
          0,
          isWholesale,
          '',
          category.categoryName
        );
        if (data['product'] && data['product'].length > 0) {
          if (!isWholesale) {
            setproducts(
              data['product'].filter(
                (product: ProductEntity) => product.wholesaleProduct === false
              )
            );
          } else {
            setproducts(data['product']);
          }
          setisVisible(true);
          setloading(false);
        } else {
          setisVisible(false);
        }
      }
    };
    if (category.subCategoryExists) {
      getSubCategories(config.COMPANY_NO, category.categoryName).then((res) =>
        setsubcategories(res.subCategory)
      );
    }
    getProducts();
  }, [isWholesale]);

  /**
   * useEffect - api call which gets triggers further api call for getting subcategory products
   */
  useEffect(() => {
    if (subcategories && subcategories.length) {
      subcategories?.forEach((subcategory: any) => {
        fetchProducts(
          config.COMPANY_NO,
          category.categoryName,
          subcategory.subCategoryName
        ).then((res) =>
          setsubcategoryProducts((subcategoryProducts) => {
            return {
              ...subcategoryProducts,
              [subcategory.subCategoryName]: res
            };
          })
        );
      });
    }
  }, [subcategories]);
  return (
    <>
      {isVisible && (
        <section
          className={`px-5 mt-16 laptop:mt-40 text-center ${
            products && products.length > 0 ? 'block' : 'hidden'
          }`}
          id={category.id}
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-easing="ease-in-out">
          <section className="grid auto-rows-auto grid-cols-1 lg:grid-cols-7 pb-4 justify-around md:w-4/5 mx-auto">
            <section className="grid bg-primary-darker lg:w-auto lg:h-full lg:col-span-2 p-2">
              <section
                style={{
                  backgroundImage: `url(${category.categoryImageURL})`,
                  backgroundSize: '100% 100%',
                  backgroundRepeat: 'no-repeat'
                }}
                className=" min-h-[100px] flex flex-col justify-end ">
                <section className="flex flex-col h-4/6 items-center justify-around mb-5">
                  
                  <Button
                    className="bg-white hidden lg:block text-primary font-poppins-medium mt-12 font-bold py-3 px-6 hover:bg-primary hover:text-white transition-all duration-150"
                    title="View All"
                    onClick={() => {
                      dispatch(setSelectedCategory(category.categoryName));
                      window.scrollTo(0, 0);
                      navigate('/products', {
                        state: {
                          fromAccordian: false
                        }
                      });
                    }}></Button>
                </section>
              </section>
            </section>
            <section className="col-span-3 relative flex items-center lg:col-span-5 pt-16">
              {products.length > 0 && (
                <Carousel
                  responsive={responsiveness}
                  autoPlaySpeed={3000}
                  className="border border-gray-50/5"
                  infinite
                  draggable
                  swipeable
                  keyBoardControl
                  partialVisible
                  customButtonGroup={<CustomButtonGroupAsArrows />}
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  arrows={false}
                  renderButtonGroupOutside
                  itemClass="snap-center"
                  containerClass="snap-x px-5 pt-5 pb-0 box-content">
                  {products &&
                    products.map((product: any, index: number) => (
                      <ProductCategoryCard product={product} key={index} />
                    ))}
                </Carousel>
              )}
            </section>
            {category.subCategoryExists && subcategories.length && width < 1024
              ? subcategories.map((category: any) =>
                  category.productCount > 0 ? (
                    <section className="flex mx-auto justify-center items-center text-center mb-4 mt-4">
                      <div className="wave">
                        <svg
                          id="wave"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 456.7 39.9">
                          <path
                            d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                  c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                c8.9,6.8,15.9,13.7,16,13.8"
                          />
                        </svg>
                      </div>
                      <h1 className="text-text-title mx-4 lg:mx-10 font-poppins-regular text-55">
                        {category.subCategoryName}
                      </h1>
                      {/* <img className="h-full relative w-auto" src={best2} alt="" /> */}
                      <div className="wave">
                        <svg
                          id="wave"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 456.7 39.9">
                          <path
                            d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                  c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                c8.9,6.8,15.9,13.7,16,13.8"
                          />
                        </svg>
                      </div>
                    </section>
                  ) : null
                )
              : null}
          </section>
          <button
            onClick={() => {
              dispatch(setSelectedCategory(category.categoryName));
              window.scrollTo(0, 0);
              navigate('/products');
            }}
            className="lg:hidden bg-white hover:bg-primary hover:text-white text-primary px-6 py-2 border border-primary">
            View All
          </button>
        </section>
      )}
    </>
  );
};

export default CategoryComponent;
