import { useNavigate } from 'react-router-dom';
import { Blog } from '../../types/type';

interface BlogCardProps {
  blog: Blog;
}

const BlogCard = ({ blog }: BlogCardProps) => {
  let navigate = useNavigate();
  return (
    <article className="p-5">
      <div className="border group max-w-xl">
        <section
          className="overflow-hidden"
          onClick={() => {
            navigate(`/blog/${blog.id}`);
          }}>
          <img
            src={blog.thumbnailUrl}
            className="group-hover:scale-105 transition-all duration-500 object-cover cursor-pointer"
            alt={blog.title}
          />
        </section>
        <section className="p-6 space-y-2">
          <h3
            className="cursor-pointer text-text-base"
            onClick={() => {
              navigate(`/blog/${blog.id}`);
            }}>
            {blog.title}
          </h3>
          <p className="text-text-body">
            {blog.content.length > 200
              ? blog.content.substring(0, 200) + '...'
              : blog.content}
          </p>
          <button
            onClick={() => {
              navigate(`/blog/${blog.id}`);
            }}>
            Read More
          </button>
        </section>
      </div>
    </article>
  );
};

export default BlogCard;
