import { PostUser, User } from '../../types/type';
import { useCallback, useState } from 'react';

import { editUserDetails } from '../../api/Profile';
import { getUserInfoAction } from '../../redux/actions/actionCreators';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

interface Props {
  user: User;
  closeModal: () => void;
}

const ProfileForm = (props: Props) => {
  const [user, setuser] = useState<User>(props.user);
  const [formErrors, setFormErrors] = useState<any>({});
  const dispatch = useDispatch();
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validNumberRegex =
    /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;

  // This function set value on input change.
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setuser((previousState) => {
      return { ...previousState, [name]: value };
    });
  }, []);

  /**
   * validateCredentials - function to check whether the form is filled or not.
   */
  const validateCredentials = () => {
    let errors: any = {};
    if (!user.fullName) {
      errors.fullName = 'Name is required';
    }
    if (!user.email) {
      errors.email = 'Email is required';
    } else if (!validEmailRegex.test(user.email)) {
      errors.email = 'Email is invalid';
    }
    if (!user.mobileNumber) {
      errors.mobileNumber = 'Mobile number is required';
    } else if (!user.mobileNumber.match(validNumberRegex)) {
      errors.mobileNumber = 'Mobile number is invalid';
    }
    if (Object.keys(errors).length === 0) {
      onEdit();
    }
    return errors;
  };

  /**
   * onEdit - function to make api call for editing user
   */
  const onEdit = () => {
    const requestBody: PostUser = {
      id: user.id,
      companyNo: user.companyNo,
      email: user.email,
      fullName: user.fullName,
      receiveEmailNotifications: user.receiveEmailNotifications,
      receivePushNotifications: user.receivePushNotifications,
      dob: user.dob,
      mobileNumber: user.mobileNumber.substring(1)
    };
    editUserDetails(requestBody)
      .then((res) => {
        props.closeModal();
        dispatch(getUserInfoAction(user.id));
        toast.success('Information edited successfully');
      })
      .catch((err) => {
        console.log(err);
        toast.error('There was an error, please try again');
      });
  };

  return (
    <section className="border border-opacity-50 px-5 md:px-0">
      <section className="bg-white p-6">
        <section className="flex flex-row-reverse">
          <section
            onClick={() => props.closeModal()}
            className="cursor-pointer">
            <svg
              id="icon_navigation_close_24px"
              data-name="icon/navigation/close_24px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <rect id="Boundary" width="24" height="24" fill="none" />
              <path
                id="_Color"
                data-name=" ↳Color"
                d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                transform="translate(5 5)"
              />
            </svg>
          </section>
        </section>
        <section className="text-2xl mb-6 font-bold ">Profile Details</section>
        <section className="mb-6">
          <div className="text-text-base">Full Name</div>
          <div>
            <input
              className="w-full border border-r-1 border-gray-300 h-12 px-2 focus:outline-1 focus:outline-text-muted"
              type="text"
              name="fullName"
              value={user.fullName}
              onChange={handleOnChange}
            />
            {
              <span className="text-red-500 text-xs">
                {formErrors.fullName ? formErrors.fullName : ''}
              </span>
            }
          </div>
        </section>
        <section className="mb-6">
          <div className="text-text-base">Email Address</div>
          <div>
            <input
              className="w-full border border-r-1 border-gray-300 h-12 px-2 focus:outline-1 focus:outline-text-muted"
              type="text"
              name="email"
              value={user.email}
              onChange={handleOnChange}
            />
            {
              <span className="text-red-500 text-xs">
                {formErrors.email ? formErrors.email : ''}
              </span>
            }
          </div>
        </section>
        <section className="mb-6">
          <div className="text-text-base">Mobile Number</div>
          <div>
            <input
              className="w-full border border-r-1 border-gray-300 h-12 px-2 focus:outline-1 focus:outline-text-muted"
              type="text"
              name="mobileNumber"
              value={user.mobileNumber}
              onChange={handleOnChange}
            />
            {
              <span className="text-red-600 text-xs">
                {formErrors.mobileNumber ? formErrors.mobileNumber : ''}
              </span>
            }
          </div>
        </section>
        <p className="flex flex-row mt-4 h-11 space-x-2">
          <button
            onClick={() => setFormErrors(validateCredentials())}
            className="bg-primary-darker text-white  px-5 text-center border">
            Edit
          </button>
        </p>
      </section>
    </section>
  );
};

export default ProfileForm;
