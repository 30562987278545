import { LoginModalProps } from '../../../../types/type';
import { closeLoginModal } from '../../../../redux/actions/modals';
import { sendOTP } from '../../../../api/Auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const LoginComponent = ({
  setRoute,
  setPhoneNumber,
  phoneNumber
}: LoginModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  /**
   * @description - Sends OTP to the user and redirects to OTP screen
   * @returns {void} - Sends OTP to the user
   */
  let mobilePattern = new RegExp(/^\d{10}$/);
  const getOTP = async () => {
    setIsError(false);
    setIsLoading(true);
    if (mobilePattern.test(phoneNumber)) {
      let otpres = await sendOTP('91' + phoneNumber);
      if (otpres['status'] === 'OK') {
        toast.success(otpres['message']);
        setIsError(false);
        setRoute('otp');
        setIsLoading(false);
        return;
      } else {
        toast.error(otpres['message']);
        setIsError(true);
        setIsLoading(false);
        return;
      }
    } else {
      setIsError(true);
      toast.error('Phone number should be 10 digits only!');
      setIsLoading(false);
      return;
    }
  };

  return (
    <main className="z-[4] grid place-items-center h-full fixed w-full bg-gray-800 bg-opacity-50 mx-auto">
      <section className="relative">
        <div className="absolute top-0 right-0 mt-8 mr-8">
          <button
            className="bg-transparent text-xs text-gray-500 hover:text-white"
            type="button"
            onClick={() => {
              dispatch(closeLoginModal());
            }}>
            <span className="h-3 w-6">
              <svg
                className="fill-text-title"
                id="icon_navigation_close_24px"
                data-name="icon/navigation/close_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <rect id="Boundary" width="24" height="24" fill="none" />
                <path
                  id="_Color"
                  data-name=" ↳Color"
                  d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                  transform="translate(5 5)"
                />
              </svg>
            </span>
          </button>
        </div>

        <section className="bg-white shadow-lg max-w-md w-full p-16">
          <article className="mt-10 mb-16">
            <h3 className="text-center text-3xl border-b-2 border-primary p-2 w-1/2 mx-auto">
              Sign In
            </h3>
            <small className="text-sm my-5 text-text-body text-center block">
              Welcome! Enter your phone number to continue
            </small>
          </article>
          <form
            action=""
            className="grid auto-rows-auto grid-cols-1 gap-4 mt-4 mb-10">
            <div className="relative">
              <input
                className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors floating_label_input"
                type="tel"
                pattern="[0-9]{10}"
                placeholder="Phone Number"
                id="phoneNumber"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    getOTP();
                  }
                }}
                maxLength={10}
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
              <label
                className="absolute left-4 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-4 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
                htmlFor="phoneNumber">
                Phone Number
              </label>
            </div>
            <small
              className={`text-center -mt-2 text-red-500 ${
                isError ? 'block' : 'hidden'
              }`}>
              Invalid mobile number entered !
            </small>

            <button
              type="button"
              onClick={async () => {
                await getOTP();
              }}
              disabled={isLoading}
              className={`bg-primary text-white px-6 py-3 hover:bg-text-title font-bold text-sm`}>
              {isLoading ? (
                <div
                  style={{ borderTopColor: 'transparent' }}
                  className="border-t-transparent border-solid animate-spin rounded-full border-2 h-6 w-6 mx-auto"></div>
              ) : (
                'Get OTP'
              )}
            </button>
          </form>
        </section>
      </section>
    </main>
  );
};

export default LoginComponent;
