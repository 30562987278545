import { Link, useNavigate } from 'react-router-dom';
import {
  setSelectedBrand,
  setSelectedCategory,
  setSelectedSubCategory
} from '../../redux/actions/productListing';
import { useDispatch, useSelector } from 'react-redux';

import { BreadcrumbProps } from '../../types/type';
import { RootState } from '../../redux/reducers';
import icons from '../../assets/icons';
import { useEffect } from 'react';

const Breadcrumbs = ({ breadcrumbArray }: BreadcrumbProps) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const categories = useSelector((state: RootState) => state.categories);

  return (
    <article className=" lg:absolute flex space-x-2">
      {breadcrumbArray &&
        breadcrumbArray.map((breadcrumb, index) => {
          if (index === breadcrumbArray.length - 1) {
            return (
              <p
                key={index}
                className={`productNameTitle truncate text-text-base text-xs font-medium ${
                  index === breadcrumbArray.length - 1
                    ? 'uppercase'
                    : 'capitalize text-opacity-60'
                }`}>
                <span
                  className={
                    index === breadcrumbArray.length - 1
                      ? ''
                      : 'text-opacity-60'
                  }>
                  {breadcrumb}
                </span>
                <span className="text-lg">
                  {!(index === breadcrumbArray.length - 1) ? (
                    <img
                      className="inline mx-2"
                      src={icons.forward}
                      alt="next"
                    />
                  ) : null}{' '}
                </span>
              </p>
            );
          } else if (
            categories?.some(
              (category) =>
                category.categoryName.toLowerCase() ===
                breadcrumb.toLowerCase()
            )
          ) {
            return (
              <span
                onClick={() => {
                  navigate('/products');
                  dispatch(setSelectedCategory(breadcrumb));
                  dispatch(setSelectedSubCategory('All'));
                }}
                key={index}
                className={`text-text-base text-xs cursor-pointer ${
                  index === breadcrumbArray.length - 1
                    ? ''
                    : 'capitalize text-opacity-60'
                }`}>
                <span
                  className={
                    index === breadcrumbArray.length - 1
                      ? ''
                      : 'capitalize text-opacity-60'
                  }>
                  {breadcrumb.toLowerCase()}
                </span>
                <span className="text-lg">
                  {!(index === breadcrumbArray.length - 1) ? (
                    <img
                      className="inline mx-2"
                      src={icons.forward}
                      alt="next"
                    />
                  ) : null}
                </span>
              </span>
            );
          } else {
            return (
              <Link
                to={`/${
                  breadcrumbArray[index] === 'home'
                    ? ''
                    : breadcrumbArray[index]
                }`}
                onClick={() => {
                  breadcrumbArray[index].toLowerCase() === 'products' &&
                    dispatch(setSelectedCategory('All'));
                  dispatch(setSelectedBrand('All'));
                }}
                key={index}
                className={`text-text-base text-xs font-medium ${
                  index === breadcrumbArray.length - 1
                    ? 'uppercase'
                    : 'capitalize text-opacity-60'
                }`}>
                <span
                  className={
                    index === breadcrumbArray.length - 1
                      ? ''
                      : 'text-opacity-60'
                  }>
                  {breadcrumb}
                </span>
                <span className="text-lg">
                  {' '}
                  {!(index === breadcrumbArray.length - 1) ? (
                    <img
                      className="inline mx-2"
                      src={icons.forward}
                      alt="next"
                    />
                  ) : null}{' '}
                </span>
              </Link>
            );
          }
        })}
    </article>
  );
};

export default Breadcrumbs;
