import React, { useEffect, useRef, useState } from 'react';
import { addToCart, addToWishlist } from '../../api/CartApi';
import {
  closeQuickViewModal,
  openLoginModal,
  unsetQuickViewProduct
} from '../../redux/actions/modals';
import {
  getCartCountAction,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import { useDispatch, useSelector } from 'react-redux';

import AddedToCartModal from '../AddedToCartModal/AddedToCartModal';
import CountStepper from '../common/CountStepper/CountStepper';
import { RootState } from '../../redux/reducers';
import { getLoginCart } from '../../redux/actions/loginCart';
import { toast } from 'react-toastify';

export default function QuickViewModal() {
  const [productQuantity, setproductQuantity] = useState(1);
  const [isModalOpen, setisModalOpen] = useState(false);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const modals = useSelector((state: RootState) => state.modals);
  const quickViewProduct = useSelector(
    (state: RootState) => state.quickViewProduct
  );
  const dispatch = useDispatch();

  /**
   * changeQuantity - function to change quantity of product
   * @param {string} change - increase or decrease quantity
   */
  const changeQuantity = (change: string) => {
    switch (change) {
      case 'increase': {
        setproductQuantity((productQuantity) => productQuantity + 1);
        break;
      }
      case 'decrease': {
        if (productQuantity > 1) {
          setproductQuantity((productQuantity) => productQuantity - 1);
        }
        break;
      }
      default: {
        setproductQuantity(productQuantity);
      }
    }
  };
  /**
   * wishList - Function to add product in wishlist
   */
  const wishList = async () => {
    if (isLoggedIn) {
      if (quickViewProduct) {
        const data = await addToWishlist(quickViewProduct.id);
        if (data) {
          toast.success('Item added to wishlist');
          dispatch(getCartCountAction());
          dispatch(getWishListCountAction());
          dispatch(closeQuickViewModal());
          dispatch(getLoginCart());
        }
      }
    } else {
      toast.error('Please login to add to wishlist');
      dispatch(openLoginModal());
    }
  };

  /**
   * cart - async function to call api for adding product to cart
   */
  const cart = async () => {
    if (isLoggedIn) {
      if (quickViewProduct) {
        const data = await addToCart(quickViewProduct.id, productQuantity);
        if (data) {
          toast.success('Item added to cart');
          setisModalOpen(true);
          dispatch(getWishListCountAction());
          dispatch(getCartCountAction());
          dispatch(closeQuickViewModal());
          dispatch(getLoginCart());
        }
      }
    } else {
      toast.error('Please login to add to cart');
      dispatch(openLoginModal());
    }
  };
  return (
    <>
      {modals?.isQuickViewModalOpen ? (
        <div className="h-20 md:h-auto absolute top-24">
          <div className="justify-center text-left items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[3] outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-4 mt-10 md:mt-0 max-w-3xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto  border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      dispatch(closeQuickViewModal());
                      dispatch(unsetQuickViewProduct());
                    }}>
                    <span className="h-3 w-6">
                      <svg
                        className="fill-text-title"
                        id="icon_navigation_close_24px"
                        data-name="icon/navigation/close_24px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <rect
                          id="Boundary"
                          width="24"
                          height="24"
                          fill="none"
                        />
                        <path
                          id="_Color"
                          data-name=" ↳Color"
                          d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                          transform="translate(5 5)"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                {/*body*/}
                <section className="mx-6 mb-8 grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <section className="grid border place-items-center object-center overflow-hidden object-cover h-56 lg:h-auto p-4">
                    <img
                      className="h-44"
                      src={
                        quickViewProduct?.productImageUrl ||
                        quickViewProduct?.product_url
                      }
                      alt=""
                    />
                  </section>
                  <section className="lg:col-span-2">
                    <p className="text-text-muted font-poppins-medium">
                      {quickViewProduct?.productName ||
                        quickViewProduct?.product_name}
                    </p>
                    <span className="mt-3 text-text-title font-poppins-semibold">
                      Rs {quickViewProduct?.afterDiscountPrice}{' '}
                      <span className="line-through text-text-muted font-poppins-regular">
                        Rs {quickViewProduct?.productPrice}
                      </span>
                    </span>
                    <section className="flex mt-3 space-x-2">
                      <p className="text-xs capitalize">Availability :</p>
                      <p
                        className={`text-xs ${
                          quickViewProduct?.available
                            ? 'text-green-400'
                            : 'text-red-400'
                        }`}>
                        {quickViewProduct?.available
                          ? 'IN STOCK'
                          : 'OUT OF STOCK'}
                      </p>
                    </section>
                    <section className="h-40 overflow-y-scroll">
                      <p
                        className="text-xs text-text-muted break-all mt-2"
                        dangerouslySetInnerHTML={{
                          __html: quickViewProduct?.description
                        }}></p>
                    </section>
                    <p className="flex flex-row mt-2 space-x-2">
                      <CountStepper
                        setCount={setproductQuantity}
                        count={productQuantity}
                        increaseCount={() => changeQuantity('increase')}
                        reduceCount={() => changeQuantity('decrease')}
                      />
                      <button
                        onClick={() => cart()}
                        disabled={productQuantity === 0}
                        className="bg-primary text-xs lg:text-sm text-white px-6 disabled:opacity-50 disabled:cursor-default py-2 hover:bg-text-title">
                        Add to cart
                      </button>
                      <article
                        onClick={() => {
                          wishList();
                        }}
                        className="w-8 group overflow-hidden hidden bg-white md:grid place-items-center hover:bg-primary cursor-pointer transition-colors duration-150 mx-1 hover:fill-white hover:stroke-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          onClick={() => wishList()}
                          className={`cursor-pointer group-hover:fill-white group-hover:stroke-white ${
                            quickViewProduct.wishlist
                              ? 'fill-red-500 stroke-red-500'
                              : ''
                          }`}>
                          <path
                            id={`wishlist_path_${
                              quickViewProduct?.product_id ||
                              quickViewProduct?.id
                            }`}
                            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                        </svg>
                      </article>
                    </p>
                  </section>
                </section>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[1] bg-black"></div>
        </div>
      ) : null}

      <AddedToCartModal
        isVisible={isModalOpen}
        product={quickViewProduct}
        quantity={productQuantity}
        closeModal={() => setisModalOpen(false)}
      />
    </>
  );
}
