const ProductGridLoading = () => {
  return (
    <section className="group flex flex-col relative">
      <article className="border bg-gray-400 animate-pulse grid place-items-center h-48 sm:h-56 md:h-60 lg:h-64 xl:h-72 object-cover object-center overflow-hidden relative"></article>
      <article className="mt-4">
        <p className="text-gray-400 bg-gray-400 animate-pulse text-xs md:text-sm font-medium cursor-pointer">
          Product Name
        </p>
        <article className="space-x-2 cursor-pointer mt-2">
          <span className="text-gray-400 bg-gray-400 animate-pulse font-bold">
            &#8377;100
          </span>
          <del className="text-gray-400 bg-gray-400 animate-pulse">
            &#8377;100
          </del>
        </article>
      </article>
    </section>
  );
};

export default ProductGridLoading;
