import {
  categoriesReducers,
  companyDetailsReducer,
  companyInfoReducer
} from './companyReducer';

import authReducer from './authReducer';
import { cartReducer } from './cartReducer';
import { combineReducers } from 'redux';
import { configReducer } from './configReducer';
import countReducer from './countReducer';
import { drawerReducer } from './drawerReducer';
import { loginCartReducer } from './loginCartReducer';
import modalReducer from './modalReducer';
import { othersReducer } from './othersReducer';
import productReducer from './productReducer';
import quickViewProduct from './quickViewProduct';
import topReducer from './topReducer';
import userReducer from './userReducer';
import wholesaleReducer from './wholesaleReducer';

const allReducers = combineReducers({
  companyDetails: companyDetailsReducer,
  companyInfo: companyInfoReducer,
  categories: categoriesReducers,
  productListingConfig: productReducer,
  isLoggedIn: authReducer,
  user: userReducer,
  modals: modalReducer,
  counts: countReducer,
  isWholesale: wholesaleReducer,
  quickViewProduct: quickViewProduct,
  drawers: drawerReducer,
  cart: cartReducer,
  config: configReducer,
  others: othersReducer,
  loginCart: loginCartReducer,
  topValue: topReducer
});

export type RootState = ReturnType<typeof allReducers>;
export default allReducers;
