import React, { useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal') as HTMLElement;

/**
 *
 * @param {ReactChildren} param0 - children to render in the modal
 * @returns  {ReactPortal} - returns a portal to render the children in the modal
 */
const Modal: React.FC<{}> = ({ children }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const current = el.current;
    modalRoot!.appendChild(current);
    return () => void modalRoot!.removeChild(current);
  }, []);

  return createPortal(children, el.current);
};

export default Modal;
