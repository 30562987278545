import { ProductEntity, ProductListingViewProps } from '../../types/type';

import { ReactComponent as NoProducts } from '../../assets/images/NoProducts.svg';
import ProductListCard from './ProductListCard';
import ProductListLoading from './ProductListLoading';

const ProductListView = ({
  allProducts,
  isLoading,
  loadMore,
  hasNext
}: ProductListingViewProps) => {
  return (
    <>
      {allProducts && allProducts.length > 0 ? (
        <>
          <section className="mt-7 container">
            {allProducts.map((product: ProductEntity) => {
              return <ProductListCard product={product} key={product.id} />;
            })}
            {isLoading && <ProductListLoading />}
          </section>
          <section className="grid place-items-center my-6 lg:my-10">
            {hasNext && (
              <button
                className="bg-white border border-primary text-primary px-6 py-2 hover:bg-primary hover:text-white transition-all duration-150"
                onClick={() => {
                  loadMore();
                }}>
                Load More
              </button>
            )}
          </section>
        </>
      ) : (
        <section className="mt-7 container px-4">
          {isLoading ? (
            <ProductListLoading />
          ) : (
            <div className="flex flex-col items-center text-center">
              <NoProducts className="mt-40" />
              <h1 className="text-2xl font-bold mt-6">
                Sorry! No products found :(
              </h1>
              <p className="mt-5 text-text-body font-bold px-10">
                Sorry, We couldn't find anything. Try clearing the filters or
                apply other filters!
              </p>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default ProductListView;
