import {
  RESET_COUNTS,
  SET_CART_COUNT,
  SET_CATEGORIES,
  SET_COMPANY_DETAILS,
  SET_COMPANY_INFO,
  SET_WISHLIST_COUNT
} from './actionTypes';
import {
  company,
  getCategories,
  getCompanyInformation,
  getConfig,
  getTestimonials
} from '../../api/company';
import { getCartCount, getWishListCount } from '../../api/CartApi';

import { CompanyInfo } from '../../types/type';
import { getUserInfo } from '../../api/Profile';
import { revertConfig, setConfig } from './config';
import { setTestimonials } from './others';

/** company - async action creater, returns an action after calling and api to set company details in store to dispatch later  */
export const getCompany = (companyId: number) => {
  return (dispatch: any) => {
    company(companyId)
      .then((res) => dispatch(setCompany(res)))
      .catch((err) => console.error(err));
  };
};

/** setCompany - returns an action , when dispatched stores company details in store */
export const setCompany = (payload: any) => ({
  type: SET_COMPANY_DETAILS,
  payload: payload
});

/** getCompanyInfo - async action creater, returns an action after calling company info api  */
export const getCompanyInfo = (companyId: number) => {
  return (dispatch: any) => {
    getCompanyInformation(companyId)
      .then((res) => dispatch(setCompanyInfo(res)))
      .catch((err) => {});
  };
};

/** setCompanyInfo - returns an action, when dispatched stores company info in store  */
export const setCompanyInfo = (payload: CompanyInfo) => ({
  type: SET_COMPANY_INFO,
  payload: payload
});

/** fetchCategories - Function to make api call for categories */
export const fetchCategories = (
  companyId: number,
  page?: number,
  size?: number
) => {
  return (dispatch: any) => {
    getCategories(companyId, page, size)
      .then((res) => dispatch(setCategories(res)))
      .catch((err) => console.error(err));
  };
};

/** setCategories- return an action to store categories in state */
export const setCategories = (payload: any) => ({
  type: SET_CATEGORIES,
  payload: payload
});

/**
 * setUserData - function to set user's data received in payload
 * @param {any} payload - user's data
 * @returns {Action}
 */
export const setUserData = (payload: any) => ({
  type: 'SET_USER_DATA',
  payload: payload
});

/**
 * getUserInfoAction - action creator for making an api call
 * @param {any} payload
 * @returns {Action} - returns a dispatch
 */
export const getUserInfoAction = (payload: any) => {
  return (dispatch: any) => {
    getUserInfo(payload)
      .then((res) => dispatch(setUserData(res)))
      .catch((err) => console.error(err));
  };
};

/**
 * getCartCountAction - makes api call for getting cart counts
 * @returns an action to be dispatched later
 */
export const getCartCountAction = () => {
  return (dispatch: any) => {
    getCartCount()
      .then((res) => dispatch(setCartCount(res)))
      .catch((err) => console.error(err));
  };
};

/**
 * setCartCount - returns an action
 * @param {any} payload - payload
 * @returns {Action} action
 */
export const setCartCount = (payload: any) => {
  return {
    type: SET_CART_COUNT,
    payload: payload
  };
};

/**
 * getWishListCountAction -  makes an api call to fetch wishlist count
 * @returns an action to be dispatched later
 */
export const getWishListCountAction = () => {
  return (dispatch: any) => {
    getWishListCount()
      .then((res) => dispatch(setWishListCount(res)))
      .catch((err) => console.error(err));
  };
};

/**
 * setWishListCount
 * @param {any} payload
 * @returns {Action} - to set wishlist data in store
 */
export const setWishListCount = (payload: any) => {
  return {
    type: SET_WISHLIST_COUNT,
    payload: payload
  };
};

/**
 * resetCounts - action creator to reset count
 * @returns {Action} - to reset counts in redux store
 */
export const resetCounts = () => {
  return {
    type: RESET_COUNTS
  };
};

/**
 * fetchConfig-  function that first fetches data from an api and returns an action to be dispatched later
 * @param {company} companyNo - company number 
 * @returns {Action} - action to be dispatched
 */
export const fetchConfig = (companyNo:number) => {
  return (dispatch : any) => {
    getConfig(companyNo).then(
      (res:any) => {
        if(res?.success){
          dispatch(setConfig(res?.configuration))
        }
        else{
          dispatch(revertConfig())
        }
      }
    )   
  } 
}

/**
 * fetchTestimonials - function which first fetches data from api and then returns an action to be dispatched
 * @param {number} companyNo - company no 
 * @param {number} perPage - records to be retrieved
 * @param {string} orderBy - paramater or sorting
 * @param {string} orderDirection - direction of sorting 
 * @param {number} size - size of records
 * @returns Action to be dispatched later
 */
export const fetchTestimonials = (companyNo:number,perPage?:number,orderBy?:string,orderDirection?:string, size?:number) => {
  return (dispatch:any) => {
    getTestimonials(companyNo,perPage,orderBy,orderDirection,size?size : 10).then(
      (res:any)=> {
          if(res?.success){
            dispatch(setTestimonials(res.data.testimonials))
          }
      })
  }
}
