import { CartItem, Offer, OfferValidationResponse } from '../../types/type';
import {
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { getCouponCodes, validateCouponCode } from '../../api/CartCheckoutApi';

import { RootState } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

interface CartData {
  cartData: CartItem[];
  setCurrentAmount: (value: number) => void;
  deliveryCharges:number;
  getCouponDiscountAmount: (data: number) => void;
  getCouponPromoCode: (data: string) => void;
  getComment:(data:any) => void;
}

const CartDetails: FC<CartData> = ({
  cartData,
  setCurrentAmount,
  deliveryCharges,
  getCouponDiscountAmount,
  getCouponPromoCode,
  getComment
}: CartData) => {
  // Apply coupon modal
  const [isApplyCouponModal, setIsApplyCouponModal] = useState(false);
  const loginCart = useSelector((state: RootState) => state.loginCart);

  // applyCouponModalHandleClose : This function use open coupon modal.
  const applyCouponModalHandleOpen = () => {
    setIsApplyCouponModal(true);
    setCurrentAppliedCode({} as OfferValidationResponse);
    getAllCouponList();
  };

  // applyCouponModalHandleClose : This function use close coupon modal.
  const applyCouponModalHandleClose = () => {
    setCurrentAmount(0);
    setIsApplyCouponModal(false);
  };

  const [offerCodes, setOfferCodeList] = useState([] as Offer[]);
  const [offerCheck, setOfferCheckValue] = useState('');
  const [selectedRadioButtonValue, setSelectedRadioButtonValue] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [currentAppliedCode, setCurrentAppliedCode] = useState(
    {} as OfferValidationResponse
  );
  const [userNote , setUsernote] = useState('');

  const getSubTotalPrice = () => {
    let total = 0;
    loginCart.forEach((p: CartItem) => {
      total = total + p.totalPrice;
    });
    return total;
  };

  // getSubTotalPrice : This function use to get sub total without discount.
  const getSubTotalPriceWithoutDiscount = () => {
    let total = 0;
    loginCart.forEach((p: CartItem) => {
      total = total + p.totalPrice;
    });
    return total;
  };

  // getAllCouponList : This function use to get all coupon list.
  const getAllCouponList = () => {
    getCouponCodes()
      .then((response) => {
        setOfferCodeList(response.currentOffersResponses);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // onOfferCheckClick : This function use to call validateOffer after click on onOfferCheckClick.
  const onOfferCheckClick = () => {
    validateOffer(offerCheck);
  };

  // onCouponRadioHandleChange : This function use to get radio button changes.
  const onCouponRadioHandleChange = (event: any) => {
    setSelectedRadioButtonValue(event.target.value);
  };

  // onOfferCheckClick : This function use to call validateOffer after click on onApplyOfferClick.
  const onApplyOfferClick = () => {
    validateOffer(selectedRadioButtonValue);
  };

  // validateOffer : This function use to check offer is valid or not from API call.
  const validateOffer = (offer: string) => {
    validateCouponCode(offer, getSubTotalPrice())
      .then((response: OfferValidationResponse) => {
        if (
          response &&
          response !== undefined &&
          response.promoCodeStatus &&
          response.promoCodeStatus === 'Valid'
        ) {
          setCurrentAppliedCode(response);
          getCouponDiscountAmount(response.discountAmount);
          setIsCouponApplied(true);
          getCouponPromoCode(response.promoCode);
          applyCouponModalHandleClose();
          toast.success('Coupon Applied');
          setCurrentAmount(response.afterDiscountAmount);
        } else {
          toast.error('Invalid Coupon');
        }
      })
      .catch((error) => {
        toast.error('Invalid Coupon');
        console.log('error', error);
      });
  };

  // onCouponRemoved : This function use to remove coupon.
  const onCouponRemoved = () => {
    getCouponDiscountAmount(0);
    setCurrentAppliedCode({} as OfferValidationResponse);
    setIsCouponApplied(false);
    setCurrentAmount(getSubTotalPriceWithoutDiscount());
  };

  //handleChangeComment : This function use to get User Note -Comment
  const handleComment = (e:any) =>{
    setUsernote(e.target.value);
    getComment(e.target.value);
 }

  return (
    <section className="">
      <section className="bg-white px-6 py-6">
        <section className="flex justify-between items-center mb-6">
          <section className="text-2xl text-text-body font-bold">
            Shopping Cart
          </section>
          <section className="text-sm bg-primary h-6 w-6 rounded-full text-white flex items-center justify-center">
            {loginCart?.length}
          </section>
        </section>
        <hr className="mb-6"/>
        <section className="h-52 overflow-y-scroll">
          {loginCart?.map((data: any) => (
            <main className="flex max-w-sm  mx-auto py-4" key={data.id}>
              {/* img */}
              <section className="h-1/2 bg-black mr-5 flex-none w-16">
                <img
                  src={data?.productEntity?.compressProductImageUrl}
                  alt="product"
                />
              </section>
              {/* details */}
              <section className="flex-grow">
                <p className="text-sm text-text-body">
                  {data?.productEntity?.productName}
                </p>
                <section>{data?.quantity} Qty</section>
                <section className="text-gray-600 font-semibold pt-1">
                  Rs. {data?.totalPrice}
                </section>
              </section>
            </main>
          ))}
        </section>
        <section>
          <section className='flex justify-between mt-4'>
            <span className="text-lg text-text-body text-gray-40 font-semibold">
                  Write Any Notes?
            </span>
            <span className="text-sm text-text-body text-gray-40">
                  255 Character Limit
            </span>
          </section>
              <section className="mt-2">
                <textarea 
                  name="note" 
                  rows={4} 
                  maxLength={255} 
                  style={{ maxHeight:'100px'}}
                  className="mt-1 px-3 py-2 block w-full sm:text-sm focus:outline-none border border-gray-200" 
                  placeholder="Any notes..."
                  value={userNote}
                  onChange={handleComment}></textarea>
              </section>
        </section>
        <hr className="mb-3" />
        {/**Coupon section */}
        {isCouponApplied ? (
          <section className="flex justify-between">
            <span className="flex justify-center text-center items-center text-lg text-text-body text-gray-40 font-semibold">
              {currentAppliedCode?.promoCode}
            </span>
            <span className="flex justify-center text-center items-center text-sm text-text-body text-gray-40">
              Coupon code applied{' '}
              <button onClick={onCouponRemoved}>&nbsp;X</button>
            </span>
          </section>
        ) : (
          <section className="flex justify-between">
            <span className="flex justify-center text-center items-center text-lg text-text-body text-gray-40 font-semibold">
              Apply Coupons
            </span>
            <button
              className="bg-gray-500 text-white px-6 py-2 ml-3"
              onClick={applyCouponModalHandleOpen}>
              Apply
            </button>
          </section>
        )}
        <hr className="mt-3" />
        <section className="py-4">
          <section className="flex justify-between">
            <section className="text-sm text-text-body text-gray-40">
              Subtotal
            </section>
            <section className="text-sm text-text-body text-gray-40">
              Rs. {getSubTotalPrice()}
            </section>
          </section>
          <section className='mt-2'>
          <section className="flex justify-between">
            <section className="text-sm text-text-body text-gray-40">
              Delivery Charges
            </section>
            <section className="text-sm text-text-body text-gray-40">
              Rs. {deliveryCharges}
            </section>
          </section>
          </section>

          {isCouponApplied ? (
            <section className="flex justify-between">
              <section className="text-sm text-text-body text-gray-40">
                Discount
              </section>
              <section className="text-sm text-text-body text-gray-40">
                <span>
                  {currentAppliedCode.discountType === 'Flat' ? 'Rs.' : ''}
                </span>
                {currentAppliedCode.discount
                  ? `- Rs. ${currentAppliedCode.discountAmount}`
                  : null}
                {/* Can be used later */}
                {/* <span> */}
                {/* {currentAppliedCode.discountType === 'Percent' ? '%' : ''} */}
                {/* </span> */}
              </section>
            </section>
          ) : null}
        </section>
        <hr className="mt-2" />
        <section className="flex justify-between pt-2">
          <section className="text-lg text-text-body font-bold">Total</section>
          <section className="text-lg text-text-body font-bold">
            Rs.{' '}
            {getSubTotalPrice() - currentAppliedCode.discountAmount + deliveryCharges ||
              getSubTotalPrice() + deliveryCharges}
          </section>
        </section>

        {/* Apply Coupon */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isApplyCouponModal}
          onClose={applyCouponModalHandleClose}>
          <section className="text-center absolute top-1/2 left-1/2 bg-white px-20 py-8 -translate-x-2/4 -translate-y-2/4">
            <section className="text-base font-semibold pb-10">
              Apply Coupon
            </section>
            <section className="flex justify-center">
              <input
                className="w-full border border-r-1 p-2 border-gray-300 h-12"
                type="text"
                placeholder="Enter Coupon Code"
                name="code"
                value={offerCheck}
                onChange={(e) => {
                  const currValue = e.target.value;
                  setOfferCheckValue(currValue);
                }}
              />
              <button
                className="bg-gray-500 text-white px-6 py-2 capitalize"
                onClick={onOfferCheckClick}>
                check
              </button>
            </section>
            {offerCodes && offerCodes.length > 0 ? (
              <section className="flex justify-items-start py-3 max-h-56 overflow-y-scroll">
                <FormControl component="fieldset">
                  <RadioGroup
                    onChange={onCouponRadioHandleChange}
                    aria-label="offers"
                    name="radio-buttons-group">
                    {offerCodes.map((code, index) => (
                      <FormControlLabel
                        className="text-sm text-text-body text-gray-40"
                        value={code.code}
                        key={index}
                        control={<Radio color="default" />}
                        label={
                          <section className="text-left align-bottom mb-3">
                            <div className="text-lg text-text-body text-gray-40 font-semibold">
                              {code.code}
                            </div>
                            <div className="text-sm text-text-body text-gray-40">
                              {code.description}
                            </div>
                            <div className="text-xs text-text-body text-gray-40">{`Use code ${code.code} & get ${code.discount} ${code.discountType} on orders above 
                        ${code.minimumPurchaseAmount} Offer valid till ${code.offerEndDate}`}</div>
                          </section>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </section>
            ) : (
              <section className="flex justify-center italic pt-5">
                No Coupons Available
              </section>
            )}

            <button
              onClick={onApplyOfferClick}
              className="bg-gray-500 text-white px-6 py-2 mt-6">
              Apply Coupon
            </button>
          </section>
        </Modal>
      </section>
    </section>
  );
};

export default CartDetails;
