import COD from './COD.svg';
import Razorpay from './Razorpay.svg';
import ask from './ask.svg';
import closeIcon from './close.svg';
import delivery from './delivery.svg';
import downArrow from './downArrow.svg';
import forwardIcon from './forwardIcon.svg';
import gridView from './gridView.svg';
import leftArrow from './leftArrowActive.svg';
import leftArrowInactive from './leftArrowInactive.svg';
import leftPrimaryArrow from './leftPrimaryArrow.svg';
import paymentFail from './paymentFail.svg';
import placed from './placed.svg';
import returnIcon from './return.svg';
import rightArrow from './rightArrowActive.svg';
import rightArrowInactive from './rightArrowInactive.svg';
import rightMenu from './rightMenu.svg';
import searchIcon from './search.svg';
import shareIcon from './shareIcon.svg';
import upArrow from './upArrow.svg';
import userIcon from './userIcon.svg';
import wishlist from './wishList.svg';

// import cart from './cart.svg';
// import sortIcon from './sortIcon.svg';
// import backIcon from './backIcon.svg';
// import backIconActive from './backIconActive.svg';
// import checkIcon from './checkIcon.svg';
// import emailIcon from './email.svg';
// import filterIcon from './filter.svg';
// import leftMenu from './leftMenu.svg';
// import listView from './listView.svg';
// import loader from './loader.svg';
// import location from './location.svg';
// import nextIcon from './nextIcon.svg';
// import nextInactive from './nextInactive.svg';
// import phoneIcon from './phone.svg';
// import quickLook from './quickLook.svg';


const icons: { [key: string]: string } = {
  // cart: cart,
  // leftMenu: leftMenu,
  // back: backIcon,
  // backActive: backIconActive,
  // next: nextIcon,
  // nextInactive: nextInactive,
  // listView: listView,
  // check: checkIcon,
  // quickLook: quickLook,
  // phone: phoneIcon,
  // location: location,
  // email: emailIcon,
  // filter: filterIcon,
  // loader: loader
  // sort: sortIcon,
  rightMenu: rightMenu,
  wishlist: wishlist,
  user: userIcon,
  leftArrow: leftArrow,
  leftArrowInactive: leftArrowInactive,
  rightArrow: rightArrow,
  rightArrowInactive: rightArrowInactive,
  share: shareIcon,
  forward: forwardIcon,
  gridView: gridView,
  downArrow: downArrow,
  upArrow: upArrow,
  close: closeIcon,
  search: searchIcon,
  return: returnIcon,
  ask: ask,
  delivery: delivery,
  razorpay: Razorpay,
  cod: COD,
  leftPrimaryArrow: leftPrimaryArrow,
  placed: placed,
  paymentFail: paymentFail,
};

export default icons;
