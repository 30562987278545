import { Order, PlaceOrderStatusReq, PostAddress } from '../types/type';
import { getDate, getTime } from './CartApi';

import { config } from '../config/config';
import axios from 'axios';
import { company_no } from './api';

/** getAllShippingAddress - This function use to get all shipping address. */
export const getAllShippingAddress = async () => {
  const result = await axios.get(`${config.BASE_URL}/shippingaddresses`,
  {
    headers: {
            Accept: 'application/json,text/plain',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
  })
  .then((res)=>{
    return res.data;
  })
  .catch((err)=>{
    throw err;
  })
  return result;
};

/** addShippingAddress - This function use add shipping address. */
export const addShippingAddress = async (requestBody: PostAddress) => {
  const result = await axios.post(`${config.BASE_URL}/shippingaddresses`,
    requestBody,
    {
      headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** addShippingAddress - This function use update shipping address. */
export const updateShippingAddress = async (requestBody: PostAddress) => {
  const result = await axios.put(`${config.BASE_URL}/shippingaddresses`,
    requestBody,
    {
      headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** getAllShippingAddress - This function use to get all shipping address. */
export const getPaymentOptions = async () => {
  const result = await axios.get(`${config.BASE_URL}/payment-options`,
    {
      headers: {
          Accept: 'application/json,text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** placeOrder - This function use place order. */
export const placeOrder = async (requestBody: Order) => {
  const result = await axios.post( `${config.BASE_URL}/order/v1?currentDate=${getDate()}&currentTime=${getTime()}`,
    requestBody,
     {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      .then((res)=>{
        return res.data;
      })
      .catch((err)=>{
        throw err;
      })
      return result;
};

/** orderStatus - This function use check order status. */
export const orderStatus = async (requestBody: PlaceOrderStatusReq) => {
  const result = await axios.post(`${config.BASE_URL}/order/v1/status`,
    requestBody,
    {
      headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** createOrder - This function use create an order. */
export const createOrder = async (requestBody: any) => {
  const result = await axios.post(`${config.BASE_URL}/payments/createanorder`,
    requestBody,
    {
      headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
    })
    .then((res) =>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** verifyPayment - This function use verify order. */
export const verifyPayment = async (requestBody: any) => {
  const result = await axios.post( `${config.BASE_URL}/payments/verify/payment`,
    requestBody,
    {
      headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
              }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err) =>{
      throw err;
    })
    return result;
};

/** getCouponCodes - This function use to get all coupon list. */
export const getCouponCodes = async () => {
  const result = await axios.get(`${config.BASE_URL}/offer/v2/user/current/offers?currentDate=${getDate()}`,
    {
      headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
              }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/** validateCouponCode - This function use to validate coupon entered by user. */
export const validateCouponCode = async (
  coupon: string,
  totalPrice: number
) => {
  const result = await axios.get(`${config.BASE_URL}/offer/v2/user/promocode/validate?promoCode=${coupon}&totalPrice=${totalPrice}&currentTime=${getTime()}&currentDate=${getDate()}`,
   {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
   })
  .then((res)=>{
    return res.data;
  })
  .catch((err)=>{
    throw err;
  })
  return result;
};

/**
 * getCityAndStateFromPin - api call to get delivery charges dependes on location distance.
 * @param {number} Latitude - Latitude of location.
 * @param {number} Longitude - Longitude of location.
 * @param {number} Company_No - company Number.
 * @param {string} distanceUnit - Location distance unit.
 * @param {number} totalAmount - Total Amt of cart.
 * @returns json response
 */
export const getDeliveryChargesOnDistRadius = async(
  latitude:number,
  longitude:number,
  Company_No:number,
  distanceUnit:string,
  totalAmount:number
)=>{
   const result = await axios.get(`${config.BASE_URL}/stores/radius?userLatitude=${latitude}&userLongitude=${longitude}&companyNo=${Company_No}&distanceUnit=${distanceUnit}&totalAmountFromCart=${totalAmount}`,
   {
      headers: {
        'Content-Type': 'application/json',
       }
   })
  .then((res)=>{
    return res.data;
  })
  .catch((err)=>{
    throw err;
  })
  return result;
}