import { config } from '../config/config';

export const api = config.BASE_URL;
export const company_no = config.COMPANY_NO;
export const ai = config.AI_BASE_URL;

export const getRequestBody = {
  method: 'GET',
  headers: {
    Accept: 'application/json,text/plain',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
};

export const postRequestBody = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
};

export const putRequestBody = {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
};
