import axios from 'axios';
import { ai, api } from '../api';

/**
 * getProducyById - function that makes an api to call to get product details of product with given product id
 * @param {string} productID - product id of given product
 * @param {string} companyID - company number
 * @returns json data of product
 */
export const getProductById = async (productID: string, companyID: number) => {
  const result = await axios.get(api + `/product/v1/${productID}?companyNo=${companyID}`,
        {
          headers: {
            Accept: 'application/json,text/plain',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }}
    )
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/**
 *getSimilarProductsById - function that fetches similar products to a given product
 * @param {string} productId - product id of given product
 * @param {number} companyId - company no
 * @returns array of similar products
 */
export const getSimilarProductsById = async (
  productId: number | string,
  companyId: number
) => {
  const result = await axios.get(ai +`/products/similar_products?product_id=${productId}&company_no=${companyId}`)
      .then((res)=>{
        return res.data;
      })
      .catch((err)=>{
        throw err;
      })
      return result;
};
