import React, { useEffect, useState } from 'react';

import ProductCardIcon from './ProductCardIcon';
import { ProductEntity } from '../../types/type';
import defaultImage from '../../assets/images/default-image.png';
import { useNavigate } from 'react-router-dom';

interface Props {
  product: ProductEntity;
}

const ProductGridCard = ({ product }: Props) => {
  const [displayImage, setdisplayImage] = useState('');
  useEffect(() => {
    setdisplayImage(product.productImageUrl);
  }, []);
  let navigate = useNavigate();
  return (
    <section className="group flex flex-col mt-2 relative shadow-md lg:hover:shadow-gray-600/40
    border-solid border rounded-md border-slate-300 hover:-translate-y-3 transition-all duration-300 hover:shadow-lg p-5">
      {product?.discount > 0 && (
        <div className="ribbon up">
          <div className="content text-xs">
            {Math.floor(product?.discount)}% off
          </div>
        </div>
      )}
      <article className="bg-white grid place-items-center h-[180px] md:h-60 lg:h-64 xl:h-72 relative overflow-hidden">
        <img
          onClick={(e) => {
            window.scrollTo(0, 0);
            navigate(`/products/${product?.id}`);
          }}
          onMouseEnter={() => {
            if (product.subImageUrl[0]) {
              setdisplayImage(product.subImageUrl[0]);
            }
          }}
          onMouseLeave={() => {
            if (displayImage !== product.productImageUrl) {
              setdisplayImage(product.productImageUrl);
            }
          }}
          src={displayImage}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          className="cursor-pointer w-full max-h-64"
          alt={product?.productName}
        />
        <ProductCardIcon product={product} />
      </article>
      <article className="mt-4">
        <p
          onClick={(e) => {
            navigate(`/products/${product?.id}`);
          }}
          className="text-text-muted tracking-tight cursor-pointer capitalize line-clamp-2">
          {product?.productName.toLowerCase()}
        </p>
        <article
          className="space-x-2 cursor-pointer"
          onClick={(e) => {
            navigate(`/products/${product?.id}`);
          }}>
          <span className="text-text-title font-poppins-semibold">
            &#8377;{product?.afterDiscountPrice}
          </span>
          {product.discount > 0 ? (
            <del className="text-text-muted">
              &#8377;{product?.productPrice}
            </del>
          ) : null}
        </article>
      </article>
    </section>
  );
};

export default ProductGridCard;
