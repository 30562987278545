const CustomDot = ({ index, onClick, active }: any) => {
  return (
    <button
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      className={`${
        active
          ? 'bg-text-body border-text-body'
          : 'bg-text-body/50 border-text-body/50'
      } w-2 h-2 border mx-1 rounded-full`}></button>
  );
};

export default CustomDot;
