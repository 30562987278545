import {
  SET_QUICKVIEW_PRODUCT,
  UNSET_QUICKVIEW_PRODUCT
} from '../actions/actionTypes';

import { Action } from '../../types/type';

const inititalState = {} as any;

const quickViewProduct = (
  state = inititalState,
  { type, payload }: Action
) => {
  if (typeof type !== undefined) {
    switch (type) {
      case SET_QUICKVIEW_PRODUCT:
        return { ...payload };
      case UNSET_QUICKVIEW_PRODUCT:
        return {};
      default:
        return state;
    }
  }
};

export default quickViewProduct;
