import {
  RESET_DRAWERS,
  SET_CART_OPEN_STATUS,
  SET_CATEGORY_OPEN_STATUS,
  SET_RIGHT_DRAWER_OPEN,
  SET_WISHLIST_OPEN_STATUS
} from '../actionTypes';

/**
 * setCartDrawer - changes state regarding to cart
 * @param {Boolean} payload - status of drawer
 * @returns {Action} action to be dispatched
 */
export const setCartDrawer = (payload: Boolean) => {
  return {
    payload: payload,
    type: SET_CART_OPEN_STATUS
  };
};

/**
 * setWishList - changes state regarding to wishlist
 * @param {Boolean} payload - status of drawer
 * @returns {Action} - action to be dispatched
 */
export const setWishList = (payload: Boolean) => {
  return {
    payload: payload,
    type: SET_WISHLIST_OPEN_STATUS
  };
};

/**
 * setCategoryMenu - changes state regarding categorymenu
 * @param {Action} payload - status of drawer
 * @returns {Action} - action to be dispatched
 */
export const setCategoryMenu = (payload: Boolean) => {
  return {
    payload: payload,
    type: SET_CATEGORY_OPEN_STATUS
  };
};

/**
 * setRightDrawer - changes state regarding rightDrawer
 * @param {Boolean} payload - status
 * @returns {Action} - Action to be dispatched
 */
export const setRightDrawer = (payload: Boolean) => {
  return {
    type: SET_RIGHT_DRAWER_OPEN,
    payload: payload
  };
};

export const resetDrawers = () => {
  return {
    type: RESET_DRAWERS
  };
};
