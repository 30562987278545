import React, { useEffect, useState } from 'react';
import { AddToCartPayload, ProductEntity } from '../../types/type';
import { addToCart, addToWishlist } from '../../api/CartApi';
import {
  addToCartWithoutLogin,
  createAddToCartPayload
} from '../../redux/actions/cart';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import defaultImage from '../../assets/images/default-image.png';
import { getCartCountAction } from '../../redux/actions/actionCreators';
import { getLoginCart } from '../../redux/actions/loginCart';
import { openLoginModal } from '../../redux/actions/modals';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Props {
  product: ProductEntity;
}

const ProductListCard = ({ product }: Props) => {
  /**
   * @description: Hooks and states for card
   */
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userCart = useSelector((state: RootState) => state.cart);
  const [displayImage, setdisplayImage] = useState('');
  useEffect(() => {
    setdisplayImage(product.productImageUrl);
  }, []);

  /**
   * @description: Add to wishlist api call. It will add the product to wishlist only if the user is logged in.
   */
  const wishList = async () => {
    if (isLoggedIn) {
      if (product) {
        const data = await addToWishlist(product.id);
        if (data) {
          toast.success('Item added to wishlist');
        }
      }
    } else {
      toast.error('Please login to add to wishlist');
      dispatch(openLoginModal());
    }
  };

  /**
   * @description: Add to cart api call. It will add the product to cart only if the user is logged in.
   */
  const cart = async () => {
    if (isLoggedIn) {
      if (product) {
        const data = await addToCart(product.id);
        if (data) {
          toast.success('Item added to cart');
          dispatch(getCartCountAction());
          dispatch(getLoginCart());
        }
      }
    } else {
      if (userCart?.length) {
        if (
          !userCart.some(
            (payload: AddToCartPayload) => payload.product === product.id
          )
        ) {
          dispatch(
            addToCartWithoutLogin(
              createAddToCartPayload(
                product.id,
                1,
                [],
                product.productName,
                product.compressProductImageUrl,
                product.afterDiscountPrice
              )
            )
          );
          toast.success('Item added to cart');
        } else {
          toast.success('Item already exists in your cart');
        }
      } else {
        dispatch(
          addToCartWithoutLogin(
            createAddToCartPayload(
              product.id,
              1,
              [],
              product.productName,
              product.compressProductImageUrl,
              product.afterDiscountPrice
            )
          )
        );
        toast.success('Item added to cart');
      }
    }
  };

  return (
    <section className="grid auto-rows-auto grid-cols-1 md:grid-cols-3 lg:grid-cols-4 md:border border-0 md:mt-16 md:bg-white">
      <article
        onClick={(e) => {
          navigate(`/products/${product?.id}`);
        }}
        className="cursor-pointer border md:border-0 bg-white md:border-r text-center overflow-hidden max-h-48 md:max-h-80">
        <img
          onMouseEnter={() => {
            if (product.subImageUrl[0]) {
              setdisplayImage(product.subImageUrl[0]);
            }
          }}
          onMouseLeave={() => {
            if (displayImage !== product.productImageUrl) {
              setdisplayImage(product.productImageUrl);
            }
          }}
          src={displayImage}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          alt={product.productName}
          className="hover:scale-105 transition-all duration-500 object-cover inline w-auto h-full"
        />
      </article>
      <article className="md:ml-10 mt-4 md:mt-16 md:pr-5 col-span-2 lg:col-span-3">
        <p
          onClick={(e) => {
            navigate(`/products/${product?.id}`);
          }}
          className="cursor-pointer text-text-muted text-xs font-bold text-opacity-60">
          {product.productName}
        </p>
        <article className="space-x-2 md:space-x-0 flex font-bold">
          <p>&#8377;{product.afterDiscountPrice}</p>
          <del className="text-text-muted md:hidden">
            &#8377;{product.productPrice}
          </del>
        </article>
        <p
          className="text-text-base text-opacity-60 font-normal text-sm"
          dangerouslySetInnerHTML={{
            __html:
              product.description.length > 500
                ? product.description.substring(0, 500) + '...'
                : product.description
          }}></p>
        <span className="flex space-x-4 my-7">
          <button
            className="bg-primary text-white px-6 py-2 hover:bg-text-title transition-all duration-150"
            onClick={() => cart()}>
            Add to cart
          </button>
          <article
            className="w-12 h-12 object-cover overflow-hidden border bg-white grid place-items-center my-auto hover:bg-primary cursor-pointer transition-colors duration-150"
            onMouseEnter={() => {
              document
                .getElementById(`path_${product.id}`)
                ?.classList.add('fill-white');
            }}
            onMouseLeave={() => {
              document
                .getElementById(`path_${product.id}`)
                ?.classList.remove('fill-white');
            }}>
            <svg
              onClick={() => wishList()}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="">
              <g
                id="Group_18943"
                data-name="Group 18943"
                transform="translate(12703 -10533)">
                <g
                  id="Group_18939"
                  data-name="Group 18939"
                  transform="translate(0 1)">
                  <rect
                    id="Rectangle_30030"
                    data-name="Rectangle 30030"
                    width="32"
                    height="32"
                    transform="translate(-12703 10532)"
                    fill="none"
                  />
                  <path
                    className=""
                    id={`path_${product.id}`}
                    data-name="Icon material-favorite-border"
                    d="M14.6,4.5A4.791,4.791,0,0,0,11,6.172,4.791,4.791,0,0,0,7.4,4.5,4.357,4.357,0,0,0,3,8.9c0,3.024,2.72,5.488,6.84,9.232L11,19.18l1.16-1.056C16.28,14.388,19,11.924,19,8.9A4.357,4.357,0,0,0,14.6,4.5ZM11.08,16.94l-.08.08-.08-.08C7.112,13.492,4.6,11.212,4.6,8.9A2.734,2.734,0,0,1,7.4,6.1a3.128,3.128,0,0,1,2.856,1.888h1.5A3.108,3.108,0,0,1,14.6,6.1a2.734,2.734,0,0,1,2.8,2.8C17.4,11.212,14.888,13.492,11.08,16.94Z"
                    transform="translate(-12697.5 10536)"
                  />
                </g>
              </g>
            </svg>
          </article>
        </span>
      </article>
    </section>
  );
};

export default ProductListCard;
