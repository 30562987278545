import { Category, SubCategory } from '../../types/type';
import { getAllBrands, getSubCategories } from '../../api/company';
import {
  resetFilters,
  setSelectedBrand,
  setSelectedCategory,
  setSelectedSubCategory
} from '../../redux/actions/productListing';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import CONFIG from '../../config.json';
import { RootState } from '../../redux/reducers';
import { company_no } from '../../api/api';
import { getMaxPrice } from '../../api/ProductListing';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  isFiltersOpen: boolean;
  toggleFilters: () => void;
  minPrice: number;
  setMinPrice: (minPrice: number) => void;
  maxPrice: number;
  setMaxPrice: (maxPrice: number) => void;
}

const Filter = ({
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  isFiltersOpen,
  toggleFilters
}: Props) => {
  let filterRef = useRef(null);
  const categories = useSelector((state: RootState) => state.categories);
  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: 0
  });
  const [brands, setBrands] = useState<any>([]);
  const [minInputValue, setMinInputValue] = useState(0);
  const [maxInputValue, setMaxInputValue] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const productListingConfig = useSelector(
    (state: RootState) => state.productListingConfig
  );
  const config = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();

  const getData = async () => {
    const brands = await getAllBrands(company_no);
    setBrands([...brands['brand']]);
    const maxPrice = await getMaxPrice();
    setPriceRange({
      ...priceRange,
      max: maxPrice['maxPrice']
    });
    setMaxInputValue(maxPrice['maxPrice']);
  };
  useEffect(() => {
    getData();
  }, []);

  const setAllSubCategories = async () => {
    const subcategories = await getSubCategories(
      config?.parameters.company_no,
      productListingConfig?.selectedCategory
    );
    setSubCategories(subcategories['subCategory']);
  };
  useEffect(() => {
    setAllSubCategories();
  }, [productListingConfig?.selectedCategory]);

  const reset = () => {
    dispatch(resetFilters());
  };
  const handleClickOutside = () => {
    // Your custom logic here
    isFiltersOpen && toggleFilters();
  };

  useOnClickOutside(filterRef, handleClickOutside);

  return (
    <article className="flex-1" ref={filterRef}>
      <div
        className="uppercase space-x-6 text-text-base opacity-60 text-sm flex cursor-pointer h-full"
        onClick={() => toggleFilters()}>
        <svg
          className="my-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="23.448"
          height="23.448"
          viewBox="0 0 23.448 23.448">
          <g
            id="adjustments_1"
            data-name="adjustments 1"
            transform="translate(0.5 0.5)"
            opacity="0.6">
            <g id="Group" transform="translate(0 0)">
              <path
                id="Vector"
                d="M18.667,14.03V0"
                transform="translate(0.975)"
                fill="none"
                stroke="#121212"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Vector-2"
                data-name="Vector"
                d="M11.224,2.806V0m0,2.806a2.806,2.806,0,0,0,0,5.612m0-5.612a2.806,2.806,0,0,1,0,5.612m0,0v14.03M2.806,19.642a2.806,2.806,0,0,0,0-5.612m0,5.612a2.806,2.806,0,0,1,0-5.612m0,5.612v2.806m0-8.418V0M19.642,19.642a2.806,2.806,0,1,0-1.984-.822A2.806,2.806,0,0,0,19.642,19.642Zm0,0v2.806"
                fill="none"
                stroke="#121212"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
        <span className="my-auto hidden md:inline-block text-text-muted">
          filters
        </span>
      </div>
      <section
        className={`p-5 w-52 z-20 absolute transition-all ease-in-out duration-500 flex flex-col divide-y ml-2 bg-white border shadow-lg ${
          isFiltersOpen
            ? '-translate-x-4 opacity-100'
            : '-translate-x-full opacity-0 pointer-events-none'
        }
`}>
        <article className="text-left flex-grow flex justify-between mb-2">
          <p
            onClick={() => reset()}
            className="px-2 text-text-muted flex items-center hover:text-text-title text-xs font-medium cursor-pointer">
            Reset All Filters
          </p>
          <svg
            onClick={() => {
              toggleFilters();
            }}
            className="cursor-pointer hover:fill-text-title fill-text-muted"
            id="icon_navigation_close_24px"
            data-name="icon/navigation/close_24px"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <rect id="Boundary" width="24" height="24" fill="none" />
            <path
              id="_Color"
              data-name=" ↳Color"
              d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
              transform="translate(5 5)"
            />
          </svg>
        </article>
        {config?.isConfigLoaded && config.parameters.isCategoryFilterEnabled && (
          <article className="text-left flex-grow">
            <p className="px-2 text-text-title uppercase text-sm font-medium mb-2 mt-2">
              Categories
            </p>
            <ul className="text-sm text-text-body">
              <li
                className={`px-2 cursor-pointer py-1 text-xs ${
                  productListingConfig &&
                  productListingConfig.selectedCategory === 'All'
                    ? 'bg-gray-200'
                    : ''
                }`}
                onClick={() => {
                  dispatch(setSelectedCategory('All'));
                  dispatch(setSelectedSubCategory('All'));
                }}>
                All
              </li>
              {categories
                ?.filter((category) => {
                  return (
                    category.categoryName !== null &&
                    category.categoryName !== undefined &&
                    category.categoryName !== ''
                  );
                })
                .map((singleCategory, index) => (
                  <li
                    key={index}
                    className={`px-2 cursor-pointer py-1 text-xs capitalize my-1 ${
                      productListingConfig &&
                      productListingConfig.selectedCategory ===
                        singleCategory.categoryName
                        ? 'bg-gray-200'
                        : ''
                    }`}
                    onClick={() => {
                      dispatch(
                        setSelectedCategory(singleCategory.categoryName)
                      );
                      dispatch(setSelectedSubCategory('All'));
                    }}>
                    {singleCategory.categoryName.toLowerCase()}
                  </li>
                ))}
            </ul>
          </article>
        )}
        {config?.isConfigLoaded &&
        config.parameters.isSubCategoryFilterEnabled ? (
          <article className="text-left flex-grow">
            <p className="px-2 text-text-title uppercase text-sm font-medium mb-2">
              SubCategories
            </p>
            <ul className="text-sm text-text-body">
              <li
                className={`px-2 cursor-pointer py-1 text-xs ${
                  productListingConfig &&
                  productListingConfig.selectedSubCategory === 'All'
                    ? 'bg-gray-200'
                    : ''
                }`}
                onClick={() => {
                  dispatch(setSelectedSubCategory('All'));
                }}>
                All
              </li>
              {subCategories.map(
                (singleCategory: SubCategory, index: number) => (
                  <li
                    key={index}
                    className={`px-2 cursor-pointer py-1 text-xs  capitalize my-1 ${
                      productListingConfig &&
                      productListingConfig.selectedSubCategory ===
                        singleCategory.subCategoryName
                        ? 'bg-gray-200'
                        : ''
                    }`}
                    onClick={() => {
                      dispatch(
                        setSelectedSubCategory(singleCategory.subCategoryName)
                      );
                    }}>
                    {singleCategory.subCategoryName.toLowerCase()}
                  </li>
                )
              )}
            </ul>
          </article>
        ) : null}
        {config?.isConfigLoaded && config.parameters.isPriceFilterEnabled ? (
          <article className="text-left flex-grow">
            <p className="px-2 text-text-title uppercase text-sm font-medium mb-2">
              Price
            </p>
            <p>
              <span>From: </span>&#8377;{minInputValue}
            </p>
            <input
              type="range"
              max={maxInputValue - 1}
              value={minInputValue}
              onChange={(e) => {
                setMinInputValue(parseInt(e.target.value));
              }}
            />
            <p>
              <span>To: </span>&#8377;{maxInputValue}
            </p>
            <input
              type="range"
              max={priceRange.max}
              value={maxInputValue}
              onChange={(e) => {
                setMaxInputValue(parseInt(e.target.value));
              }}
            />
            <button
              onClick={() => {
                setMinPrice(minInputValue);
                setMaxPrice(maxInputValue);
              }}
              type="button"
              className="float-right px-2 py-2  text-xs bg-primary hover:bg-text-title text-white">
              Apply
            </button>
          </article>
        ) : null}
        {config?.isConfigLoaded && config.parameters.isBrandFilterEnabled ? (
          <article className="text-left flex-grow mt-5 pt-3">
            {brands && brands.length > 0 ? (
              <>
                <p className="px-2 text-text-title uppercase text-sm font-medium mb-2">
                  Brands
                </p>
                <ul className="text-sm text-text-body">
                  <li
                    className={`px-3 cursor-pointer py-1 rounded-md ${
                      productListingConfig &&
                      productListingConfig.selectedBrand === 'All'
                        ? 'bg-gray-200'
                        : ''
                    }`}
                    onClick={() => {
                      dispatch(setSelectedBrand('All'));
                    }}>
                    All
                  </li>
                  {/* Any used here as there is no interface defined for this right now */}
                  {brands.map((singleBrand: any, index: number) => (
                    <li
                      key={index}
                      className={`px-3 cursor-pointer py-1 rounded-md capitalize my-1 ${
                        productListingConfig &&
                        productListingConfig.selectedBrand ===
                          singleBrand.brandName
                          ? 'bg-gray-200'
                          : ''
                      }`}
                      onClick={() => {
                        dispatch(setSelectedBrand(singleBrand.brandName));
                      }}>
                      {singleBrand.brandName.toLowerCase()}
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </article>
        ) : null}
      </section>
    </article>
  );
};

export default Filter;
