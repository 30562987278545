import React, { useEffect, useState } from 'react';

import ProductCardIcon from '../ProductListing/ProductCardIcon';
import { ProductCategoryCardInterface } from '../../types/type';
import defaultImage from '../../assets/images/default-image.png';
import { useNavigate } from 'react-router-dom';

const ProductCategoryCard = ({
  product,
  animate
}: ProductCategoryCardInterface) => {
  let navigate = useNavigate();
  const [displayImage, setdisplayImage] = useState('');
  useEffect(() => {
    setdisplayImage(product.productImageUrl);
  }, []);
  return (
    <section
      className={`mx-1 lg:mx-2 group flex flex-col relative ${animate} shadow-md lg:hover:shadow-gray-600/40
      border-solid border rounded-md border-slate-300  px-5 py-2 lg:hover:-translate-y-3 transition-all duration-300`}>
      {product?.discount > 0 && (
        <div className="ribbon up">
          <div className="content text-xs">
            {Math.floor(product?.discount)}% off
          </div>
        </div>
      )}
      <article className="bg-white grid place-items-center relative object-center cursor-pointer w-full h-auto aspect-square ease-in-out">
        <img
          onClick={(e) => {
            window.scrollTo(0, 0);
            setTimeout(() => {
              navigate(`/products/${product?.id}`);
            }, 700);
          }}
          onMouseEnter={() => {
            if (product.subImageUrl[0]) {
              setdisplayImage(product.subImageUrl[0]);
            }
          }}
          onMouseLeave={() => {
            if (displayImage !== product.productImageUrl) {
              setdisplayImage(product.productImageUrl);
            }
          }}
          src={displayImage}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          className="cursor-pointer h-full w-auto aspect-square max-h-64"
          alt={product?.productName}
        />
        <ProductCardIcon product={product} />
      </article>
      <article className="mt-6 text-left">
        <p
          onClick={(e) => {
            navigate(`/products/${product?.id}`);
          }}
          className="truncate text-text-muted text-sm md:text-md cursor-pointer capitalize categoryCardTitle"
          >
          {product?.productName?.toLowerCase()}
        </p>
        <article
          className="space-x-2 cursor-pointer"
          onClick={(e) => {
            navigate(`/products/${product?.id}`);
          }}>
          <span className="text-text-title font-bold text-xl">
            &#8377;{product?.afterDiscountPrice}
          </span>

          {product.discount ? (
            <del className="text-text-muted">
              &#8377;{product?.productPrice}
            </del>
          ) : null}
        </article>
      </article>
    </section>
  );
};

export default ProductCategoryCard;
