import { Action } from "../../types/type";
import { REVERT_CONFIG, SET_LOADED_CONFIG } from "../actions/actionTypes";

const initialState = {
    isConfigLoaded:false,
    parameters : {

    }
}

export const configReducer = (state=initialState,action:Action) => {
    if(typeof action.type !== undefined){
        switch(action.type){
            case SET_LOADED_CONFIG: 
            return {
                ...state, isConfigLoaded:true, parameters: action.payload
            } 
            case REVERT_CONFIG:
                return {...initialState}
            default :
            return state
        }
    }
}