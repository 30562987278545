import { RESET_LOGIN_CART, SET_LOGIN_CART } from '../actions/actionTypes';

import { Action } from '../../types/type';

const initialState = [] as any;
/**
 * loginCartReducer - reducer for handling login cart action
 * @param {any} state - default state
 * @param {Action} action - dispatched action
 * @returns modified state
 */
export const loginCartReducer = (state = initialState, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_LOGIN_CART: {
        return [...action.payload];
      }
      case RESET_LOGIN_CART: {
        return [];
      }
      default:
        return state;
    }
  }
};
