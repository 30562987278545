import { Address, PostAddress, PostUser } from '../../types/type';
import { api, getRequestBody, postRequestBody, putRequestBody } from '../api';

import axios from 'axios';
import { threadId } from 'worker_threads';
import { config } from '../../config/config';

/**
 * getAllAddresses - function to fetch addresses of given user
 * @returns array of addresses
 */
export const getAllAddresses = async () => {
  const result =await axios.get(`${api}/shippingaddresses`,
    {
      headers:{
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
  )
  .then((res)=>{
    return res.data
  })
  .catch((err)=>{
    throw err;
  })
  return result;
};

/**
 * getUserInfo - function to fetch details of user
 * @param {number} userId - user id
 * @returns json object of user details
 */
export const getUserInfo = async (userId: number) => {
  const result = await axios.get(`${api}/users/${userId}`,
    {
      headers: {
         Accept: 'application/json,text/plain',
         'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>
      {throw err}
    )
    return result;
};

/**
 * editUserAddress - function to edit address of user
 * @param {Address} address - object containing address of user
 * @returns json response
 */
export const editUserAddress = async (address: Address) => {
  const result = await axios.put(`${api}/shippingaddresses`,
      address,
    {
      headers:{
          Accept: 'application/json,text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    }
    )
    .then((res)=>{
       return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/**
 * addUserAddress - function to add new address for given user
 * @param {PostAddress} address - object containing address data
 * @returns json response
 */
export const addUserAddress = async (address: PostAddress) => {
  const result = await axios.post(`${api}/shippingaddresses`,
      address,
    {
      headers: {
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
  return result;
};

/**
 * editUserDetails - Function to edit details of user
 * @param {PostUser} user - requestbody of user details
 * @returns json response
 */
export const editUserDetails = async (user: PostUser) => {
  const result = await axios.put(`${api}/users/${user.id}`,
      user,
    {
      headers: {
            Accept: 'application/json,text/plain',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err
    })
  return result;
};

/**
 * orderHistory - function to fetch order history of a user
 * @param {number} companyNo - company no
 * @param {number} orderBy - order by
 * @param {number} size - number of records to be retrieved
 * @param {number} page - index of page
 * @returns json response
 */
export const orderHistory = async (
  companyNo: number,
  orderBy: number,
  size?: number,
  page?: number
) => {
  const result = await axios.get(`${api}/order/ordershistory?companyNo=${companyNo}&orderBy=${orderBy}&size=${
      size || 10
    }&page=${page || 0}`,
    {
      headers: {
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json'
      }
    })
    .then((res)=>{
       return res.data;
    })
    .catch((err)=>{
       throw err;
    })
  return result;
};

/**
 * deleteAddress - async function to delete address
 * @param {number} addressId - id of the address
 * @returns json response
 */
export const deleteAddress = async (addressId: number) => {
  const result = await axios.delete( `${api}/shippingaddresses/?addressId=${addressId}`,
    {
      headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }  
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err
    })
    return result;
};

/**
 * getCityAndStateFromPin - api call to get city and state from pincode
 * @param {string} pincode - 6 digit pincode 
 * @returns json response
 */
export const getCityAndStateFromPin = async (pincode:string) => {
   const result = axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=${config.GOOGLE_MAPS_API_KEY}`,
    {
      headers: {
         Accept: 'application/json,text/plain',
         'Content-Type': 'application/json'
        }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>
      {throw err}
    )
    return result;
}
