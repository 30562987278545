import { Action, AddToCartPayload } from '../../types/type';
import {
  ADD_TO_CART_WITHOUT_LOGIN,
  REMOVE_FROM_CART_WITHOUT_LOGIN,
  RESET_CART,
  UPDATE_CART_WTIHOUT_LOGIN
} from '../actions/actionTypes';

export const cartReducer = (
  state: Array<AddToCartPayload> = [],
  action: Action
) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case ADD_TO_CART_WITHOUT_LOGIN: {
        return [...state, action.payload];
      }
      case REMOVE_FROM_CART_WITHOUT_LOGIN: {
        return [
          ...state.filter(
            (payload: AddToCartPayload) => payload.product !== action.payload
          )
        ];
      }
      case UPDATE_CART_WTIHOUT_LOGIN: {
        return [
          ...state.filter(
            (payload: AddToCartPayload) =>
              payload.product !== action.payload.product
          ),
          action.payload
        ];
      }
      case RESET_CART: {
        return [] as AddToCartPayload[];
      }
      default:
        return state;
    }
  }
};
