import {
  RESET_DRAWERS,
  SET_CART_OPEN_STATUS,
  SET_CATEGORY_OPEN_STATUS,
  SET_RIGHT_DRAWER_OPEN,
  SET_WISHLIST_OPEN_STATUS
} from '../actions/actionTypes';

import { Action } from '../../types/type';

const initialState = {
  isCategoryMenuOpen: false,
  isCartOpen: false,
  isWishListOpen: false,
  isRightDrawerOpen: false
};

/**
 * drawerReducer - function to toggle states od drawer
 * @param state - initial state
 * @param {Action} action - action which is dispatched
 * @returns modified state
 */
export const drawerReducer = (state = initialState, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_CATEGORY_OPEN_STATUS:
        return {
          ...state,
          isCategoryMenuOpen: action.payload,
          isCartOpen: false,
          isWishListOpen: false,
          isRightDrawerOpen: false
        };
      case SET_CART_OPEN_STATUS:
        return {
          ...state,
          isCartOpen: action.payload,
          isWishListOpen: false,
          isCategoryMenuOpen: false,
          isRightDrawerOpen: false
        };
      case SET_WISHLIST_OPEN_STATUS:
        return {
          ...state,
          isWishListOpen: action.payload,
          isCartOpen: false,
          isCategoryMenuOpen: false,
          isRightDrawerOpen: false
        };
      case SET_RIGHT_DRAWER_OPEN:
        return {
          ...state,
          isRightDrawerOpen: action.payload,
          isCartOpen: false,
          isCategoryMenuOpen: false,
          isWishListOpen: false
        };
      case RESET_DRAWERS:
        return {
          ...initialState
        };
      default:
        return state;
    }
  }
};
