import {
  RESER_FILTERS,
  SET_SELECTED_BRAND,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
  SET_SELECTED_VIEW
} from '../actions/actionTypes';

import { Action } from '../../types/type';

const inititalState = {
  selectedView: 'grid',
  selectedBrand: 'All',
  selectedCategory: 'All',
  selectedSubCategory: 'All'
};

const productReducer = (state = inititalState, { type, payload }: Action) => {
  if (typeof type !== undefined) {
    switch (type) {
      case SET_SELECTED_VIEW:
        return { ...state, selectedView: payload };
      case SET_SELECTED_BRAND:
        return { ...state, selectedBrand: payload };
      case SET_SELECTED_CATEGORY:
        return { ...state, selectedCategory: payload };
      case SET_SELECTED_SUB_CATEGORY:
        return { ...state, selectedSubCategory: payload };
      case RESER_FILTERS:
        return {
          ...state,
          selectedBrand: 'All',
          selectedCategory: 'All',
          selectedSubCategory: 'All'
        };
      default:
        return state;
    }
  }
};

export default productReducer;
