import { ProductEntity, ProductListingViewProps } from '../../types/type';

import { ReactComponent as NoProducts } from '../../assets/images/NoProducts.svg';
import ProductGridCard from './ProductGridCard';
import ProductGridLoading from './ProductGridLoading';
import React from 'react';

const ProductGridView = ({
  allProducts,
  loadMore,
  isLoading,
  hasNext
}: ProductListingViewProps) => {
  return (
    <>
      {allProducts && allProducts.length > 0 ? (
        <>
          <section className="grid auto-rows-auto grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 container">
            {allProducts.map((product: ProductEntity) => {
              return <ProductGridCard key={product.id} product={product} />;
            })}
            {isLoading && <ProductGridLoading />}
          </section>
          <section className="grid place-items-center my-6 lg:my-10">
            {hasNext && (
              <button
                className="bg-white border border-primary text-primary text-sm px-6 py-2 hover:bg-primary hover:text-white transition-all duration-150"
                onClick={() => {
                  loadMore();
                }}>
                Load More
              </button>
            )}
          </section>
        </>
      ) : isLoading ? (
        <section className="grid auto-rows-auto grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-7 container px-4">
          <ProductGridLoading />
        </section>
      ) : (
        <section className="container px-4">
          <div className="flex flex-col items-center text-center">
            <NoProducts className="mt-40" />
            <h1 className="text-2xl font-bold mt-6">
              Sorry! No products found :(
            </h1>
            <p className="mt-5 text-text-body font-bold px-10">
              Sorry, We couldn't find anything. Try clearing the filters or
              apply other filters!
            </p>
          </div>
        </section>
      )}
    </>
  );
};

export default ProductGridView;
