import ProfileForm from './ProfileForm';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const ProfileDetails = () => {
  const [isFormVisible, setisFormVisible] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  return (
    <section className="border border-opacity-50 px-2 mx-5 md:px-0 ">
      <section className="px-6">
        <ul>
          <li className="p-3 font-medium text-text-body ">
            Name : <span className="ml-2 font-normal">{user?.fullName}</span>
          </li>
          <li className="p-3 font-medium text-text-body">
            Email : <span className="ml-2 font-normal">{user?.email}</span>
          </li>
          <li className="p-3 font-medium text-text-body">
            Mobile Number :{' '}
            <span className="ml-2 font-normal">{user?.mobileNumber}</span>
          </li>
        </ul>
      </section>
      {/* *For future use */}
      <section className="px-8">
        <button
          onClick={() => {
            setisFormVisible(true);
          }}
          className="bg-primary-darker text-white text-sm hover:bg-primary md:text-md p-2 md:p-3 mb-3">
          Edit Profile
        </button>
        {isFormVisible ? (
          <ProfileForm
            closeModal={() => setisFormVisible(false)}
            user={user}
          />
        ) : null}
      </section>
    </section>
  );
};

export default ProfileDetails;
