import { useEffect, useState } from 'react';

import { ReactComponent as NoOrders } from '../../assets/images/NoOrders.svg';
import OrderDetails from './OrderDetails';
import { OrderHistoryType } from '../../types/type';
import { RootState } from '../../redux/reducers';
import appconfig from '../../config.json';
import { config } from '../../config/config';
import { orderHistory } from '../../api/Profile';
import { useSelector } from 'react-redux';

interface Props {
  userId: number;
}

const statusMapping: { [key: string]: string } = {
  ...appconfig.orderStatusMapping
};

const OrderHistory = (props: Props) => {
  const [orders, setorders] = useState<OrderHistoryType[]>([]);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const [isLoading, setisLoading] = useState(true);
  const [areDetailsShown, setareDetailsShown] = useState(false);
  const [selectedOrder, setselectedOrder] = useState(0);

  const fetchOrderHistory = async () => {
    const data = await orderHistory(config.COMPANY_NO, props.userId);
    if (data) {
      setorders([...data['orders']]);
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchOrderHistory();
    }
  }, []);
  return (
    <>
      {areDetailsShown ? (
        <OrderDetails
          order={orders[selectedOrder]}
          close={() => setareDetailsShown(false)}
        />
      ) : (
        <section className="mx-2 md:px-0 h-104 overflow-y-scroll border-b">
          {isLoading ? (
            <article className="border mb-2  border-black  animate-pulse border-opacity-10 p-6">
              <section className="text-xl bg-gray-400 font-bold text-gray-400 border-b mb-3 pb-2">
                # Order Number
              </section>
              <section className="text-gray-400 bg-gray-400">
                Product Name
              </section>
            </article>
          ) : orders && orders.length > 0 ? (
            orders.map((order, index) => (
              <article
                key={index}
                className="border mb-2  border-black border-opacity-10 p-6">
                <section className="flex flex-row justify-between border-b mb-3 pb-2">
                  <span className="text-md md:text-xl font-bold">
                    #{order.orderNo || order.orderId}
                  </span>
                  <span
                    className={`${
                      statusMapping[order.orderStatus.toLowerCase()]
                    } font-poppins-semibold`}>
                    {order.orderStatus}
                  </span>
                </section>
                <section className="mt-2 pb-2 border-b">
                  <span className="text-sm md:text-base font-poppins-semibold">
                    {order.productsList[0].productName}
                  </span>
                  <br />
                  {order.productsList.length > 1 ? (
                    <>
                      <span className="text-sm md:text-xs font-poppins-regular text-black">
                        + {order.productsList.length - 1} item(s)
                      </span>
                      <br />
                    </>
                  ) : null}
                  <aside
                    onClick={() => {
                      setareDetailsShown(true);
                      setselectedOrder(index);
                    }}
                    className="text-sm md:text-md font-semibold pt-3 cursor-pointer text-primary">
                    View Details
                  </aside>
                </section>
                <article className="flex flex-row-reverse mt-2">
                  <span className="text-sm md:text-md font-semibold">
                    Total Paid:{' '}
                    <span className="text-sm md:text-md font-bold">
                      &#8377;{order.totalPrice}
                    </span>
                  </span>
                </article>
              </article>
            ))
          ) : (
            <section className="grid place-items-center mx-2 md:px-0 h-96">
              <NoOrders />
              <h1 className="text-text-title font-bold text-2xl mt-6">
                No Orders
              </h1>
              <p className="mt-5 text-text-body">
                Looks you haven't placed any orders yet..
              </p>
            </section>
          )}
        </section>
      )}
    </>
  );
};

export default OrderHistory;
