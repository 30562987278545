import { Action } from '../../types/type';
import { SET_WHOLESALE, TOGGLE_WHOLESALE } from '../actions/actionTypes';

const inititalState = false;

const wholesaleReducer = (state = inititalState, { type , payload }: Action) => {
  if (typeof type !== undefined) {
    switch (type) {
      case TOGGLE_WHOLESALE:
        return !state;
      case SET_WHOLESALE:
        return payload;
      default:
        return state;
    }
  }
};

export default wholesaleReducer;
