import { AddToCartPayload } from '../../../types/type';

interface Props {
  item: AddToCartPayload;
  updateCart: (product: AddToCartPayload, quantity: number) => void;
}

export const WithoutLoginCartCard = (props: Props) => {
  return (
    <>
      <main className="flex max-w-sm w-full mx-auto">
        {/* img */}
        <section className="h-1/2 bg-black mr-5 flex-none w-16">
          <img src={props.item.imageUrl} alt="product" />
        </section>
        {/* details */}
        <section className="flex-grow">
          <section className="flex justify-between pb-2">
            <p className="text-sm text-text-body line-clamp-2">
              {props.item.productName}
            </p>
            <button
              onClick={() => {
                props.updateCart(props.item, 0);
              }}>
              x
            </button>
          </section>
          <section className="flex justify-between">
            <section className="flex justify-between pr-2 pl-2 w-24 border border-gray-300">
              <button
                onClick={() => {
                  props.updateCart(props.item, props.item.quantity - 1);
                }}>
                -
              </button>
              <span>{props.item.quantity}</span>
              <button
                onClick={() => {
                  props.updateCart(props.item, props.item.quantity + 1);
                }}>
                +
              </button>
            </section>
            <span className="text-gray-600 font-semibold">
              Rs. {props.item.productPrice}
            </span>
          </section>
        </section>
      </main>
      <hr className="m-2 max-w-sm w-full mx-auto" />
    </>
  );
};
