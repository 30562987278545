import React, { useEffect, useState } from 'react';
import {
  fetchCategories,
  getCompany,
  getCompanyInfo
} from '../../redux/actions/actionCreators';
import {
  getAllBrands,
  getBestSellers,
  getFeaturedProducts,
  getOffers
} from '../../api/company';
import { useDispatch, useSelector } from 'react-redux';
import ProductGridLoading from '../../components/ProductListing/ProductGridLoading'

import BestSellerCard from '../../components/BestSellerCard/BestSellerCard';
import { config as CONFIG } from '../../config/config';
import Carousel from 'react-multi-carousel';
import CategoryComponent from '../../components/CategoryComponent/CategoryComponent';
import CustomArrowsSide from '../../components/common/CustomArrowsSide';
import CustomDot from '../../components/common/CustomDot';
import { Helmet } from 'react-helmet';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import { RootState } from '../../redux/reducers';
import appconfig from '../../config.json';
import defaultImage from '../../assets/images/default-image.png';
import { setSelectedBrand } from '../../redux/actions/productListing';
import { useNavigate } from 'react-router-dom';
import best1 from '../../assets/images/landing-page/best1.png';
import {BrandSkeletonLoding} from './LandingPageSkeleton'

const containerWidthMapping: { [key: string]: string } = {
  full: 'w-full',
  half: 'w-1/2'
};
const containerPositionMapping: { [key: string]: string } = {
  left: 'left-0',
  right: 'right-0'
};
const contentAlignmentMapping: { [key: string]: string } = {
  left: 'text-left items-start',
  center: 'text-center items-center',
  right: 'text-right items-end'
};

const LandingPage = () => {
  const companyDetails = useSelector(
    (state: RootState) => state.companyDetails
  );
  const companyInfo = useSelector((state: RootState) => state.companyInfo);
  const categories = useSelector((state: RootState) => state.categories);
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const config = useSelector((state: RootState) => state.config);
  const others = useSelector((state: RootState) => state.others);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banners, setbanners] = useState<any>([]);

  const [bestSellers, setbestSellers] = useState({ products: [] as any });
  const [brands, setbrands] = useState<any>([]);
  const[isLoading , setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (Object.keys(companyDetails).length === 0) {
      dispatch(getCompany(CONFIG.COMPANY_NO));
    }
    if (Object.keys(companyInfo).length === 0) {
      dispatch(getCompanyInfo(CONFIG.COMPANY_NO));
    }

    /**bestSellers - async function to fetch best selling products */
    dispatch(fetchCategories(CONFIG.COMPANY_NO));
    const bestSellers = async () => {
      try {
        let banner: any[] = [];
        const bestSellers = await getBestSellers(
          CONFIG.COMPANY_NO,
          isWholesale
        );
        banner = await getOffers(CONFIG.COMPANY_NO);
        setbanners(banner);
        const brands = await getAllBrands(CONFIG.COMPANY_NO);
        setbrands([...brands['brand']]);
        if (bestSellers['data'].length !== 0) {
          const bestSellerData = { ...bestSellers['data'] };
          bestSellerData.products = bestSellers['data']['products'].map(
            (item: any) => {
              return { ...item, id: item._id };
            }
          );
          setbestSellers(bestSellerData);
          setIsLoading(false);
        } else {
          const featuredProducts = await getFeaturedProducts(
            CONFIG.COMPANY_NO,
            'product_name',
            'asc'
          );
          if (
            featuredProducts !== undefined &&
            featuredProducts.length !== 0
          ) {
            setbestSellers({ products: [...featuredProducts] });
          }
          setIsLoading(false);
        }
      } catch (err) {}
    };
    bestSellers();
  }, [isWholesale]);

  const bannnerDisplayPosition = (value: any) => {
    let bannerPostion = banners.filter(
      (pictures: any) => pictures.bannerDisplayOption === value
    );
    return bannerPostion;
  };

  const allBannerPositions = (value: any) => {
    return (
      
        <div>
          <Carousel
            responsive={appconfig.bannerResponsiveness}
            infinite
            keyBoardControl
            containerClass="snap-x lg:h-full h-75"
            arrows={false}
            autoPlay
            itemClass="flex items-center snap-center "
            autoPlaySpeed={2500}>
            {bannnerDisplayPosition(value).map((each: any, index: number) => {
              return (
                <img
                  src={each.offerImageUrl || defaultImage}
                  alt={each.offerName}
                  className="h-full w-full"
                  onError={(e) => {
                    e.currentTarget.src = defaultImage;
                  }}
                  key={index}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                />
              );
            })}
          </Carousel>
        </div>
      
    );
  };

  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${appconfig.companyName} - ${
            appconfig.pages.filter((page) => page.name === 'Home')[0].name
          }`}
        </title>
        <meta
          name="title"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo.title
          }
        />
        <meta
          name="description"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo
              .description
          }
        />
        <meta
          name="keywords"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo
              .keywords
          }
        />
        <meta
          property="og:type"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo.type
          }
        />
        <meta
          property="og:title"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo.title
          }
        />
        <meta
          property="og:url"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo.url
          }
        />
        <meta
          property="og:image"
          content={
            appconfig.pages.filter((page) => page.name === 'Home')[0].seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}

      {/* Top Banner Starts */}
      {!isLoading? 
      bannnerDisplayPosition('Top').length > 0 ? 
      <section className="w-full  flex flex-col justify-center relative lg:h-[500px] xl:h-[calc(100vh-100px)] overflow-hidden">
        <div
          className="absolute z-[1] top-0 w-full h-full flex flex-col items-center justify-end text-center"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="1100"
          data-aos-easing="ease-in-out">
          <button
            className="lg:mb-16 mb-6 ml-2 font-poppins-medium text-primary bg-white border transition-all duration-150 hover:border-primary hover:bg-primary hover:text-white px-2 sm:px-4 lg:px-11 py-1 sm:py-3 lg:py-5 text-xs sm:text-lg lg:text-2xl w-fit"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/products');
            }}>
            Shop Now
          </button>
        </div>
        {allBannerPositions('Top')}
      </section> 
      :null
      :<section className="w-full bg-gray-400 animate-pulse cursor-pointer h-48 lg:h-96 xl:h-96 justify-center relative overflow-hidden">
      </section>
      }

      {/* Top Banner Ends */}
      <section className="pt-14 h-auto">
        <div className="flex">
          <section className="text-center grid grid-cols-12">
            <div className="col-span-1 lg:col-span-3"></div>
            <div
              className="col-span-10 lg:col-span-6"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-easing="ease-in-out">
              <h1 className="text-text-title font-poppins-semibold text-2xl lg:text-4xl laptop:text-55 leading-[60px]">
                About Us
              </h1>
              <div className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  x="0"
                  y="0"
                  width="100"
                  height="60"
                  viewBox="0 0 960 560"
                  enableBackground="new 0 0 960 560"
                  className="mx-auto">
                  <polyline
                    className="zigzag"
                    fill="none"
                    stroke="#FF6E68"
                    strokeWidth="64"
                    strokeMiterlimit="12"
                    points="902 245.5 762.5 141.5 623.01 245.5 483.51 141.5 344.01 245.5 204.5 141.5 65 245.5 "
                  />
                </svg>
              </div>{' '}
              <p className="text-text-body mt-3 text-md laptop:text-xl">
                {config?.isConfigLoaded ? config.parameters['about_us'] : null}
              </p>
            </div>
            <div className="col-span-1 lg:col-span-3"></div>
          </section>
        </div>

        {!isLoading? 
        bestSellers && bestSellers?.products.length !== 0 ? (
          <section
            className="px-1 text-center"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-easing="ease-in-out"
            // style={{
            //   backgroundImage: `url(${best1})`,
            //   backgroundRepeat: 'no-repeat'
            // }}
          >
            <section className="flex mx-auto justify-center items-center text-center mb-4 mt-16">
              {/* <img className="h-full relative w-auto" src={best1} alt="" /> */}
              <div className="wave">
                <svg
                  id="wave"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 456.7 39.9">
                  <path
                    d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                    c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                  c8.9,6.8,15.9,13.7,16,13.8"
                    stroke="rgb(241,204,154)"
                    strokeWidth={7}
                  />
                </svg>
              </div>
              <h1 className="mx-4 lg:mx-10 text-text-title font-poppins-semibold text-2xl lg:text-4xl laptop:text-55">
                Best Sellers
              </h1>
              {/* <img className="h-full relative w-auto" src={best2} alt="" /> */}
              <div className="wave">
                <svg
                  id="wave"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 456.7 39.9">
                  <path
                    d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                    c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                  c8.9,6.8,15.9,13.7,16,13.8"
                    stroke="rgb(241,204,154)"
                    strokeWidth={7}
                  />
                </svg>
              </div>
            </section>
            <section className="mx-auto relative p-5 flex items-center justify-center w-full md:w-4/5">
              <Carousel
                responsive={appconfig.bestSellerResponsiveness}
                autoPlaySpeed={2500}
                infinite
                draggable
                swipeable
                keyBoardControl
                pauseOnHover
                partialVisible
                customButtonGroup={<CustomArrowsSide isBestSeller />}
                arrows={false}
                renderButtonGroupOutside
                itemClass="snap-center"
                containerClass="snap-x pt-5 w-full box-content">
                {bestSellers.products.map((bestSeller: any, index: number) => (
                  <BestSellerCard product={bestSeller} key={index} />
                ))}
              </Carousel>
            </section>
          </section>
        ) : null
        :
        <section className="mx-auto relative p-5 flex items-center justify-center w-full md:w-4/5">
          <Carousel
            responsive={appconfig.bestSellerResponsiveness}
            autoPlaySpeed={2500}
            infinite
            draggable
            swipeable
            keyBoardControl
            pauseOnHover
            partialVisible
            customButtonGroup={<CustomArrowsSide isBestSeller />}
            arrows={false}
            renderButtonGroupOutside
            itemClass="snap-center mr-5 "
            containerClass="snap-x pt-5 w-full box-content">
              <ProductGridLoading />
              <ProductGridLoading />
              <ProductGridLoading /> 
          </Carousel>
        </section>}
        {/* Middle Banner Starts */}
        {bannnerDisplayPosition('Middle').length > 0?
          <section className="w-full  flex flex-col justify-center relative lg:h-[500px] xl:h-[calc(100vh-100px)] overflow-hidden">
            {allBannerPositions('Middle')}
          </section> 
        : null
        }
        {/* Midldle Banner Ends */}
        {categories &&
          categories.map((cat: any, index: number) =>
            cat.categoryName ? (
              <CategoryComponent key={index} category={cat} />
            ) : null
          )}
        {!isLoading ?
         brands && brands.length !== 0 ? (
          <section
            className="mt-16 laptop:mt-60 bg-primary-dark py-10"
            data-aos="zoom-in"
            data-aos-delay="500"
            data-aos-duration="500"
            data-aos-easing="ease-in-out">
            <p className="font-poppins-medium text-center text-white text-20 lg:text-4xl laptop:text-55  font-bold lg:mt-3 laptop:mt-24">
              Some of our top selling brands
            </p>
            <p className="text-center text-white  text-sm laptop:text-3xl px-20 mt-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Repellat, soluta.
            </p>
            <section className="w-2/3 mx-auto mt-10 laptop:mt-16 mb-10">
              <Carousel
                responsive={appconfig.brandResponsiveness}
                autoPlaySpeed={2500}
                itemClass="snap-center"
                infinite
                draggable
                sliderClass="snap-x"
                swipeable
                arrows={true}
                keyBoardControl
                pauseOnHover
                partialVisible
                containerClass="snap-x flex items-center justify-center">
                {brands.map((brand: any, index: number) => (
                  <section
                    className="cursor-pointer mx-2"
                    key={index}
                    onClick={() => {
                      dispatch(setSelectedBrand(brand.brandName));
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        navigate('/products');
                      }, 600);
                    }}>
                    <img
                      src={brand.brandImageURL}
                      alt={brand.brandName}
                      onError={(e) => {
                        e.currentTarget.src = defaultImage;
                      }}
                      className="max-h-[300px] aspect-square w-[200px]"
                    />
                  </section>
                ))}
              </Carousel>
            </section>
          </section>
        ) : null
        : 
        <section className="mt-16 laptop:mt-60 bg-gray-200 py-10">
          <div className="mx-auto h-8 bg-gray-400 animate-pulse dark:bg-gray-700 w-2/5 mb-4"></div>
          <div className="mx-auto h-4 bg-gray-400 animate-pulse dark:bg-gray-700 w-2/5 mb-4"></div>
        <section className="mx-auto relative border border-slate-200 p-5 flex items-center justify-center w-full md:w-4/5">
          <Carousel
          responsive={appconfig.brandResponsiveness}
          autoPlaySpeed={2500}
          itemClass="snap-center"
          infinite
          draggable
          sliderClass="snap-x"
          swipeable
          arrows={false}
          keyBoardControl
          pauseOnHover
          partialVisible
          containerClass="snap-x pt-5 w-full box-content gap-x-4">
            <BrandSkeletonLoding/>
            <BrandSkeletonLoding/>
            <BrandSkeletonLoding/>
            <BrandSkeletonLoding/>
          </Carousel>
        </section>
        </section>}
        {!isLoading? others && others.testimonials.length > 0 ? (
          <section
            className="mt-16 laptop:mt-36 pb-10 text-center"
            data-aos="zoom-in"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out">
            <section className="flex mx-auto justify-center text-center mb-5">
              <div className="wave">
                <svg
                  id="wave"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 456.7 39.9">
                  <path
                    d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                    c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                  c8.9,6.8,15.9,13.7,16,13.8"
                    stroke="rgb(241,204,154)"
                    strokeWidth={7}
                  />
                </svg>
              </div>
              <h1 className="text-text-title font-bold text-xl lg:text-3xl laptop:text-6xl font-poppins-regular">
                What Our Customers Say
              </h1>
              <div className="wave">
                <svg
                  id="wave"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 456.7 39.9">
                  <path
                    d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
                  c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
                    c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
                  c8.9,6.8,15.9,13.7,16,13.8"
                    stroke="rgb(241,204,154)"
                    strokeWidth={7}
                  />
                </svg>
              </div>
              {/* <img
              className="h-full relative w-auto top-5 ml-4 lg:ml-8"
              src={best2}
              alt=""
            /> */}
            </section>
            <section className="mx-auto w-5/6 mt-4 laptop:mt-20 text-center mb-20 laptop:mb-48">
              <Carousel
                responsive={appconfig.testimonialResponsiveness}
                autoPlaySpeed={2500}
                infinite
                draggable
                swipeable
                keyBoardControl
                showDots
                customDot={<CustomDot />}
                pauseOnHover
                containerClass="py-10 snap-x"
                itemClass="snap-center">
                {others && others.testimonials.length > 0
                  ? others.testimonials.map(
                      (testimonial: any, index: number) => (
                        <ReviewCard key={index} review={testimonial} />
                      )
                    )
                  : null}
              </Carousel>
            </section>
          </section>
        ) : null
        :<section className="mx-auto w-5/6 mt-4 laptop:mt-20 text-center mb-20 laptop:mb-48">
        <Carousel
            responsive={appconfig.testimonialResponsiveness}
            autoPlaySpeed={2500}
            infinite
            draggable
            swipeable
            keyBoardControl
            showDots
            customDot={<CustomDot />}
            pauseOnHover
            containerClass="py-10 snap-x"
            itemClass="snap-center">
              <section className="border bg-gray-400 animate-pulse place-items-center h-48 sm:h-56 md:h-60 lg:h-64 xl:h-72 overflow-hidden relative"></section>
              <section className="border bg-gray-400 animate-pulse place-items-center h-48 sm:h-56 md:h-60 lg:h-64 xl:h-72 overflow-hidden relative"></section>  
              <section className="border bg-gray-400 animate-pulse place-items-center h-48 sm:h-56 md:h-60 lg:h-64 xl:h-72 overflow-hidden relative"></section>
            </Carousel>
            </section>}
      </section>

      {/* Bottom banner Start */}
      {bannnerDisplayPosition('Bottom').length > 0?
        <section className="w-full  flex flex-col justify-center relative lg:h-[500px] xl:h-[calc(100vh-100px)] overflow-hidden">
          {allBannerPositions('Bottom')}
        </section> 
        : null
        }
      {/* Bottom banenr Ends */}
    </>
  );
};

export default LandingPage;
