import {
  fetchCategories,
  fetchConfig,
  fetchTestimonials,
  getCartCountAction,
  getCompany,
  getCompanyInfo,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import BottomBar from '../Navbar/BottomBar';
import Footer from '../Footer/Footer';
import { FooterProps, SEO } from '../../types/type';
import Navbar from '../Navbar/Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { config } from '../../config/config';
import { getLoginCart } from '../../redux/actions/loginCart';
import { resetDrawers } from '../../redux/actions/drawer';
import { getSeo } from '../../api/Configuration';
import { Helmet } from 'react-helmet';

const Layout = () => {
  const companyDetails = useSelector(
    (state: RootState) => state.companyDetails
  );
  const companyInfo = useSelector((state: RootState) => state.companyInfo);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const categories = useSelector((state: RootState) => state.categories);
  const topValue = useSelector((state: RootState) => state.topValue);
  const [seo, setSeo] = useState({} as SEO);
  const dispatch = useDispatch();
  const location = useLocation();
  /** Props to be passed to Footer Component after API response */
  const FooterProps: FooterProps = {
    customerAddress: companyDetails['companyAddress'] || '',
    customerContact: companyDetails['contactNumber'] || '',
    customerEmail: companyDetails['contactEmail'] || '',
    categories: categories || []
  };

  useEffect(() => {
    dispatch(fetchConfig(config.COMPANY_NO));
    dispatch(fetchTestimonials(config.COMPANY_NO));
    if (Object.keys(companyDetails).length === 0) {
      dispatch(getCompany(config.COMPANY_NO));
    }
    if (Object.keys(companyInfo).length === 0) {
      dispatch(getCompanyInfo(config.COMPANY_NO));
    }
    dispatch(fetchCategories(config.COMPANY_NO));
    if (isLoggedIn) {
      dispatch(getCartCountAction());
      dispatch(getWishListCountAction());
      dispatch(getLoginCart());
    }
  }, []);

  useEffect(() => {
    dispatch(resetDrawers());
  }, []);
  useEffect(() => {
    const getSeoData = async () => {
      let res = await getSeo(location.pathname);
      if (res['success']) {
        setSeo(res['data']);
      }
    };
    getSeoData();
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{seo.og_title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={seo.og_title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.og_image} />
        <meta property="og:url" content={seo.og_url} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet>
      <main className="relative h-screen">
        <Navbar />
        <div
          className="relative"
          style={{
            top: `${topValue}px`
          }}>
          <section>
            <Outlet />
            <BottomBar />
          </section>
          <section className="relative">
            <Footer {...FooterProps} />
          </section>
        </div>
      </main>
    </>
  );
};

export default Layout;
