import customer from '../../assets/images/landing-page/customer.png';
import quotes from '../../assets/images/landing-page/quotes.svg';
import reviews from '../../assets/images/landing-page/reviews.svg';

interface ReviewProps {
  review: {
    id: number;
    customerName: string;
    testimonialImageUrl: string;
    description: string;
    monthsAgo: number;
    stars: number;
  };
}

const ReviewCard = ({ review }: ReviewProps) => {
  let { testimonialImageUrl, customerName, id, monthsAgo, description, stars } =
    review;
  return (
    <section className="bg-white mx-2 p-10">
      <section className="flex flex-row">
        <img src={testimonialImageUrl}  className="w-16 h-16 rounded-full" alt="" />
        <span className="text-text-base text-sm md:text-xl text-center mt-3 ml-2">
          {customerName}
        </span>
      </section>
      <img className="mt-2" src={quotes} alt="" />
      <p className="mt-2 md:mt-4 text-xs md:text-sm text-text-body text-left line-clamp-5 font-poppins-light">
        {description}
      </p>
      <section className="flex md:flex-row mt-3">
        <img className="w-12 hidden md:block lg:w-auto" src={reviews} alt="" />
        <span className="text-xxs md:text-xs text-text-body ml-2 lg:ml-5 mt-2">
          {monthsAgo} months ago
        </span>
        <img className="rotate-180 ml-1 lg:ml-10" src={quotes} alt="" />
      </section>
    </section>
  );
};

export default ReviewCard;
