import { CartItem, PlacedOrderResponse } from '../../types/type';
import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

interface CartData {
  cartData: CartItem[];
  placedOrderResponse: PlacedOrderResponse;
  couponDiscountAmount: number;
}

const ConformationCartDetails: FC<CartData> = ({
  cartData,
  placedOrderResponse,
  couponDiscountAmount
}: CartData) => {
  const navigate = useNavigate();
  let total = 0;

  // To display Total Price
  const getDisplayPrice = () => {
    cartData.forEach((p: CartItem) => {
      total = total + p.totalPrice;
    });
    return total;
  };

  // To display Discounted Price and add with DefaultDelivery charges.
  const getSubTotalPrice = () => {
    placedOrderResponse.deliveryCharges=placedOrderResponse.deliveryCharges ? placedOrderResponse.deliveryCharges : 0;
    if (couponDiscountAmount > 0) {
     // return total - couponDiscountAmount;
         total =  total - couponDiscountAmount;
         return total + placedOrderResponse.deliveryCharges;
      
    } else {
      return total + placedOrderResponse.deliveryCharges;
      
    }
  };

  const navigateToShop = () => {
    navigate('/');
  };
  return (
    <section className="bg-white px-6 py-8">
      <section className="mb-6">
        <section className="flex gap-10 text-2xl font-bold">
          <span>Total item ({cartData.length})</span>{' '}
          <span> Order No: {placedOrderResponse.orderNo}</span>
        </section>
      </section>
      <section className="h-60 overflow-y-scroll divide-y">
        {cartData.map((data, i) => {
          return (
            <article key={i}>
              <main className="flex w-full mx-auto py-6">
                {/* img */}
                <section className="h-1/2 bg-black mr-5 flex-none w-16">
                  <img
                    src={data?.productEntity?.compressProductImageUrl}
                    alt="product"
                  />
                </section>
                {/* details */}
                <section className="flex-grow">
                  <p className="text-sm text-text-body">
                    {data?.productEntity?.productName}
                  </p>
                  <section className="text-sm text-text-body">
                    {data?.quantity} Qty
                  </section>
                </section>
                <section className="text-gray-600 font-semibold pt-1">
                  Rs. {data?.totalPrice}
                </section>
              </main>

              {/* {i === (cartData.length - 1) ? <hr className="border border-gray-500" /> : <hr />} */}
            </article>
          );
        })}
      </section>
      <section className="w-full mx-auto pr-2">
        <section className="flex justify-between pb-4">
          <section className="text-sm text-text-body text-gray-40">
            Total MRP
          </section>
          <section className="text-sm text-text-body text-gray-40">
            Rs. {getDisplayPrice()}
          </section>
        </section>
        <section className="flex justify-between pb-4">
          <section className="text-sm text-text-body text-gray-40">
            Your Saving / Discounts
          </section>
          <section className="text-sm text-text-body text-gray-40">
            - Rs. {couponDiscountAmount ? couponDiscountAmount : 0}
          </section>
        </section>
        <section className="flex justify-between pb-4">
          <section className="text-sm text-text-body text-gray-40">
            Delivery Charges
          </section>
          <section className="text-sm text-text-body text-gray-40">{placedOrderResponse.deliveryCharges ? placedOrderResponse.deliveryCharges : 0}</section>
        </section>
      </section>
      <section className="flex justify-between pr-2 py-2">
        <section className="text-lg text-text-body font-bold">Total</section>
        <section className="text-lg text-text-body font-bold">
          Rs. {getSubTotalPrice()}
        </section>
      </section>
      <button
        className="w-full mx-auto bg-primary text-white px-6 py-2 mt-6"
        onClick={navigateToShop}>
        CONTINUE TO SHOPPING
      </button>
    </section>
  );
};

export default ConformationCartDetails;
