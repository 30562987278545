import {
  CLOSE_AGE_RESTRICTION_MODAL,
  CLOSE_LOGIN_MODAL,
  CLOSE_QUICK_VIEW_MODAL,
  OPEN_AGE_RESTRICTION_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_QUICK_VIEW_MODAL,
  SET_QUICKVIEW_PRODUCT,
  UNSET_QUICKVIEW_PRODUCT
} from '../actionTypes';
export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL
});

export const closeLoginModal = () => ({
  type: CLOSE_LOGIN_MODAL
});

export const openAgeModal = () => ({
  type: OPEN_AGE_RESTRICTION_MODAL
});

export const closeAgeModal = () => ({
  type: CLOSE_AGE_RESTRICTION_MODAL
});

export const openQuickViewModal = () => ({
  type: OPEN_QUICK_VIEW_MODAL
});

export const closeQuickViewModal = () => ({
  type: CLOSE_QUICK_VIEW_MODAL
});

export const setQuickViewProduct = (product: any) => ({
  type: SET_QUICKVIEW_PRODUCT,
  payload: product
});

export const unsetQuickViewProduct = () => ({
  type: UNSET_QUICKVIEW_PRODUCT
});
