import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import './index.css';

import { persistor, store } from './redux/store';

import AllRoutes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <AllRoutes />
        </PersistGate>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
