export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const OPEN_AGE_RESTRICTION_MODAL = 'OPEN_AGE_RESTRICTION_MODAL';
export const CLOSE_AGE_RESTRICTION_MODAL = 'CLOSE_AGE_RESTRICTION_MODAL';
export const OPEN_QUICK_VIEW_MODAL = 'OPEN_QUICK_VIEW_MODAL';
export const CLOSE_QUICK_VIEW_MODAL = 'CLOSE_QUICK_VIEW_MODAL';
export const SET_CART_COUNT = 'SET_CART_COUNT';
export const SET_WISHLIST_COUNT = 'SET_WISHLIST_COUNT';
export const TOGGLE_WHOLESALE = 'TOGGLE_WHOLESALE';
export const SET_SELECTED_SUB_CATEGORY = 'SET_SELECTED_SUB_CATEGORY';
export const SET_WHOLESALE = 'SET_WHOLESALE';
export const SET_QUICKVIEW_PRODUCT = 'SET_QUICKVIEW_PRODUCT';
export const UNSET_QUICKVIEW_PRODUCT = 'UNSET_QUICKVIEW_PRODUCT';
export const RESET_COUNTS = 'RESET_COUNTS';
export const SET_CART_OPEN_STATUS = 'SET_CART_OPEN_STATUS';
export const SET_WISHLIST_OPEN_STATUS = 'SET_WISHLIST_OPEN_STATUS';
export const SET_CATEGORY_OPEN_STATUS = 'SET_CATEGORY_OPEN_STATUS';
export const ADD_TO_CART_WITHOUT_LOGIN = 'ADD_TO_CART_WITHOUT_LOGIN';
export const REMOVE_FROM_CART_WITHOUT_LOGIN = 'REMOVE_FROM_CART_WITHOUT_LOGIN';
export const UPDATE_CART_WTIHOUT_LOGIN = 'UPDATE_CART_WITHOUT_LOGIN';
export const RESET_CART = 'RESET_CART';
export const SET_RIGHT_DRAWER_OPEN = 'SET_RIGHT_DRAWER_OPEN';
export const RESER_FILTERS = 'RESET_FILTERS';
export const SET_LOADED_CONFIG = 'SET_LOADED_CONFIG';
export const REVERT_CONFIG = 'REVERT_CONFIG';
export const SET_TESTIMONIALS = 'SET_TESTIMONIALS';
export const SET_BLOGS = 'SET_BLOGS';
export const SET_LOGIN_CART = 'SET_LOGIN_CART';
export const RESET_LOGIN_CART = 'RESET_LOGIN_CART';
export const SET_TOP_VALUE = 'SET_TOP_VALUE';
export const RESET_DRAWERS = 'RESET_DRAWERS';
