import React, { useRef } from 'react';
import {
  setCartDrawer,
  setCategoryMenu,
  setRightDrawer,
  setWishList
} from '../../../redux/actions/drawer';
import { useDispatch, useSelector } from 'react-redux';

import { DrawerInterface } from '../../../types/type';
import { RootState } from '../../../redux/reducers';
import icons from '../../../assets/icons';

const Drawer = ({ children, isOpen, title, position }: DrawerInterface) => {
  const ref = useRef(null);
  const drawers = useSelector((state: RootState) => state.drawers);
  const topValue = useSelector((state: RootState) => state.topValue);

  const dispatch = useDispatch();

  return (
    <section
      style={{
        height: `calc(100vh - ${topValue + 50}px)`
      }}
      className={
        'fixed w-auto z-50 bg-opacity-25 inset-0 ease-in-out transition-all duration-700 ' +
        (isOpen
          ? 'opacity-100 translate-x-0'
          : position === 'right'
          ? 'opacity-0 translate-x-full'
          : 'opacity-0 -translate-x-full')
      }>
      <section
        style={{
          top: `${topValue}px`
        }}
        ref={isOpen ? ref : null}
        className={`max-w-md absolute h-full bg-white shadow-xl duration-500 ease-in-out transition-all delay-400  border 
          ${position === 'right' ? 'right-0 w-11/12' : 'left-0 w-auto'}
            `}>
        <article
          className={`max-w-lg flex flex-col h-full overflow-y-auto ${
            position === 'right' ? 'p-4' : ' w-72 md:w-80'
          }`}>
          {position === 'right' ? (
            <header
              className={`flex flex-row my-2 ${
                title ? 'justify-between' : 'justify-end'
              } font-bold text-text-title text-lg mb-4`}>
              {title ? title : null}
              <img
                className="cursor-pointer"
                onClick={() => {
                  if (drawers?.isCartOpen) {
                    dispatch(setCartDrawer(false));
                  } else if (drawers?.isWishListOpen) {
                    dispatch(setWishList(false));
                  } else if (drawers?.isRightDrawerOpen) {
                    dispatch(setRightDrawer(false));
                  }
                }}
                src={icons.close}
                alt=""
              />
            </header>
          ) : null}
          <section className="h-[670px] overflow-y-auto">{children}</section>
        </article>
      </section>
      <section
       style={{
        background: '#00000080',
      }}
        className="w-screen h-screen cursor-not-allowed"
        onClick={() => {
          if (drawers?.isCartOpen) {
            dispatch(setCartDrawer(false));
          } else if (drawers?.isCategoryMenuOpen) {
            dispatch(setCategoryMenu(false));
          } else if (drawers?.isWishListOpen) {
            dispatch(setWishList(false));
          } else if (drawers?.isRightDrawerOpen) {
            dispatch(setRightDrawer(false));
          }
        }}></section>
    </section>
  );
};

export default Drawer;
