import { AddToCartPayload, BestSellerCardProps } from '../../types/type';
import { addToCart, addToWishlist } from '../../api/CartApi';
import {
  addToCartWithoutLogin,
  createAddToCartPayload
} from '../../redux/actions/cart';
import {
  getCartCountAction,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import {
  openLoginModal,
  openQuickViewModal,
  setQuickViewProduct
} from '../../redux/actions/modals';
import { useDispatch, useSelector } from 'react-redux';

import ProductCardIcon from '../ProductListing/ProductCardIcon';
import { RootState } from '../../redux/reducers';
import defaultImage from '../../assets/images/default-image.png';
import { getLoginCart } from '../../redux/actions/loginCart';
import productReducer from '../../redux/reducers/productReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const BestSellerCard = (props: BestSellerCardProps) => {
  const navigate = useNavigate();
  const [displayImage, setdisplayImage] = useState(
    props.product.product_url || props.product.productImageUrl
  );
  const [className, setclassName] = useState('');

  return (
    <>
      <section
        className="flex flex-col group relative cursor-pointer mx-2 shadow-md p-4 lg:hover:shadow-gray-600/40
        border-solid border rounded-md border-slate-300 hover:-translate-y-3 transition-all duration-300"
        onMouseEnter={() => {
          setdisplayImage(
            props.product.productSubImageUrl1
              ? props.product.productSubImageUrl1
              : displayImage
          );
        }}
        onMouseLeave={() => {
          setdisplayImage(
            props.product.product_url || props.product.productImageUrl
          );
          setclassName('');
        }}>
        <section className="relative w-full h-auto aspect-square bg-white text-center">
          <img
            className={`max-h-80 inline ${className}`}
            onClick={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                navigate(
                  `/products/${props.product.product_id || props.product.id}`
                );
              }, 600);
            }}
            src={displayImage}
            onError={(e) => {
              e.currentTarget.src = defaultImage;
            }}
            alt=""
          />
          <section className="flex justify-center">
            <ProductCardIcon product={props.product} />
          </section>
        </section>
        <section className="mt-4 text-left">
          <p className="text-md">
            <p className="cardTitle truncate text-text-muted capitalize"
            >
              {props.product.product_name
                ? props.product.product_name.toLowerCase()
                : props.product.productName
                ? props.product.productName.toLowerCase()
                : 'Product Name'}
            </p>
            <span className="block text-text-title text-xl font-bold">
              &#8377;
              {props.product.afterDiscountPrice
                ? props.product.afterDiscountPrice
                : 250}{' '}
              {props.product.discount > 0 ? (
                <del className="text-text-muted text-sm">
                  &#8377;
                  {props.product.productPrice
                    ? props.product.productPrice
                    : 200}
                </del>
              ) : null}
            </span>
          </p>
        </section>
      </section>
    </>
  );
};

export default BestSellerCard;
