// import { company, getCompanyInformation } from '../../api/company';
import {
  RESER_FILTERS,
  SET_SELECTED_BRAND,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
  SET_SELECTED_VIEW,
  SET_WHOLESALE,
  TOGGLE_WHOLESALE
} from '../actionTypes';

/** setSelectedView - returns an action, when dispatched stores current view in store  */
export const setSelectedView = (payload: any) => ({
  type: SET_SELECTED_VIEW,
  payload: payload
});

/** setSelectedBrand - returns an action, when dispatched stores current Brand in store  */
export const setSelectedBrand = (payload: any) => ({
  type: SET_SELECTED_BRAND,
  payload: payload
});

/** setSelectedCategory - returns an action, when dispatched stores current Category in store  */
export const setSelectedCategory = (payload: any) => ({
  type: SET_SELECTED_CATEGORY,
  payload: payload
});

/**
 * setSelectedSubCategory - return an action, when dispatched stores subcategory in store
 * @param {string} payload - subcategory name
 * @returns action
 */
export const setSelectedSubCategory = (payload: any) => ({
  type: SET_SELECTED_SUB_CATEGORY,
  payload: payload
});

export const toggleWholesale = () => ({
  type: TOGGLE_WHOLESALE
});

export const setWholesale = (payload: boolean) => ({
  type: SET_WHOLESALE,
  payload: payload
});

/**
 * resetFilters - returns an action which resets filters
 * @returns
 */
export const resetFilters = () => ({
  type: RESER_FILTERS
});
