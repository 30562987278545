import { Helmet } from 'react-helmet';
import ProductHeader from '../../components/ProductListing/ProductHeader';
import config from '../../config.json';
import { useEffect } from 'react';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].name
          }
        </title>
        <meta
          name="title"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.title
          }
        />
        <meta
          name="description"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.description
          }
        />
        <meta
          name="keywords"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.keywords
          }
        />
        <meta
          property="og:type"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.type
          }
        />
        <meta
          property="og:title"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.title
          }
        />
        <meta
          property="og:url"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.url
          }
        />
        <meta
          property="og:image"
          content={
            config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].seo.image
          }
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
      </Helmet> */}
      <ProductHeader
        breadcrumbArray={['home', 'terms and conditions']}
        productCategory="Terms and Conditions"
      />
      <section className="my-7 px-5 lg:px-20">
        <div
          dangerouslySetInnerHTML={{
            __html: config.pages.filter(
              (page) => page.name === 'Terms And Conditions'
            )[0].content
          }}></div>
      </section>
    </>
  );
};

export default TermsAndConditions;
