import { Action } from '../../types/type';
import { SET_TOP_VALUE } from '../actions/actionTypes';

const initialState = 0;

/**
 * topReducer - function to handle actions pertaining to user
 * @param {any} state - state passed to the reducer
 * @param {Action} action - action that is dipatched
 * @returns user state
 */
const topReducer = (state = initialState, { type, payload }: Action) => {
  if (typeof type !== undefined) {
    switch (type) {
      case SET_TOP_VALUE: {
        return payload;
      }
      default: {
        return state;
      }
    }
  }
};

export default topReducer;
