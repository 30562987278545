import { AddToCartPayload, CartItem, CartItemUpdate } from '../../types/type';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  createAddToCartPayload,
  removeFromCartWithoutLogin,
  resetReduxCart,
  updateCartWithoutLogin
} from '../../redux/actions/cart';
import {
  getCartDataList,
  resetCart,
  updateCartItemQuantity
} from '../../api/CartApi';
import { getLoginCart, setLoginCart } from '../../redux/actions/loginCart';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/common/Card/Card';
import { ReactComponent as CartIcon } from '../../assets/images/EmptyCart.svg';
import Drawer from '../../components/common/Drawer/Drawer';
import { RootState } from '../../redux/reducers';
import { WithoutLoginCartCard } from '../../components/common/Card/WithoutLoginCartCard';
import { getAllShippingAddress } from '../../api/CartCheckoutApi';
import { getCartCountAction } from '../../redux/actions/actionCreators';
import { openLoginModal } from '../../redux/actions/modals';
import { setCartDrawer } from '../../redux/actions/drawer';

const CartPage = () => {
  const [cartData, setCartData] = useState([] as CartItem[]);
  const [withoutLoginCart, setwithoutLoginCart] = useState(
    [] as AddToCartPayload[]
  );
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const drawers = useSelector((state: RootState) => state.drawers);
  const userCart = useSelector((state: RootState) => state.cart);
  const counts = useSelector((state: RootState) => state.counts);
  const dispatch = useDispatch();

  useEffect(() => {
    setwithoutLoginCart(userCart ? userCart : ([] as AddToCartPayload[]));
  }, [userCart]);
  const getCartData = async () => {
    if (isLoggedIn) {
      getCartDataList().then((res) => {
        setCartData(res);
      });
    } else {
      setwithoutLoginCart(userCart ? userCart : ([] as AddToCartPayload[]));
    }
  };
  const navigate = useNavigate();

  // This function use open drawer.
  const openCart = () => {
    if (isLoggedIn) {
      getCartData();
      dispatch(setCartDrawer(true));
    } else {
      getCartData();
      dispatch(setCartDrawer(true));
    }
  };

  // This function increment cart item quantity.
  const onQuantityIncrementClick = (item: CartItem) => {
    const requestBody: CartItemUpdate = {
      cart: true,
      extra: [],
      id: item.id,
      product: item.product,
      quantity: item.quantity + 1,
      replace: true,
      status: true,
      wishlist: false
    };
    updateCartItemQuantityCall(requestBody);
  };

  /**
   * updateQuantityOfCart - This function is used to update quantity of product in cart
   * @param {string} product  - id of product
   * @param {number} quantity - updated quantity
   */
  const updateQuantityOfCart = (
    payload: AddToCartPayload,
    quantity: number
  ) => {
    if (quantity === 0) {
      dispatch(removeFromCartWithoutLogin(payload.product));
      openCart();
    } else {
      dispatch(
        updateCartWithoutLogin(
          createAddToCartPayload(
            payload.product,
            quantity,
            payload.extra,
            payload.productName,
            payload.imageUrl,
            payload.productPrice
          )
        )
      );
      openCart();
    }
  };
  // This function decrement cart item quantity.
  const onQuantityDecrementClick = (item: CartItem) => {
    const requestBody: CartItemUpdate = {
      cart: true,
      extra: [],
      id: item.id,
      product: item.product,
      quantity: item.quantity - 1,
      replace: true,
      status: true,
      wishlist: false
    };
    updateCartItemQuantityCall(requestBody);
  };

  // This function remove cart item.
  const onRemoveItemClick = async (item: CartItem) => {
    const requestBody: CartItemUpdate = {
      cart: true,
      extra: [],
      id: item.id,
      product: item.product,
      quantity: 0,
      replace: true,
      status: true,
      wishlist: false
    };
    updateCartItemQuantityCall(requestBody);
  };

  // This function update cart item quantity.
  const updateCartItemQuantityCall = (requestBody: CartItemUpdate) => {
    updateCartItemQuantity(requestBody)
      .then(() => {
        getCartDataList().then((res) => {
          setCartData(res);
          dispatch(getCartCountAction());
          dispatch(setLoginCart(res));
        });
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const getSubTotalPrice = () => {
    if (isLoggedIn) {
      let total = 0;
      cartData.forEach((p) => {
        total = total + p.totalPrice;
      });
      return total;
    } else {
      let total = 0;
      withoutLoginCart.forEach((p) => {
        total += p.productPrice * (p.quantity || 1);
      });
      return total;
    }
  };

  const navigateToCheckoutScreen = async () => {
    dispatch(setCartDrawer(false));
    if (isLoggedIn) {
      getAllShippingAddress()
        .then((res) => {
          if (res && res.length > 0) {
            navigate('/cart-checkout', { state: 1 });
            window.scrollTo(0, 0);
          } else {
            navigate('/cart-checkout', { state: 0 });
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          navigate('/cart-checkout', { state: 0 });
          window.scrollTo(0, 0);
          console.log('error', error);
        });
    } else {
      dispatch(openLoginModal());
    }
  };

  const reset = async () => {
    if (isLoggedIn) {
      resetCart().then((res) => {
        dispatch(getCartCountAction());
        dispatch(getLoginCart());
        getCartData();
        openCart();
      });
    } else {
      dispatch(resetReduxCart());
      getCartData();
      openCart();
    }
  };

  return (
    <>
      <svg
        onClick={() => {
          if (drawers?.isCartOpen) {
            dispatch(setCartDrawer(false));
          } else {
            openCart();
          }
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#2C2C2C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="cursor-pointer group">
        <circle
          cx="9"
          cy="21"
          r="1"
          className="group-hover:stroke-primary transition-all duration-150"></circle>
        <circle
          cx="20"
          cy="21"
          r="1"
          className="group-hover:stroke-primary transition-all duration-150"></circle>
        <path
          d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
          className="group-hover:stroke-primary transition-all duration-150"></path>
      </svg>
      <Drawer
        isOpen={drawers?.isCartOpen}
        title={
          cartData.length > 0
            ? `Shopping Cart (${counts?.cartCount?.ProductQuantity})`
            : 'Shopping Cart'
        }
        position={'right'}>
        {isLoggedIn ? (
          cartData && cartData.length > 0 ? (
            <>
              <article className="my-3 border-b">
                <button
                  onClick={() => reset()}
                  className="text-lg text-text-body mb-3">
                  Reset Cart
                </button>
              </article>
              {cartData.map((data, index) => {
                return (
                  <span key={index}>
                    <Card
                      item={data}
                      onRemoveItemClick={onRemoveItemClick}
                      onQuantityIncrementClick={onQuantityIncrementClick}
                      onQuantityDecrementClick={onQuantityDecrementClick}
                    />
                  </span>
                );
              })}
            </>
          ) : (
            <div className="mt-20 flex flex-col items-center justify-center">
              <CartIcon className="h-28" />
              <h1 className="text-text-title font-bold text-xl mt-6">
                Your Cart is Empty
              </h1>
              <p className="mt-5 text-text-body">
                Look likes you haven't made your choice yet.....
              </p>
              <Link
                onClick={() => {
                  dispatch(setCartDrawer(false));
                }}
                to="/products"
                className="px-4 text-center w-full py-3 bg-primary text-white hover:bg-text-title uppercase font-medium mt-32">
                Shop Now
              </Link>
            </div>
          )
        ) : withoutLoginCart && withoutLoginCart.length ? (
          <>
            <article className="my-3 border-b">
              <button
                onClick={() => reset()}
                className="text-md text-text-body mb-3">
                Reset Cart
              </button>
            </article>
            {withoutLoginCart.map((product, index) => {
              return (
                <span key={index}>
                  <WithoutLoginCartCard
                    item={product}
                    updateCart={updateQuantityOfCart}
                  />
                </span>
              );
            })}
          </>
        ) : (
          <div className="mt-20 flex flex-col items-center justify-center">
            <CartIcon className="h-28" />
            <h1 className="text-text-title font-bold text-xl mt-6">
              Your Cart is Empty
            </h1>
            <p className="mt-5 text-text-body">
              Look likes you haven't made your choice yet.....
            </p>
            <Link
              onClick={() => {
                dispatch(setCartDrawer(false));
              }}
              to="/products"
              className="px-4 text-center w-full py-3 bg-primary text-white hover:bg-text-title uppercase font-medium mt-32">
              Shop Now
            </Link>
          </div>
        )}

        {isLoggedIn ? (
          cartData && cartData.length > 0 ? (
            <section className="grid mx-3 mt-6">
              <section className="flex justify-between">
                <section className="text-sm text-text-body text-gray-40">
                  Subtotal
                </section>
                <section className="text-lg text-text-title font-bold">
                  Rs. {getSubTotalPrice()}
                </section>
              </section>
              <button
                className="bg-primary hover:bg-text-title text-white mt-3 mb-2 px-6 py-2"
                onClick={() => {
                  navigateToCheckoutScreen();
                }}>
                Checkout
              </button>
            </section>
          ) : null
        ) : withoutLoginCart && withoutLoginCart.length > 0 ? (
          <section className="grid mx-3 mt-6">
            <section className="flex justify-between">
              <section className="text-sm text-text-body text-gray-40">
                Subtotal
              </section>
              <section className="text-lg text-text-title font-bold">
                Rs. {getSubTotalPrice()}
              </section>
            </section>
            <button
              className="bg-primary hover:bg-text-title text-white mt-3 mb-2 px-6 py-2"
              onClick={() => {
                navigateToCheckoutScreen();
              }}>
              Checkout
            </button>
          </section>
        ) : null}
      </Drawer>
    </>
  );
};

export default CartPage;
