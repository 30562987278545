import React, { FC } from 'react';

import { PlacedOrderResponse } from '../../types/type';

interface PlacedOrderResponseData {
  data: PlacedOrderResponse;
}

const ConformationUserDetails: FC<PlacedOrderResponseData> = ({
  data
}: PlacedOrderResponseData) => {
  return (
    <section className="">
      <section className="bg-white px-8 py-10 w-80">
        <section className="py-5">
          <section className="text-lg text-text-body font-semibold">
            Name
          </section>
          <section className="text-sm text-text-body text-gray-40">
            {data.userName}
          </section>
          <section className="text-sm text-text-body text-gray-40">
            Mobile : {data.mobileNo}
          </section>
        </section>
        <hr />
        <section className="py-5">
          <section className="text-lg text-text-body font-semibold">
            Email
          </section>
          <section className="text-sm text-text-body text-gray-40">
            {data.emailId}
          </section>
        </section>
        <hr />
        <section className="py-5">
          <section className="text-lg text-text-body font-semibold">
            Delivery Address
          </section>
          <section className="text-sm text-text-body text-gray-40 line-clamp-3">
            {data.shippingAddress}
          </section>
        </section>
        <hr />
        <section className="py-5">
          <section className="text-lg text-text-body font-semibold">
            Payment
          </section>
          <section className="text-sm text-text-body text-gray-40">
            {data.paymentMode}
          </section>
        </section>
      </section>
    </section>
  );
};

export default ConformationUserDetails;
