import Modal from '../Modal';
import { closeAgeModal } from '../../../../redux/actions/modals';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const AgeRestrictModal = () => {
  const dispatch = useDispatch();
  return (
    <Modal>
      <main className="z-50 grid place-items-center h-full fixed w-full text-center cursor-not-allowed bg-gray-800 bg-opacity-50">
        <section className="bg-white shadow-lg max-w-md w-full p-14 space-y-10 cursor-auto">
          <h1 className="text-4xl font-bold">Are you above 18?</h1>
          <p className="font-bold">
            You must be 18 years of age or older to view page. Please verify
            your age to enter.
          </p>
          <article className="space-x-5">
            <button
              className="bg-primary text-white px-5 py-3 hover:bg-text-title"
              onClick={() => {
                localStorage.setItem('isWebsiteRestricted', 'false');
                dispatch(closeAgeModal());
              }}>
              I am above 18
            </button>
            <button
              className="border border-primary text-primary px-5 py-3 hover:bg-primary hover:text-white"
              onClick={() => {
                localStorage.setItem('isWebsiteRestricted', 'true');
                toast.error('You must be above 18 to view this website.');
                dispatch(closeAgeModal());
              }}>
              I am below 18
            </button>
          </article>
        </section>
      </main>
    </Modal>
  );
};

export default AgeRestrictModal;
