import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PaymentSelectionSkeleton = () => {
  return (
    <>
      <SkeletonTheme duration={2}  baseColor="#D3D3D3" highlightColor="#939799">
          <section className="mb-4 px-5 py-2 border border-gray-200 w-[606px]">
            <Skeleton width={100} />
            <section className="text-text-base text-sm font-bold">
              <Skeleton width={200} />
              <Skeleton width={150} />
              <Skeleton width={80} />
              <Skeleton width={30} />
            </section>
          </section>
      </SkeletonTheme>
    </>
  );
};

export default PaymentSelectionSkeleton;
