import { ProductEntity } from '../../types/type';
import icons from '../../assets/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
  product: ProductEntity;
  quantity: number;
  isVisible: boolean;
  closeModal: () => void;
}

const AddedToCartModal = (props: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {props.isVisible ? (
        <div className="h-20 md:h-auto">
          <div className="justify-center text-left items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[3] outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-4 mt-10 md:mt-0 max-w-3xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-b border-blueGray-200 rounded-t">
                  <span className="font-thin">1 Item added to cart</span>
                  <button
                    className="p-1 ml-auto  border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.closeModal()}>
                    <span className="h-3 w-6 text-2xl block outline-none focus:outline-none">
                      <img src={icons.close} alt="" />
                    </span>
                  </button>
                </div>
                {/*body*/}
                <section className="mx-6  mt-4 grid grid-cols-3 gap-4">
                  <section className="grid border h-32 place-items-center object-center overflow-hidden object-cover lg:h-auto p-4">
                    <img src={props.product.productImageUrl} alt="" />
                  </section>
                  <section className="col-span-2 border-b">
                    <p className="text-text-title">
                      {props.product.productName}
                    </p>
                    <p className="mt-3 font-thin">
                      Quantity: {props.quantity}
                    </p>
                    <p className="mt-3 font-thin">
                      Cart Subtotal: Rs{' '}
                      {props.quantity * props.product.afterDiscountPrice}
                    </p>
                  </section>
                </section>
                {/*footer*/}
                <div className="flex flex-row-reverse my-4 mr-2  border-solid border-blueGray-200 rounded-t">
                  {/* Need to check and update later */}
                  {/* <button
                    className="p-4 ml-3 leading-none border bg-primary border-primary text-white hover:bg-primary-dark outline-none focus:outline-none"
                    onClick={() => {
                      props.closeModal();
                      navigate('/cart');
                    }}>
                    Proceed To Cart
                  </button> */}
                  <button
                    className="p-4 ml-3   float-right leading-none border border-primary hover:bg-text-title text-primary outline-none focus:outline-none"
                    onClick={() => {
                      props.closeModal();
                      navigate('/products');
                    }}>
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[2] bg-black"></div>
        </div>
      ) : null}
    </>
  );
};

export default AddedToCartModal;
