import { Link, useNavigate } from 'react-router-dom';
import {
  setSelectedCategory,
  setWholesale
} from '../../redux/actions/productListing';
import { useDispatch, useSelector } from 'react-redux';

import { FooterProps } from '../../types/type';
import { RootState } from '../../redux/reducers';

const Footer = (props: FooterProps) => {
  const companyInfo = useSelector((state: RootState) => state.companyInfo);
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <footer className="bg-text-title relative">
      <section className="px-5 lg:px-20">
        <section className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 pb-6 w-full">
          <section className="my-6">
            <section className="grid mt-6">
              <h1 className="text-white text-sm lg:text-base">Contact Us</h1>
              <hr className="my-3 w-20 px-5" />
              <ul className="grid grid-cols-1">
                <li className="text-text-muted flex flex-row space-x-2 my-2 text-sm cursor-default align-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#24A4B7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mail">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  <a className="block"
                  // href={`mailto: ${props.customerEmail}`}
                  >
                    {props.customerEmail}
                  </a>
                </li>
                <a
                  // href={`tel:${props.customerContact}`}
                  className="text-text-muted flex flex-row space-x-2 my-2 text-sm cursor-default">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#24A4B7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-phone">
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                  </svg>
                  <span>{props.customerContact}</span>
                </a>
                <li className="text-text-muted flex flex-row space-x-2 my-2 text-sm cursor-default">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#24A4B7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-map-pin">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                  <span>{props.customerAddress}</span>
                </li>
              </ul>
            </section>
          </section>
          <section className="my-6">
            <section className="grid  md:mt-6">
              <h1 className="text-white lg:text-md lg:text-3">
                Product Category
              </h1>
              <hr className="my-3 w-20 px-5" />
              <ul className="capitalize">
                {props.categories.map((cat, index) => (
                  <li
                    onClick={() => {
                      dispatch(setSelectedCategory(cat['categoryName']));
                      window.scrollTo(0, 0);
                      navigate('/products');
                    }}
                    key={index}
                    className="text-text-muted my-2 text-md cursor-pointer">
                    {cat.categoryName?.toLowerCase()}
                  </li>
                ))}
              </ul>
            </section>
          </section>
          <section className="my-6">
            <section className="grid  md:mt-6">
              <h1 className="text-white text-sm lg:text-base">
                Website Policies
              </h1>
              <hr className="my-3 w-20 px-5 " />
              <ul className="">
                <li className="text-text-muted my-2 text-sm">
                  <Link
                    onClick={() => {
                      if (isWholesale) {
                        dispatch(setWholesale(false));
                      }
                    }}
                    to="/privacy-policy">
                    Privacy Policies
                  </Link>
                </li>
                <li className="text-text-muted my-2 text-sm">
                  <Link
                    onClick={() => {
                      if (isWholesale) {
                        dispatch(setWholesale(false));
                      }
                    }}
                    to="/shipping-policy">
                    Shipping Policies
                  </Link>
                </li>
                <li className="text-text-muted my-2 text-sm">
                  <Link
                    onClick={() => {
                      if (isWholesale) {
                        dispatch(setWholesale(false));
                      }
                    }}
                    to="/terms-conditions">
                    Terms of Service
                  </Link>
                </li>
                <li className="text-text-muted my-2 text-sm">
                  <Link
                    onClick={() => {
                      if (isWholesale) {
                        dispatch(setWholesale(false));
                      }
                    }}
                    to="/refund-policy">
                    Return and Refund Policy
                  </Link>
                </li>
                {/* <li className="text-text-muted my-2 text-md">Feedback</li> */}
              </ul>
            </section>
          </section>
        </section>
      </section>
      <hr className="px-5 w-auto" />
      <section className=" flex flex-row justify-between px-5 lg:px-20 mt-2 pb-5">
        <p className="text-sm text-text-muted">
          Copyrights {new Date().getFullYear()}. All Rights Reserved
        </p>
        <section className="flex flex-row justify-evenly cursor-pointer">
          {(companyInfo?.linkedInLink != null && companyInfo?.linkedInLink != '') &&
            <a href={companyInfo?.linkedInLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-linkedin mx-2">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          }
          {(companyInfo.facebookLink != null && companyInfo?.facebookLink != '') &&
            <a href={companyInfo?.facebookLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-facebook mx-2">
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
            </a>
          }
          {(companyInfo?.twitterLink != null && companyInfo?.twitterLink != '') &&
            <a href={companyInfo?.twitterLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-twitter mx-2">
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
              </svg>
            </a>
          }
          {(companyInfo?.instagramLink != null && companyInfo?.instagramLink != '') &&
            <a href={companyInfo?.instagramLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-instagram mx-2">
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
            </a>
          }
        </section>
      </section>
    </footer>
  );
};

export default Footer;
