import { REVERT_CONFIG, SET_LOADED_CONFIG } from "../actionTypes"

/**
 * setConfig - action creator to create an action
 * @param payload - loaded configuration 
 * @returns action which sets config to be dispatched
 */
export const setConfig = (payload:any) => {
    return {
        type:SET_LOADED_CONFIG ,
        payload:payload 
    }
}

/**
 * revertConfig - action creator to create an action
 * @returns action which reverts config
 */
export const revertConfig = () => {
    return {
        type:REVERT_CONFIG
    }
}