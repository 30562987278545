import { OrderHistoryType } from '../../types/type';

interface Props {
  order: OrderHistoryType;
  close: () => void;
}

const OrderDetails = ({ order, close }: Props) => {
  return (
    <section className="border border-opacity-5 mx-2 md:ml-3 px-5 md:px-10 pt-5 bg-bg-primary pb-8">
      <article className="flex flex-row-reverse">
        <section onClick={() => close()} className="cursor-pointer">
          <svg
            id="icon_navigation_close_24px"
            data-name="icon/navigation/close_24px"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <rect id="Boundary" width="24" height="24" fill="none" />
            <path
              id="_Color"
              data-name=" ↳Color"
              d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
              transform="translate(5 5)"
            />
          </svg>
        </section>
      </article>
      {order.orderNo ? (
        <span className="text-base md:text-lg font-bold">
          Order no: {order.orderNo}
        </span>
      ) : (
        <span className="text-base md:text-lg font-bold">
          Order ID: {order.orderId}
        </span>
      )}
      <br />
      <aside className="mt-1 font-bold">
        Total Item(s): {order.productsList.length}
      </aside>
      <section className="mt-3">
        {order.productsList.map((product, index) => (
          <section
            className="flex flex-row place-items-center justify-between my-2"
            key={index}>
            <section className="flex flex-row space-x-5 h-16">
              <img src={product.productImageUrl} alt="" />
              <span className="text-sm md:text-base grid place-items-center">
                {product.productName} X {product.quantity}
              </span>
            </section>
            <section>
              <span className="text-sm md:text-base font-bold grid place-items-center">
                &#8377;{product.afterDiscountPrice}
              </span>
            </section>
          </section>
        ))}
      </section>
      <section className="mt-3 ">
        <span className="font-bold">Bill Details</span>
        <section className="flex flex-row place-items-center justify-between mt-3 mb-4">
          <section className="flex flex-row space-x-5">
            <span className="grid place-items-center">Total MRP</span>
          </section>
          <section>
            <span className="font-bold grid place-items-center">
              &#8377;{order.totalMRPPrice}
              {/* &#8377;{order.totalPrice} */}
            </span>
          </section>
        </section>
        {/*Comment code for not need to showing selling Price*/}
        {/* <section className="flex flex-row place-items-center justify-between mt-3 mb-4">
          <section className="flex flex-row space-x-5">
            <span className="grid place-items-center">Selling Price</span>
          </section>
          <section>
            <span className="font-bold grid place-items-center"> */}
              {/* &#8377;{order.totalMRPPrice} */}
              {/* &#8377;{order.totalPrice}
            </span>
          </section>
        </section> */}
        <section>
          <section className="flex flex-row place-items-center justify-between mb-4">
            <section className="flex flex-row space-x-5">
              <span className="grid place-items-center">
                Discount Coupon Applied
              </span>
            </section>
            <span className="font-bold grid place-items-center">
              {order.couponCode ? order.couponCode : 'NA'}
            </span>
          </section>
        </section>
        <section className="flex flex-row place-items-center justify-between mb-4">
          <section className="flex flex-row space-x-5">
            <span className="grid place-items-center">
              Your Savings/ Discount
            </span>
          </section>
          <section>
            <span className="font-bold grid place-items-center">
              - &#8377;{order.discountAmount}
            </span>
          </section>
        </section>
        <section className="flex flex-row place-items-center justify-between mb-4">
          <section className="flex flex-row space-x-5">
            <span className="grid place-items-center">Delivery Charges</span>
          </section>
          <section>
            <span className="font-bold grid place-items-center">
              &#8377;{order.deliveryCharges || 0}
            </span>
          </section>
        </section>
        <hr />
        <section className="flex flex-row place-items-center justify-between mt-2 mb-4">
          <section className="flex flex-row space-x-5">
            <span className="grid place-items-center">Total Pay</span>
          </section>
          <section>
            <span className="font-bold grid place-items-center">
              {/* &#8377;{order.totalMRPPrice - order.discountAmount} */}
              &#8377;
              {order.totalPrice}
            </span>
          </section>
        </section>
      </section>
      <br />
      <section>
        <span className="font-bold">Name</span>
        <p className="mt-2">{order.userName}</p>
      </section>
      <section className="mt-4">
        <span className="font-bold">Delivery Address</span>
        <p className="mt-2">{order.shippingAddress}</p>
      </section>
      <section className="mt-4">
        <span className="font-bold">Payment Type</span>
        <p className="mt-2">{order.paymentMode}</p>
      </section>
    </section>
  );
};

export default OrderDetails;
