import { AddToCartPayload, LoginModalProps } from '../../../../types/type';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  getCartCountAction,
  getUserInfoAction,
  getWishListCountAction
} from '../../../../redux/actions/actionCreators';
import { loginUser, sendOTP } from '../../../../api/Auth';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal';
import OTPInput from '../../Input/OTPInput';
import { RootState } from '../../../../redux/reducers';
import { addToCart } from '../../../../api/CartApi';
import { closeLoginModal } from '../../../../redux/actions/modals';
import config from '../../../../config.json';
import { getLoginCart } from '../../../../redux/actions/loginCart';
import jwt_decode from 'jwt-decode';
import { login } from '../../../../redux/actions/auth';
import { resetReduxCart } from '../../../../redux/actions/cart';
import { toast } from 'react-toastify';

const OTPComponent = ({
  setRoute,
  phoneNumber,
  setPhoneNumber
}: LoginModalProps) => {
  /**
   * @description - States for signup
   */
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [otpTimer, setOtpTimer] = useState(config.loginConfig.otpTimer);
  const [otpTry, setOtpTry] = useState(config.loginConfig.otpTry);
  const [otpSend, setOtpSend] = useState(config.loginConfig.otpSend);
  const userCart = useSelector((state: RootState) => state.cart);

  /**
   * @description - Hooks to use in component
   */
  const dispatch = useDispatch();
  let navigate = useNavigate();

  /**
   * @description - Function to verify OTP
   * @returns {void} - Verifies the OTP and dispatches login action to set the token or redirects to signup if not registered respectively
   */
  const verifyOTP = async () => {
    setIsError(false);
    setIsLoading(true);
    if (otp.length === 6) {
      let res = await loginUser(otp, '91' + phoneNumber);
      if (res.ok) {
        let token = await res.json();
        var decoded: any = jwt_decode(token['accessToken']);
        if (decoded.userregistered) {
          dispatch(login(token['accessToken']));
          const decodedInfo: any = jwt_decode(token['accessToken']);
          dispatch(getUserInfoAction(decodedInfo?.id));
          toast.success('Login successful!');
          dispatch(closeLoginModal());
          if (userCart?.length) {
            userCart.forEach(async (payload: AddToCartPayload, index) => {
              addToCart(payload.product, payload.quantity, payload.extra).then(
                (res) => {
                  if (index === userCart?.length - 1) {
                    dispatch(getCartCountAction());
                    dispatch(getWishListCountAction());
                    dispatch(getLoginCart());
                  }
                }
              );
            });
            dispatch(resetReduxCart());
          } else {
            dispatch(getCartCountAction());
            dispatch(getWishListCountAction());
            dispatch(getLoginCart());
          }
          navigate('/');
          setIsLoading(false);
          return;
        } else {
          dispatch(login(token['accessToken']));
          toast.info('Please complete your signup to continue!');
          setRoute('signup');
          setIsLoading(false);
          return;
        }
      } else {
        let err = await res.json();
        toast.error(err['message'].split('_').join(' '));
      }
      setIsLoading(false);
    }
  };

  const runTimer = () => {
    let timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    if (otpTimer <= 0) return clearTimeout(timer);
  };

  useEffect(() => {
    let timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    if (otpTimer <= 0) return clearTimeout(timer);
  });

  useEffect(() => {
    if (otp.length === 6) {
      verifyOTP();
      setOtpTry(otpTry - 1);
    }
  }, [otp]);

  return (
    <Modal>
      <main className="z-[4] grid place-items-center h-full fixed w-full bg-gray-800 bg-opacity-50 mx-auto">
        <section className="relative h-104">
          <div className="absolute top-6 right-6">
            <button
              className=""
              type="button"
              onClick={() => {
                dispatch(closeLoginModal());
              }}>
              <span className="h-3 w-6">
                <svg
                  className="fill-text-title"
                  id="icon_navigation_close_24px"
                  data-name="icon/navigation/close_24px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <rect id="Boundary" width="24" height="24" fill="none" />
                  <path
                    id="_Color"
                    data-name=" ↳Color"
                    d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                    transform="translate(5 5)"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className="absolute top-4 left-4">
            <button
              className="py-1 px-2 rounded"
              type="button"
              onClick={() => {
                setRoute('login');
              }}>
              &larr;
            </button>
          </div>

          <section className="bg-white shadow-lg h-104 max-w-md w-full p-10">
            <form
              action=""
              className="grid auto-rows-auto grid-cols-1 gap-4 mt-4">
              <div className="container mx-auto">
                <div className="max-w-sm mx-auto md:max-w-lg">
                  <div className="w-full">
                    <div className="bg-white py-3 rounded text-center">
                      <h1 className="text-2xl font-bold">OTP Verification</h1>
                      {otpTry <= 0 && (
                        <span className="text-red-500 mt-5">
                          Please Login Again
                        </span>
                      )}
                      <div className="flex flex-col my-8">
                        <span>Enter the OTP you received at</span>
                        <span className="font-bold">{`+91******${phoneNumber.substring(
                          6
                        )}`}</span>
                      </div>
                      <OTPInput
                        disabled={otpTry === 0 ? true : false}
                        autoFocus
                        isNumberInput
                        length={6}
                        className="space-x-1 md:space-x-3"
                        inputClassName="appearance-none focus:border-primary focus:outline-none w-10 h-10 md:h-12 md:w-12 text-center text-2xl border rounded-sm"
                        onChangeOTP={(otp) => {
                          setOtp(otp);
                        }}
                      />
                      {isError && (
                        <small className="text-center w-full block mt-2 text-red-500 font-poppins-medium">
                          Invalid OTP!
                        </small>
                      )}
                      <div className="flex justify-end items-center mt-7">
                        <span>
                          {otpTimer < 10
                            ? `00:0${otpTimer}`
                            : `00:${otpTimer}`}
                        </span>
                        <button
                          disabled={otpTimer === 0 ? false : true}
                          type="button"
                          className="ml-5 border border-primary text-primary px-4 text-sm py-2 text-center  cursor-pointer hover:text-white hover:bg-primary transition-all rounded-full disabled:bg-gray-400 disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none"
                          onClick={async () => {
                            if (otpSend > 0) {
                              runTimer();
                              setOtpTimer(config.loginConfig.otpTimer);
                              setOtpSend(otpSend - 1);
                              let otpres = await sendOTP('91' + phoneNumber);
                              if (otpres['status'] === 'OK') {
                                toast.success(otpres['message']);
                                setRoute('otp');
                              } else {
                                toast.error(otpres['message']);
                              }
                            } else {
                              toast.error(
                                'Cannot send OTP more than 3 times! Login Again'
                              );
                            }
                          }}>
                          Resend OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={() => {
                  verifyOTP();
                }}
                disabled={otpTry === 0 ? true : false}
                className="bg-primary text-white px-6 py-2 hover:bg-text-title transition-all disabled:bg-gray-400 disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none">
                {isLoading ? (
                  <div
                    style={{ borderTopColor: 'transparent' }}
                    className="border-t-transparent border-solid animate-spin rounded-full border-2 h-6 w-6 mx-auto"></div>
                ) : (
                  'Login'
                )}
              </button>
            </form>
            <p className="text-center text-xs mt-2 w-2/3 mx-auto">
              By continuing, you agree to our{' '}
              <Link
                to="/terms-conditions"
                onClick={() => {
                  dispatch(closeLoginModal());
                }}
                className="text-primary hover:text-primary-dark">
                Terms & Conditions
              </Link>{' '}
              &{' '}
              <Link
                to="/privacy-policy"
                onClick={() => {
                  dispatch(closeLoginModal());
                }}
                className="text-primary hover:text-primary-dark">
                Privacy Policy
              </Link>
            </p>
          </section>
        </section>
      </main>
    </Modal>
  );
};

export default OTPComponent;
