import { api, company_no } from '../api';
import axios from 'axios';

/**
 * @description - async action creator to search products
 * @param {number} companyNo - company number
 * @param {string} browserTimeZone - browser time zone
 * @param {string} orderBy - order by
 * @param {string} direction - direction
 * @param {number} size - size
 * @param {number} page - page
 * @param {string} [searchString] - search string
 * @param {string} [category] - category
 * @param {string} [subCategory] - sub category
 * @param {string} [brand] - brand
 * @param {number} [maxPrice] - max price
 * @param {number} [minPrice] - min price
 * @returns product list
 */
export const searchProducts = async (
  companyNo: number,
  browserTimeZone: string,
  orderBy: string,
  direction: string,
  size: number,
  page: number,
  isWholesale: boolean | undefined,
  searchString?: string,
  category?: string,
  subCategory?: string,
  brand?: string,
  maxPrice?: number,
  minPrice?: number
) => {
  const result = await axios.get(`${api}/product/search/v1?companyNo=${companyNo}&browserTimeZone=${browserTimeZone}&orderBy=${orderBy}&direction=${direction}&page=${page}&size=${size !== 0? size : ''}${
    searchString && searchString.length && `&searchString=${searchString}`
  }${
    category && category.length && category !== 'All'
      ? `&category=${category}`
      : ''
  }${
    subCategory && subCategory.length && subCategory !== 'All'
      ? `&subCategory=${subCategory}`
      : ''
  }${brand && brand.length && brand !== 'All' ? `&brand=${brand}` : ''}${
    maxPrice ? `&maxPrice=${maxPrice}` : ''
  }${
    minPrice ? `&minPrice=${minPrice}` : ''
  }&wholesaleProduct=${isWholesale}`,
  {
    headers: {
            Accept: 'application/json,text/plain',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }).then((res)=>{
          return res.data
        })
        .catch((err)=>{
          throw err
        })
        return result;
};

/**
 *
 * @returns - max price of all products
 */
export const getMaxPrice = async () => {
  const result = axios.get( `${api}/product/max/price?companyNo=${company_no}`,
    {
      headers: {
            Accept: 'application/json,text/plain',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
    })
    .then((res)=>{
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/**
 * fetchProducts - api to fetch products according to various paramaters
 * @param {number} companyNo - company no
 * @param {string} category - category of products
 * @param {string} subcategory - subcategory of products
 * @param {string} page - page number
 * @param {string} size - number of records to be returned
 * @param {string} model - name of model
 * @param {string} brand - brand name
 * @param {string} orderBy - parameter of sorting
 * @param {string} order_direction - direction of sorting
 * @returns json response of products
 */
export const fetchProducts = async (
  companyNo: number,
  category?: string,
  subcategory?: string,
  page?: number,
  size?: number,
  model?: string,
  brand?: string,
  orderBy?: string,
  order_direction?: string
) => {
  const result = await axios.get(`${api}/product/category/subCategory?companyNo=${companyNo}&category=${
          category ? category : ''
        }&subCategory=${subcategory ? subcategory : ''}&page=${page || 0}&size=${
          size || 10
        }&model=${model || ''}&brand=${brand || ''}&orderBy=${
          orderBy || ''
        }&direction=${order_direction || ''}`,
        {
          headers: {
                  Accept: 'application/json,text/plain',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }}
                )
                .then((res)=>{
                  return res.data;
                })
                .catch((err) =>{
                  throw err
                })
                return result;
};
