import { LOGIN, LOGOUT, UPDATE_TOKEN } from '../actionTypes';

/**
 *
 * @param payload the token to store
 * @returns
 */
export const login = (payload: any) => ({
  type: LOGIN,
  payload: payload
});

/**
 *
 * @returns
 */
export const logout = () => ({
  type: LOGOUT
});

/**
 *
 * @param payload the token to update
 * @returns
 */
export const updateToken = (payload: any) => ({
  type: UPDATE_TOKEN,
  payload: payload
});
