import { AddToCartPayload, ProductEntity } from '../../types/type';
import { addToCart, addToWishlist } from '../../api/CartApi';
import {
  addToCartWithoutLogin,
  createAddToCartPayload
} from '../../redux/actions/cart';
import {
  getCartCountAction,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import {
  openLoginModal,
  openQuickViewModal,
  setQuickViewProduct
} from '../../redux/actions/modals';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { getLoginCart } from '../../redux/actions/loginCart';
import { toast } from 'react-toastify';
import { useState } from 'react';

interface Props {
  product?: ProductEntity | any;
  id?: string;
}

const ProductCardIcon = ({ product, id }: Props) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  /**setisModalOpen - function to toggle visibility of modal */
  const userCart = useSelector((state: RootState) => state.cart);
  const [quickLookClass, setQuickLookClass] = useState('');
  const [wishlistClass, setWishlistClass] = useState('');
  const [cartClass, setCartClass] = useState('');
  const dispatch = useDispatch();
  /**
   * wishlist - async function to call api for adding product to wishlist
   */
  const wishList = async () => {
    if (isLoggedIn) {
      if (product) {
        const data = await addToWishlist(
          id ? id : product.id ? product.id : ''
        );
        if (data) {
          toast.success('Item added to wishlist');
          dispatch(getWishListCountAction());
        }
      }
    } else {
      toast.error('Please login to add to wishlist');
      dispatch(openLoginModal());
    }
  };

  /**
   * cart - async function to call api for adding product to cart
   */
  const cart = async () => {
    if (isLoggedIn) {
      if (product) {
        const data = await addToCart(product.product_id || product.id);
        if (data) {
          toast.success('Item added to cart');
          dispatch(getCartCountAction());
          dispatch(getLoginCart());
        }
      }
    } else {
      if (userCart && userCart.length > 0) {
        if (
          !userCart.some(
            (payload: AddToCartPayload) =>
              payload.product === product.product_id ||
              payload.product === product.id
          )
        ) {
          dispatch(
            addToCartWithoutLogin(
              createAddToCartPayload(
                product.product_id || product.id,
                1,
                [],
                product.productName || product.product_name,
                product.compressProductImageUrl || product.product_url,
                product.afterDiscountPrice
              )
            )
          );
          toast.success('Item added to cart');
        } else {
          toast.info('Item already exists in your cart');
        }
      } else {
        dispatch(
          addToCartWithoutLogin(
            createAddToCartPayload(
              product.product_id || product.id,
              1,
              [],
              product.productName || product.product_name,
              product.compressProductImageUrl || product.product_url,
              product.afterDiscountPrice
            )
          )
        );
        toast.success('Item added to cart');
      }
    }
  };

  return (
    <>
      <article className="flex space-x-2 justify-center opacity-100 lg:opacity-0 lg:group-hover:-translate-y-5 lg:group-hover:opacity-100 duration-300 transition-all ease-in-out absolute bottom-0 group">
        <article
          className="w-8 h-8 overflow-hidden bg-white grid place-items-center hover:bg-primary cursor-pointer transition-colors duration-150"
          onMouseEnter={() => {
            setWishlistClass('stroke-white');
          }}
          onMouseLeave={() => {
            setWishlistClass('stroke-text-title');
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2C2C2C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => wishList()}
            className={`cursor-pointer h-6 w-6 ${wishlistClass}`}>
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </article>
        <article
          onMouseEnter={() => {
            setCartClass('stroke-white');
          }}
          onMouseLeave={() => {
            setCartClass('stroke-text-title');
          }}
          className="w-8 h-8 bg-white grid place-items-center hover:bg-primary cursor-pointer transition-colors duration-150">
          <svg
            onClick={() => cart()}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2C2C2C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`cursor-pointer h-6 w-6 ${cartClass}`}>
            <circle cx="9" cy="21" r="1"></circle>
            <circle cx="20" cy="21" r="1"></circle>
            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
          </svg>
        </article>
        <article
          className="w-8 h-8 overflow-hidden hidden bg-white md:grid place-items-center hover:bg-primary cursor-pointer transition-colors duration-150"
          onMouseLeave={() => {
            setQuickLookClass('stroke-text-title');
          }}
          onMouseEnter={() => {
            setQuickLookClass('stroke-white');
          }}
          onClick={() => {
            dispatch(setQuickViewProduct(product));
            dispatch(openQuickViewModal());
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2C2C2C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-zoom-in">
            <circle
              cx="11"
              cy="11"
              r="8"
              className={`${quickLookClass}`}></circle>
            <line
              x1="21"
              y1="21"
              x2="16.65"
              y2="16.65"
              className={`${quickLookClass}`}></line>
            <line
              x1="11"
              y1="8"
              x2="11"
              y2="14"
              className={`${quickLookClass}`}></line>
            <line
              x1="8"
              y1="11"
              x2="14"
              y2="11"
              className={`${quickLookClass}`}></line>
          </svg>
        </article>
      </article>
    </>
  );
};

export default ProductCardIcon;
