import LoginComponent from './LoginComponent';
import Modal from '../Modal';
import OTPComponent from './OTPComponent';
import SignupComponent from './SignupComponent';
import { useState } from 'react';

const LoginModal = () => {
  /**
   * @description - States for loginModal
   * route state is used to manage internal routing
   */
  const [route, setRoute] = useState('login');
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <Modal>
      {(() => {
        switch (route) {
          case 'login':
            return (
              <LoginComponent
                setRoute={setRoute}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            );
          case 'otp':
            return (
              <OTPComponent
                setRoute={setRoute}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            );
          case 'signup':
            return (
              <SignupComponent
                setRoute={setRoute}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            );
          default:
            return (
              <LoginComponent
                setRoute={setRoute}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            );
        }
      })()}
    </Modal>
  );
};

export default LoginModal;
