import { useEffect, useState } from 'react';

import { SimilarProductsCardInterface } from '../../types/type';
import defaultImage from '../../assets/images/default-image.png';
import { useNavigate } from 'react-router-dom';

const SimilarProductCard = ({ product }: SimilarProductsCardInterface) => {
  const navigate = useNavigate();
  const [displayImage, setdisplayImage] = useState('');
  useEffect(() => {
    setdisplayImage(product.product_url);
  }, []);
  return (
    <section className={`mx-4 md:mx-10 w-60 group flex flex-col relative`}>
      {product?.discount > 0 && (
        <p className="absolute top-0 left-0 text-white px-3 text-center py-1 z-[1] bg-primary text-xs">
          {Math.floor(product?.discount)}% OFF
        </p>
      )}
      <article className="border border-text-muted/50 bg-white grid place-items-center overflow-hidden  object-containt relative cursor-pointer w-full h-44 md:h-60  aspect-square hover:transition-all hover:ease-in-out">
        <img
          onClick={(e) => {
            window.scrollTo(0, 0);
            navigate(`/products/${product?.product_id}`);
          }}
          onMouseEnter={() => {
            if (product.subImageUrl[0]) {
              setdisplayImage(product.subImageUrl[0]);
            }
          }}
          onMouseLeave={() => {
            if (displayImage !== product.product_url) {
              setdisplayImage(product.product_url);
            }
          }}
          src={displayImage}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          className="cursor-pointer object-contain"
          alt={product?.product_name}
        />
        {/* <ProductCardIcon product={product} /> */}
      </article>
      <article className="mt-6 text-left">
        <p
          onClick={(e) => {
            navigate(`/products/${product?.product_id}`);
          }}
          className="text-text-muted text-sm md:text-sm font-light cursor-pointer capitalize line-clamp-2">
          {product?.product_name.toLowerCase()}
        </p>
        <article
          className="space-x-2 cursor-pointer"
          onClick={(e) => {
            navigate(`/products/${product?.product_name}`);
          }}>
          <span className="text-text-title font-bold">
            &#8377;{product?.discounted_price}
          </span>
          <del className="text-text-muted">&#8377;{product?.actual_price}</del>
        </article>
      </article>
    </section>
  );
};

export default SimilarProductCard;
