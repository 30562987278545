import { useEffect, useState } from 'react';

import { config as CONFIG } from '../../config/config';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { sendMessage } from '../../api/Auth/index';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AnyReactComponent = (props: any) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="red"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-map-pin">
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
      <circle cx="12" cy="10" r="3"></circle>
    </svg>
  </div>
);
const googleMapsConfiguration = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 10,
  key: CONFIG.GOOGLE_MAPS_API_KEY
};

const ContactUs = () => {
  const companyInfo = useSelector((state: RootState) => state.companyInfo);
  const [mapsConfig, setMapsConfig] = useState(googleMapsConfiguration);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [name , setName] = useState('');
  const [email ,setEmail] = useState('');
  const [message , setMessage] = useState('');
  const [nameError , setNameError] = useState(false);
  const [emailError , setEmailError] = useState(false);
  const [msgError , setMsgError] = useState(false);

  var isNameValidFlag = false
  var isEmailValidFlag = false
  const navigate = useNavigate()
  const nameFormat = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/


  useEffect(() => {
    Geocode.setApiKey(CONFIG.GOOGLE_MAPS_API_KEY!);
    const getLongLat = async () => {
      let response = await Geocode.fromAddress(companyInfo.address);
      const { lat, lng } = response.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
    };
    getLongLat();
  }, [companyInfo]);

  useEffect(() => {
    setMapsConfig((mapsConfig) => ({
      ...mapsConfig,
      center: {
        lat: latitude,
        lng: longitude
      }
    }));
  }, [latitude, longitude]);

  const SubmitMessage = async () =>{
    validateForm()
    if(isNameValidFlag && isEmailValidFlag && message)
    {
        let res = await sendMessage(
          name,
          email,
          message
        )
        toast.success('Message send successfully!');
        navigate('/');
    }
  }

  const validateForm = () =>{
    if (name && !nameFormat.test(name)){
      setNameError(false)
      isNameValidFlag = true
    }
    else{
      setNameError(true)
    }
    if(email && emailFormat.test(email)){
      setEmailError(false)
      isEmailValidFlag=true
    }
    else{
      setEmailError(true)
    }
    if(message){
      setMsgError(false)
    }
    else
    {
      setMsgError(true)
    }
  }
  return (
    <section className="p-10 grid place-items-center ">
      <div className="h-300px lg:h-[600px] w-full">
        <GoogleMapReact
          draggable
          shouldUnregisterMapOnUnmount
          zoom={15}
          bootstrapURLKeys={{ key: mapsConfig.key! }}
          center={mapsConfig.center}>
          <AnyReactComponent
            lat={mapsConfig.center.lat}
            lng={mapsConfig.center.lng}
          />
        </GoogleMapReact>
      </div>
      <section className="p-10 shadow-2xl bg-white max-w-md relative lg:-top-108 lg:left-96">
        <h1 className="capitalize text-3xl text-center text-text-title">
          write to us!
        </h1>
        <form action="" className="grid auto-rows-auto grid-cols-1 gap-4 mt-4"
          onKeyPress={(e)=>{
            if(e.key === 'Enter' || e.key === 'Tab')
            {
              e.preventDefault();
              validateForm()
            }
          }}
        >
          <div className="relative">
            <input
              className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors"
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e)=>{setName(e.target.value)}}
              id="name"
            />
            <label
              className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
              htmlFor="name">
              Your Name
            </label>
            <span hidden={!nameError} className="text-xs text-red-600">
                  Please enter a valid Name
                </span>
          </div>
          <div className="relative">
            <input
              className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors"
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
              id="email"
            />
            <label
              className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
              htmlFor="email">
              Your Email
            </label>
            <span hidden={!emailError} className="text-xs text-red-600">
                  Please enter a valid Email
                </span>
          </div>
          <div className="relative">
            <textarea
              className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors"
              name=""
              id="message"
              cols={30}
              rows={5}
              value={message}
              onChange={(e)=>{setMessage(e.target.value)}}
              placeholder="Message"></textarea>
            <label
              className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
              htmlFor="message">
              Message
            </label>
            <span hidden={!msgError} className="text-xs text-red-600">
                  Please enter a Message.
                </span>
          </div>
          <button
            type="button"
            onClick={SubmitMessage}
            className="bg-primary text-white px-6 py-2 hover:bg-text-title">
            Submit
          </button>
        </form>
      </section>
    </section>
  );
};

export default ContactUs;
