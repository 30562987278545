import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

interface Props {
  totalRecords: number;
  productCategory?: string;
}

const ProductCategory = ({ productCategory }: Props) => {
  const productListingConfig = useSelector(
    (state: RootState) => state.productListingConfig
  );
  return (
    <article className="capitalize w-full text-right lg:text-center">
      <p className="text-primary-darker hidden lg:block text-md flex justify-end lg:text-lg my-auto"
      >
        {productCategory
          ? productCategory
          : productListingConfig?.selectedCategory
          ? productListingConfig?.selectedCategory
          : 'All'}
      </p>
    </article>
  );
};

export default ProductCategory;
