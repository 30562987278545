import {
  RESET_COUNTS,
  SET_CART_COUNT,
  SET_WISHLIST_COUNT
} from '../actions/actionTypes';

import { Action } from '../../types/type';

const initialState = {
  wishlistCount: [],
  cartCount: {
    CartQuantity: 0,
    ProductQuantity: 0
  }
};

/**
 * countReducer - reducer to handle count login
 * @param {any} state - initialState
 * @param {Action} action - dispatched action
 * @returns modified state
 */
const countReducer = (state = initialState, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_CART_COUNT: {
        if (action.payload) {
          return { ...state, cartCount: action.payload };
        } else {
          return state;
        }
      }
      case SET_WISHLIST_COUNT: {
        if (action.payload) {
          return { ...state, wishlistCount: action.payload };
        } else {
          return state;
        }
      }
      case RESET_COUNTS: {
        return { ...initialState };
      }
      default: {
        return state;
      }
    }
  }
};

export default countReducer;
