import {
  CLOSE_AGE_RESTRICTION_MODAL,
  CLOSE_LOGIN_MODAL,
  CLOSE_QUICK_VIEW_MODAL,
  OPEN_AGE_RESTRICTION_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_QUICK_VIEW_MODAL
} from '../actions/actionTypes';

import { Action } from '../../types/type';

const inititalState = {
  isLoginModalOpen: false,
  isAgeRestrictionModalOpen: false,
  isQuickViewModalOpen: false
};

const modalReducer = (state = inititalState, { type }: Action) => {
  if (typeof type !== undefined) {
    switch (type) {
      case OPEN_LOGIN_MODAL:
        return {
          isLoginModalOpen: true,
          isAgeRestrictionModalOpen: false,
          isQuickViewModalOpen: false
        };
      case CLOSE_LOGIN_MODAL:
        return {
          isLoginModalOpen: false,
          isAgeRestrictionModalOpen: false,
          isQuickViewModalOpen: false
        };
      case OPEN_AGE_RESTRICTION_MODAL:
        return {
          isLoginModalOpen: false,
          isAgeRestrictionModalOpen: true,
          isQuickViewModalOpen: false
        };
      case CLOSE_AGE_RESTRICTION_MODAL:
        return {
          isLoginModalOpen: false,
          isAgeRestrictionModalOpen: false,
          isQuickViewModalOpen: false
        };
      case OPEN_QUICK_VIEW_MODAL:
        return {
          isLoginModalOpen: false,
          isAgeRestrictionModalOpen: false,
          isQuickViewModalOpen: true
        };
      case CLOSE_QUICK_VIEW_MODAL:
        return {
          isLoginModalOpen: false,
          isAgeRestrictionModalOpen: false,
          isQuickViewModalOpen: false
        };
      default:
        return state;
    }
  }
};

export default modalReducer;
