import { SET_BLOGS, SET_TESTIMONIALS, SET_TOP_VALUE } from '../actionTypes';

export const setTestimonials = (payload: any) => {
  return {
    type: SET_TESTIMONIALS,
    payload: payload
  };
};

export const setBlogs = (payload: any) => {
  return {
    type: SET_BLOGS,
    payload: payload
  };
};

export const setTopValue = (payload: number) => {
  return {
    type: SET_TOP_VALUE,
    payload: payload
  };
};
