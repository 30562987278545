import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { setWholesale } from '../../redux/actions/productListing';

interface Props {
  title: string;
  to: string;
}

const NavItemLink = ({ title, to }: Props) => {
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const dispatch = useDispatch();
  return (
    <NavLink
      className={({ isActive }) =>
        `px-1 mx-4 text-center text-base cursor-pointer capitalize hover:text-primary flex items-center ${
          isActive && !isWholesale
            ? 'border-b border-primary text-text-title'
            : 'text-text-muted'
        }`
      }
      onClick={() => {
        if (isWholesale) {
          dispatch(setWholesale(false));
        }
      }}
      to={to}>
      {title}
    </NavLink>
  );
};

export default NavItemLink;
