import axios from 'axios';
import { api, company_no } from '../api';

/**
 *
 * @param {string} otp - OTP
 * @param {string} mobileNumber - Mobile Number
 * @returns {Promise<any>} - Either returns the json response or throws an error
 */
export const loginUser = async (otp: string, mobileNumber: string) => {
  const response = await fetch(
    `${api}/users/OTP?OTP=${otp}&mobileNumber=${mobileNumber}&companyNo=${company_no}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
  );
  return response;
};

/**
 *
 * @param {string} mobileNumber - Mobile Number
 * @returns {Promise<any>} - Either returns the json response or throws an error
 */
export const sendOTP = async (mobileNumber: string) => {
  const result = await axios.get( `${api}/users/OTP?mobileNumber=${mobileNumber}&companyNo=${company_no}`)
      .then((res)=>{
          return res.data;
      }).catch((err)=>{
          throw err;
      })
      return result;
};

/**
 *
 * @param {string} email - Email
 * @param {string} firstName - First Name
 * @param {string} fullName - Full Name
 * @param {string} addressLine1 - Address Line 1
 * @param {string} addressLine2 - Address Line 2
 * @param {string} phoneNumber  - Phone Number
 * @param {number} latitude - Latitude from the address
 * @param {number} longitude - Longitude from the address
 * @returns {Promise<any>} - Either returns the json response or throws an error
 */
export const register = async (
  email: string,
  firstName: string,
  fullName: string,
  addressLine1: string,
  addressLine2: string,
  phoneNumber: string,
  latitude: number,
  longitude: number
) => {
  const result = await axios.put(`${api}/users/register`,
      {
        companyNo: company_no,
        email,
        firstName,
        fullName,
        mobileNumber: phoneNumber,
        addressLine1,
        addressLine2,
        latitude,
        longitude
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((res)=>{
        return res.data
      })
      .catch((err) =>{
        throw err
      })
      return result;
 };


//Send Customer message through Contact Us Functionality.
/**
 * @param {string} name -Full Name
 * @param {number} mobileNumber - Contact No
 * @param {string} email -Email
 * @param {string} subject - Subject of message
 * @param {string} message - Text Message
 * @param {string} country - Country Address
 * @param {string} state - State Address
 */
export const sendMessage =async (
  name: string,
  email: string,
  message: string,
  subject?: string,
  mobileNumber?: number,
  country?: string,
  state?: string

)=>{
  const requestBody = {
    name: name,
    mobileNumber: mobileNumber,
    email: email,
    subject: subject,
    message: message,
    country: country,
    state: state,
  };
 const result = await axios.post(`${api}/contact-us/merchant?companyNo=${company_no}`,
        {
          requestBody
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
       }
      ).then((res)=>{
        return res;
      })
      .catch((err)=>{
        throw err;
      })
      return result;
}
