import 'aos/dist/aos.css';

import { Route, Routes } from 'react-router-dom';
import { closeLoginModal, openAgeModal } from './redux/actions/modals';
import { useDispatch, useSelector } from 'react-redux';

import AOS from 'aos';
import AgeRestrictModal from './components/common/modals/AgeRestrictModal';
import AllBlogs from './screens/Blogs/AllBlogs';
import CartPage from './screens/Cart/CartPage';
import CheckoutPage from './screens/Checkout/CheckoutPage';
import ContactUs from './screens/ContactUs/ContactUs';
import Error404 from './screens/Error404';
import LandingPage from './screens/LandingPage/LandingPage';
import Layout from './components/common/Layout';
import LoginModal from './components/common/modals/LoginModal/LoginModal';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import ProductListing from './screens/ProductListing/ProductListing';
import ProductScreen from './screens/Product/ProductScreen';
import Profile from './screens/Profile/Profile';
import QuickViewModal from './components/QuickViewModal/QuickViewModal';
import RefundPolicy from './screens/RefundPolicy/RefundPolicy';
import { RootState } from './redux/reducers';
import ShippingPolicy from './screens/ShippingPolicy/ShippingPolicy';
import SingleBlog from './screens/Blogs/SingleBlog';
import TermsAndConditions from './screens/TermsAndConditions/TermsAndConditions';
import { ToastContainer } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import { logout } from './redux/actions/auth';
import { resetLoginCart } from './redux/actions/loginCart';
import { useEffect } from 'react';

const AllRoutes = () => {
  const modals = useSelector((state: RootState) => state.modals);
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    if (localStorage.getItem('isWebsiteRestricted') !== null) {
      dispatch(closeLoginModal());
    } else {
      dispatch(openAgeModal());
    }
    return () => {
      if (localStorage.getItem('accessToken')) {
        var decoded: any = jwt_decode(localStorage.getItem('accessToken')!);
        if (!decoded.userregistered) {
          dispatch(logout());
          dispatch(resetLoginCart());
        }
      }
    };
  }, []);
  return (
    <main className="font-poppins-regular">
      <ToastContainer
        role="alert"
        hideProgressBar
        theme = "light"
        newestOnTop
        position="bottom-right"
      />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<ProductListing />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/cart-checkout" element={<CheckoutPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/blog/:id" element={<SingleBlog />} />
          <Route path="/products/:id" element={<ProductScreen />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
      {modals?.isLoginModalOpen && <LoginModal />}
      {modals?.isAgeRestrictionModalOpen && <AgeRestrictModal />}
      {modals?.isQuickViewModalOpen && <QuickViewModal />}
      <div
        className={`h-full w-screen fixed top-0 left-0 cursor-not-allowed bg-gray-800 bg-opacity-50 z-[999] ${
          localStorage.getItem('isWebsiteRestricted') === 'true'
            ? 'block'
            : 'hidden'
        }`}></div>
    </main>
  );
};

export default AllRoutes;
