import BlogCard from '../../components/BlogsSection/BlogCard';
import CONFIG from '../../config.json';

const AllBlogs = () => {
  return (
    <section className="grid auto-rows-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-items-center gap-6 container">
      {CONFIG.blogs.map((blog) => {
        return <BlogCard key={blog.id} blog={blog} />;
      })}
    </section>
  );
};

export default AllBlogs;
