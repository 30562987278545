import { AddToCartPayload, LoginModalProps } from '../../../../types/type';
import {
  getCartCountAction,
  getUserInfoAction,
  getWishListCountAction
} from '../../../../redux/actions/actionCreators';
import { login, logout, updateToken } from '../../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';

import Geocode from 'react-geocode';
import Modal from '../Modal';
import { RootState } from '../../../../redux/reducers';
import { addToCart } from '../../../../api/CartApi';
import { closeLoginModal } from '../../../../redux/actions/modals';
import { config } from '../../../../config/config';
import { getLoginCart } from '../../../../redux/actions/loginCart';
import jwt_decode from 'jwt-decode';
import { register } from '../../../../api/Auth';
import { resetReduxCart } from '../../../../redux/actions/cart';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

/**
 * @description - Google Maps API key setup
 */
Geocode.setApiKey(config.GOOGLE_MAPS_API_KEY!);

const SignupComponent = ({
  setRoute,
  phoneNumber,
  setPhoneNumber
}: LoginModalProps) => {
  /**
   * @description - States for signup
   */
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [fnameError, setfnameError] = useState(false);
  const [lnameError, setlnameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userCart = useSelector((state: RootState) => state.cart);
  const nameFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const emailFormat = /\S+@\S+\.\S+/;
  /**
   * @description - Hooks to use in component
   */
  const dispatch = useDispatch();
  let navigate = useNavigate();

  /**
   *
   * @returns {void} - Signs up the user and dispatches login action to set the token
   */
  const registerUser = async () => {
    setIsLoading(true);
    let isValid = validateForm();
    const fullName = `${fname} ${lname}`;
    try {
      phoneNumber = '91' + phoneNumber;
      let res = await register(
        email,
        fname,
        fullName,
        addressLine1,
        addressLine2,
        phoneNumber,
        0,
        0
      );
      if (res.hasOwnProperty('jwt')) {
        dispatch(updateToken(res['jwt']));
        toast.success('Signup successful!');
        dispatch(closeLoginModal());
        const decodedInfo: any = jwt_decode(res['jwt']);
        dispatch(getUserInfoAction(decodedInfo?.id));
        dispatch(getCartCountAction());
        dispatch(getWishListCountAction());
        dispatch(getLoginCart());
        setIsLoading(false);
        if (userCart?.length) {
          userCart.forEach(async (payload: AddToCartPayload, index) => {
            addToCart(payload.product, payload.quantity, payload.extra).then(
              (res) => {
                if (index === userCart?.length - 1) {
                  dispatch(getCartCountAction());
                  dispatch(getWishListCountAction());
                  dispatch(resetReduxCart());
                  dispatch(getLoginCart());
                }
              }
            );
          });
        } else {
          dispatch(getCartCountAction());
          dispatch(getWishListCountAction());
          dispatch(getLoginCart());
        }
        navigate('/');
      } else {
        toast.error('User already exists!');
        setIsLoading(false);
      }
    } catch (err) {
      toast.error('Signup failed!');
      setIsLoading(false);
    }
  };

  /*   TODO: can be useful later
    useEffect(() => {
      Geocode.fromLatLng('18.500039', '73.801702').then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
        },
        (error) => {
          console.error(error);
        }
      );
      let response = await Geocode.fromAddress(
        addressLine1 + ' ' + addressLine2
      );
      const { lat, lng } = response.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
      console.log(lat, lng);
    }, [addressLine1, addressLine2]); 
  */
  const validateForm = () => {
    if (fname && nameFormat.test(fname)) {
      setfnameError(false);
    } else {
      setfnameError(true);
    }
    if (lname && nameFormat.test(lname)) {
      setlnameError(false);
    } else {
      setlnameError(true);
    }
    if (email && emailFormat.test(email)) {
      setemailError(false);
    } else {
      setemailError(true);
    }
  };

  return (
    <Modal>
      <main className="z-[4] grid place-items-center h-full fixed w-full bg-gray-800 bg-opacity-50 mx-auto">
        <section className="relative">
          <div className="absolute top-0 right-0 mt-4 mr-4">
            <button
              className="bg-transparent text-xs text-gray-500 hover:text-white font-semibold  py-1 px-2"
              type="button"
              onClick={() => {
                dispatch(logout());
                dispatch(closeLoginModal());
                toast.error('Signup failed! Please fill all the fields');
              }}>
              <svg
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="16px"
                height="24px">
                <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
              </svg>
            </button>
          </div>
          <div className="absolute top-0 left-0 mt-4 ml-4">
            <button
              className="bg-transparent text-sm text-gray-500 hover:text-white font-semibold hover:bg-gray-500 py-1 px-2 rounded"
              type="button"
              onClick={() => {
                dispatch(logout());
                toast.error('Signup failed! Please enter your number again');
                setRoute('login');
                setPhoneNumber('');
              }}>
              &larr;
            </button>
          </div>

          <section className="bg-white shadow-lg max-w-md w-full p-16">
            <h1 className="text-center text-2xl font-medium mt-7 mb-10">
              Complete your signup!
            </h1>
            <form
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  registerUser();
                }
              }}
              action=""
              className="grid auto-rows-auto grid-cols-1 gap-5 mt-4">
              <div className="relative">
                <input
                  className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors floating_label_input"
                  type="text"
                  placeholder="First Name"
                  id="fname"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
                <label
                  className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
                  htmlFor="First Name">
                  First Name
                </label>
                <span hidden={!fnameError} className="text-xs text-red-600">
                  Please enter a valid first name
                </span>
              </div>
              <div className="relative">
                <input
                  className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors floating_label_input"
                  type="text"
                  placeholder="Last Name"
                  id="lname"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
                <label
                  className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
                  htmlFor="Last Name">
                  Last Name
                </label>
                <span hidden={!lnameError} className="text-xs text-red-600">
                  Please enter a valid last name
                </span>
              </div>
              <div className="relative">
                <input
                  className="peer border px-2 py-3 w-full placeholder-transparent focus:outline-none focus:border-primary transition-colors floating_label_input"
                  type="email"
                  placeholder="Email Address"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  className="absolute left-2 -top-2.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 transition-all peer-focus:left-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm bg-white px-1"
                  htmlFor="email">
                  Email
                </label>
                <span hidden={!emailError} className="text-xs text-red-600">
                  Please enter a valid email address
                </span>
              </div>
              <button
                type="button"
                onClick={registerUser}
                disabled={isLoading || fnameError || lnameError || emailError}
                className="bg-primary text-white px-6 py-2 hover:bg-text-title mt-3 cursor-pointer">
                Signup
              </button>
            </form>
          </section>
        </section>
      </main>
    </Modal>
  );
};

export default SignupComponent;
