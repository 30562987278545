import {
  Address,
  CartItem,
  Order,
  PaymentOptions,
  PlaceOrderStatusReq,
  PlacedOrderResponse,
  PostAddress,
  ProductsList
} from '../../types/type';
import {
  Modal,
  MuiThemeProvider,
  Step,
  StepLabel,
  Stepper,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  addShippingAddress,
  createOrder,
  getAllShippingAddress,
  getPaymentOptions,
  orderStatus,
  placeOrder,
  updateShippingAddress,
  verifyPayment,
  getDeliveryChargesOnDistRadius
} from '../../api/CartCheckoutApi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { config as CONFIG } from '../../config/config';
import CartDetails from './CartDetails';
import ConfirmationCartDetails from './ConfirmationCartDetails';
import ConfirmationUserDetails from './ConfirmationUserDetails';
import { RootState } from '../../redux/reducers';
import { getCartCountAction } from '../../redux/actions/actionCreators';
import { getCartDataList } from '../../api/CartApi';
import { getCityAndStateFromPin } from '../../api/Profile';
import { getCompanyInformation } from '../../api/company';
import { getLoginCart } from '../../redux/actions/loginCart';
import icons from '../../assets/icons';
import { toast } from 'react-toastify';
import { company_no } from '../../api/api';
import PaymentSelectionSkeleton from './PaymentSelectionSkeleton';
import { is } from 'immer/dist/internal';

const steps = ['Shipping Info', 'Payment Selection', 'Confirmation'];

declare var Razorpay: any;

interface Location {
  pathname: string;
  key: string;
  search: string;
  hash: string;
  state: number | any;
}

export default function CheckoutPage() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
  const [minimumOrderValue, setMinimumOrderValue] = useState(0);
  const [couponPromoCode, setCouponPromoCode] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([] as Address[]);
  const [selectedShippingAddress, setSelectedAddress] = useState(
    {} as Address
  );
  const [cartData, setCartData] = useState([] as CartItem[]);
  const [placedOrderResponse, setPlacedOrderResponse] = useState(
    {} as PlacedOrderResponse
  );
  const [freeDeliveryOnPurchaseVal, setFreeDeliveryOnPurchaseVal] =
    useState(0);
  const [defaultDeliveryChargesVal, setDeliveryCharges] = useState(0);
  const dispatch = useDispatch();
  const location: Location = useLocation();
  // To get the minimum order , defaultDeliveryChargesVal ,freeDeliveryOnPurchase value from Admin Panel
  const getMinimumOrderValue = async () => {
    let {
      minimumOrderValue,
      defaultDeliveryChargesVal,
      freeDeliveryOnPurchase
    } = await getCompanyInformation(CONFIG.COMPANY_NO);
    setMinimumOrderValue(minimumOrderValue);
    setDeliveryCharges(defaultDeliveryChargesVal);
    setFreeDeliveryOnPurchaseVal(freeDeliveryOnPurchase);
  };

  useEffect(() => {
    getMinimumOrderValue();
  }, []);

  // Razorpay
  const [expectedOrder, setExpectedOrder] = useState('');
  const [orderID, setOrderID] = useState(0);
  const userState = useSelector((state: RootState) => state.user);
  const loginCart = useSelector((state: RootState) => state.loginCart);

  // success modal
  const [isOrderSuccessModal, setIsOrderSuccessModal] = useState(false);
  const successModalHandleOpen = () => {
    dispatch(getCartCountAction());
    dispatch(getLoginCart());
    setIsOrderSuccessModal(true);
    /** automatic navigation triggered after 2 seconds */
    setInterval(() => {
      successModalHandleClose();
    }, 2000);
  };
  const successModalHandleClose = () => {
    setIsOrderSuccessModal(false);
    setActiveStep(2);
  };

  // error modal
  const [isOrderErrorModal, setIsOrderErrorModal] = useState(false);
  const errorModalHandleOpen = () => setIsOrderErrorModal(true);
  const errorModalHandleClose = () => setIsOrderErrorModal(false);

  //loading
  const [isLoading, setisLoading] = useState(false);
  const [adressLoading, setAdressLoading] = useState(false);

  // Create address
  const [addAddress, setAddAddressValues] = useState<PostAddress>({
    addressLine1: '',
    addressLine2: '',
    addressType: 'Home',
    city: '',
    country: '',
    defaultAddress: false,
    fullName: '',
    id: null,
    landmark: '',
    latitude: 0,
    longitude: 0,
    mobileNumber: '',
    state: '',
    zipCode: ''
  });

  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions>({
    cashfreeSupported: null,
    codSupported: false,
    companyNo: 0,
    googlePaySupported: false,
    id: 0,
    paypalSupported: false,
    razorPaySupported: false,
    stripeSupported: false
  });

  const [currentAmountForCheckout, setCurrentAmountForCheckout] = useState(0);
  const [comment, setComment] = useState('');
  const [isPincodeError, setisPincodeError] = useState(false);
  //  This function use to navigate forward in steeper
  const handleNext = () => {
    if (
      activeStep === 0 &&
      addAddress.addressLine1 !== '' &&
      addAddress.zipCode !== '' &&
      !isEdit
    ) {
      addNewShippingAddress();
    } else {
      updateExistingShippingAddress();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  //  This function use to navigate back in steeper
  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // This function will edit shipping addresses
  const updateExistingShippingAddress = () => {
    updateShippingAddress(addAddress)
      .then(() => {
        getShippingAddressData();
        setAddAddressValues({
          addressLine1: '',
          addressLine2: '',
          addressType: 'Home',
          city: '',
          country: '',
          defaultAddress: false,
          fullName: '',
          id: null,
          landmark: '',
          latitude: 0,
          longitude: 0,
          mobileNumber: '',
          state: '',
          zipCode: ''
        });
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  // This function add new shipping address.
  const addNewShippingAddress = () => {
    addShippingAddress(addAddress)
      .then(() => {
        getShippingAddressData();
        setAddAddressValues({
          addressLine1: '',
          addressLine2: '',
          addressType: 'Home',
          city: '',
          country: '',
          defaultAddress: false,
          fullName: '',
          id: null,
          landmark: '',
          latitude: 0,
          longitude: 0,
          mobileNumber: '',
          state: '',
          zipCode: ''
        });
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  // This function get shipping address list.
  const getShippingAddressData = async () => {
    getAllShippingAddress()
      .then((res) => {
        setShippingAddress(res);
        setAdressLoading(true);
        const item = res.find((item: Address) => {
          return item.defaultAddress;
        });
        setSelectedAddress(item);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // This function get cart item list.
  const getCartData = async () => {
    getCartDataList().then((res) => {
      setCartData(res);
    });
  };

  // This function set value on input change.
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setAddAddressValues((previousState) => {
      return { ...previousState, [name]: value };
    });
  }, []);

  // This function use edit shipping address.
  const addressEditClick = (address: Address) => {
    setAddAddressValues({
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressType: address.addressType,
      city: address.city,
      country: address.country,
      defaultAddress: address.defaultAddress,
      fullName: address.fullName,
      id: address.id,
      landmark: address.landmark,
      latitude: address.latitude,
      longitude: address.longitude,
      mobileNumber: address.mobileNumber,
      state: address.state,
      zipCode: address.zipCode
    });
    setActiveStep(0);
  };

  // This function use navigate to add new address and reset state of address.
  const addNewAddress = () => {
    setAddAddressValues({
      addressLine1: '',
      addressLine2: '',
      addressType: 'Home',
      city: '',
      country: '',
      defaultAddress: false,
      fullName: '',
      id: null,
      landmark: '',
      latitude: 0,
      longitude: 0,
      mobileNumber: '',
      state: '',
      zipCode: ''
    });
    setActiveStep(0);
  };

  //onRadioButtonHandleChange: This function use to change selected address.
  const onRadioButtonHandleChange = (value: string) => {
    setAddAddressValues({
      ...addAddress,
      addressType: value
    });
  };

  //getPaymentOptionsData: This function get payment option.
  const getPaymentOptionsData = async () => {
    getPaymentOptions()
      .then((res) => {
        setPaymentOptions(res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // getSubTotalPrice: This function get shipping address list.
  const getSubTotalPrice = () => {
    let total = 0;
    cartData.forEach((p: CartItem) => {
      total = total + p.totalPrice;
    });
    return total;
  };

  // paymentGatewayCall: This function use to call payment gateway acc to use click.
  const paymentGatewayCall = (paymentType: string) => {
    if (cartData && cartData.length > 0) {
      if (selectedShippingAddress && selectedShippingAddress.id) {
        const requestBody = getCheckoutRequestBody(paymentType, 'Delivery');
        placeOrder(requestBody)
          .then((response) => {
            setOrderID(response.orderId);
            setExpectedOrder(response.expectedOrderDelivery);
            const body = {
              companyNo: response.companyNo,
              orderId: response.orderId,
              orderStatus: 'Placed',
              expectedOrderDelivery: response.expectedOrder
            };
            if (response.paymentMode === 'Razorpay') {
              displayRazorpay(response.totalPrice, response.orderId);
            } else if (response.paymentMode === 'COD') {
              setisLoading(true);
              placeOrderStatus(body);
            }
          })
          .catch((error) => {
            errorModalHandleOpen();
            console.log('error', error);
            setisLoading(false);
          });
      } else {
        toast.error('Please select address');
      }
    }
  };

  // Mode Razorpay start
  // loadScript: This function use load razorpay script.
  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // displayRazorpay: This function use display razorpay modal.
  const displayRazorpay = async (totalPrice: number, orderId: number) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const createOrderRequestBody = {
      amount: totalPrice.toString(),
      currency: 'INR',
      receipt: '',
      payment_capture: true,
      country: 'India',
      initiateOrderId: orderId,
      paymentGateway: 'RazorPay'
    };
    createOrder(createOrderRequestBody).then((orderResponse) => {
      const options = {
        key: orderResponse.razorPayKey,
        amount: orderResponse.amount,
        currency: orderResponse.currency,
        name: userState.fullName,
        order_id: orderResponse.orderId,
        account_id: orderResponse.razorPayAccountId,

        handler: async function (response: any) {
          paymentCallback(response, orderResponse.orderId);
        },

        prefill: {
          name: userState.fullName,
          email: userState.email,
          contact: userState.mobileNumber
          // method: 'upi'
        },
        notes: {
          source: 'Authorised Person & Ambassador Payment - Web',
          email: userState.email,
          apId: 1,
          transactionType: 'New Authorised Partner',
          mobile: userState.mobileNumber
        },
        theme: {
          color: '#61dafb'
        }
      };
      const paymentObject = new Razorpay(options);
      paymentObject.open();
    });
  };

  // paymentCallback: This function use verify payment after payment done.
  const paymentCallback = (paymentData: any, orderId: any) => {
    paymentData.orderId = orderId;
    const requestBody = {
      orderId: paymentData.razorpay_order_id,
      razorPayPaymentId: paymentData.razorpay_payment_id,
      razorPaySignature: paymentData.razorpay_signature,
      razorPayAccountId: paymentData.account_id,
      paymentGateway: 'RazorPay'
    };
    verifyPayment(requestBody)
      .then((paymentResponse) => {
        const body = {
          companyNo: userState.companyNo,
          orderId: orderID,
          orderStatus: 'Placed',
          expectedOrderDelivery: expectedOrder,
          transactionId:
            paymentData && paymentData.razorpay_payment_id
              ? paymentData.razorpay_payment_id
              : ''
        };
        placeOrderStatus(body);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  // Mode Razorpay end

  // getCheckoutRequestBody : This function use to get requestBody for placeOrder call.
  const getCheckoutRequestBody = (
    mode: string,
    type: string,
    paymentData?: any
  ) => {
    const requestBody = {} as Order;
    requestBody.amountPaid =
      currentAmountForCheckout > 0
        ? currentAmountForCheckout
        : getSubTotalPrice();
    requestBody.billingUserAddressId = Number(selectedShippingAddress.id);
    requestBody.companyNo = userState.companyNo;
    requestBody.deliveryCharges =
      freeDeliveryOnPurchaseVal > 0 && freeDeliveryOnPurchaseVal !== null
        ? (currentAmountForCheckout > 0
            ? currentAmountForCheckout
            : getSubTotalPrice()) > freeDeliveryOnPurchaseVal
          ? 0
          : defaultDeliveryChargesVal
          ? defaultDeliveryChargesVal
          : 0
        : defaultDeliveryChargesVal
        ? defaultDeliveryChargesVal
        : 0;
    requestBody.deliveryOption = type;
    requestBody.orderBy = userState.id;
    requestBody.offerCodes = { promoCode: couponPromoCode };
    requestBody.paymentGateway = mode;
    requestBody.paymentMode = mode;
    requestBody.paymentStatus =
      paymentData && paymentData.transactionId ? 'Success' : 'Pending';
    requestBody.pointsAwarded = 2;
    requestBody.shippingUserAddressId = Number(selectedShippingAddress.id);
    requestBody.totalPrice =
      currentAmountForCheckout > 0
        ? currentAmountForCheckout
        : getSubTotalPrice();
    requestBody.transactionId =
      paymentData && paymentData.razorpay_payment_id
        ? paymentData.razorpay_payment_id
        : '';
    requestBody.userComment = comment;
    requestBody.productsList = [];
    cartData.forEach((product: CartItem) => {
      const Obj = {} as ProductsList;
      Obj.productId = product.productEntity.id;
      requestBody.productsList.push(Obj);
    });

    // const addressDataLine = selectedShippingAddress.landmark
    //   ? `${selectedShippingAddress?.addressLine1}, ${selectedShippingAddress?.addressLine2}, ${selectedShippingAddress?.landmark}`
    //    : `${selectedShippingAddress?.addressLine1}, ${selectedShippingAddress?.addressLine2}`;
    //    requestBody.shippingAddress = addressDataLine;

    const addressDataLine = selectedShippingAddress?.addressLine1;
    requestBody.shippingAddress = addressDataLine;

    return requestBody;
  };

  // placeOrderStatus : This function use to check place order status.
  const placeOrderStatus = (request: PlaceOrderStatusReq) => {
    orderStatus(request)
      .then((response) => {
        setPlacedOrderResponse(response);
        setisLoading(false);
        successModalHandleOpen();
      })
      .catch((error) => {
        console.log('error', error);
        setisLoading(false);
        errorModalHandleOpen();
      });
  };

  // setCurrentAmount : This function use to set current amount according to discount applied.
  const setCurrentAmount = (value: number) => {
    setCurrentAmountForCheckout(value);
  };

  const getComment = (value: string) => {
    setComment(value);
  };
  // Function to get Discounted Price from <CartDetails /> component
  const getCouponDiscountAmount = (amount: number) => {
    setCouponDiscountAmount(amount);
  };

  // Function to get Coupon Promo Code from <CartDetails /> component
  const getCouponPromoCode = (code: string) => {
    setCouponPromoCode(code);
  };

  // useEffect: This function use to get shipping address while loading.
  useEffect(() => {
    setActiveStep(location.state | 0);
    getShippingAddressData();
    getPaymentOptionsData();
    getCartData();
  }, [location]);

  /**
   * useEffect - calls geocode api for fetching state and city
   */
  useEffect(() => {
    if (addAddress?.zipCode.length === 6) {
      getCityAndStateFromPin(addAddress.zipCode).then((res: any) => {
        if (res.status === 'OK') {
          setisPincodeError(false);
          let addressObj: any[] = [];
          res.results[0].address_components.forEach((addItem: any) => {
            if (
              addItem.types[0] === 'administrative_area_level_2' ||
              addItem.types[0] === 'locality'
            ) {
              addressObj[0] = addItem.long_name;
            }
            if (addItem.types[0] === 'administrative_area_level_1') {
              addressObj.push(addItem.long_name);
            }
            if (addItem.types[0] === 'country') {
              addressObj.push(addItem.long_name);
            }
          });
          setAddAddressValues((address) => {
            return {
              ...address,
              city: addressObj[0],
              country: addressObj[2],
              state: addressObj[1],
              latitude: res.results[0].geometry.location.lat,
              longitude: res.results[0].geometry.location.lng
            };
          });
        } else {
          setisPincodeError(true);
          setAddAddressValues((address) => {
            return {
              ...address,
              city: '',
              country: '',
              state: ''
            };
          });
        }
      });
    }
  }, [addAddress.zipCode]);
  // useEffect(() => {
  //   if (shippingAddress.length) {
  //     setActiveStep(1);
  //   }
  // }, [shippingAddress]);

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiStepIcon-active': { color: ' #24A4B7 ' },
      '& .MuiStepIcon-completed': { color: ' #2C2C2C ' }
    }
  }));

  useEffect(() => {
    setDeliveryChargesonRadius();
  }, [selectedShippingAddress]);

  //Added function for set delivery charges depends on Location Radius.
  const setDeliveryChargesonRadius = () => {
    console.log(selectedShippingAddress);
    if (selectedShippingAddress) {
      let lat = selectedShippingAddress.latitude;
      let lng = selectedShippingAddress.longitude;
      let TotalAmount =
        currentAmountForCheckout > 0
          ? currentAmountForCheckout
          : getSubTotalPrice();
      getDeliveryChargesOnDistRadius(lat, lng, company_no, 'km', TotalAmount)
        .then((res) => {
          setDeliveryCharges(res.deliveryCharges);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  };

  const styles = useStyles();
  return (
    <main className=" bg-bg-primary">
      <section className="container w-11/12 pb-10">
        {/* Stepper */}
        <Stepper
          className={`bg-bg-primary lg:w-8/12 ${styles.root}`}
          activeStep={activeStep}
          alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    active: 'bg-primary rounded-full'
                  }
                }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* Component for stepper */}
        {minimumOrderValue - getSubTotalPrice() > 0 ? (
          <div className="bg-rose-500/25 text-base col-span-8 text-center p-2 mb-3 lg:ml-28">
            <span>
              Minimum purchase amount is Rs {minimumOrderValue}, shop for Rs{' '}
              {minimumOrderValue - getSubTotalPrice()} more to place an order
            </span>
          </div>
        ) : null}
        <section className="grid grid-rows-2 lg:grid-cols-12 mx-auto">
          <section className="row-start-2 lg:col-span-8 lg:row-start-1 lg:col-start-1 lg:px-28">
            {/* step 1 */}
            {activeStep === 0 ? (
              <section className="bg-white p-6">
                <section className="text-xl text-text-body mb-6 font-bold ">
                  Shipping address
                </section>
                <section className="mb-6">
                  <label htmlFor="fullName" className="text-text-base">
                    Full name
                  </label>
                  <div>
                    <input
                      className="w-full border border-r-1 p-2 border-gray-300 h-12"
                      type="text"
                      name="fullName"
                      id="fullName"
                      value={addAddress.fullName ? addAddress.fullName : ''}
                      onChange={handleOnChange}
                    />
                  </div>
                </section>
                <section className="mb-6">
                  <label htmlFor="addressLine1" className="text-text-base">
                    Address
                  </label>
                  <div>
                    <input
                      className="w-full border border-r-1 p-2 border-gray-300 h-12"
                      type="text"
                      name="addressLine1"
                      id="addressLine1"
                      value={
                        addAddress.addressLine1 ? addAddress.addressLine1 : ''
                      }
                      onChange={handleOnChange}
                    />
                  </div>
                </section>

                <section className="grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 mb-6">
                  <section className="lg:col-auto">
                    <label htmlFor="zipCode" className="text-text-base">
                      Postal code
                    </label>
                    <div>
                      <input
                        className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                        type="number"
                        name="zipCode"
                        id="zipCode"
                        value={addAddress.zipCode ? addAddress.zipCode : ''}
                        onChange={handleOnChange}
                      />
                      <span
                        className="text-red-500 text-sm"
                        hidden={!isPincodeError}>
                        Please enter a valid pincode
                      </span>
                    </div>
                  </section>
                  <section className="col-span-2">
                    <label htmlFor="mobileNumber" className="text-text-base">
                      Phone
                    </label>
                    <div>
                      <input
                        className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                        type="text"
                        name="mobileNumber"
                        id="mobileNumber"
                        value={
                          addAddress.mobileNumber
                            ? addAddress.mobileNumber
                            : ''
                        }
                        onChange={handleOnChange}
                      />
                    </div>
                  </section>
                  <section>
                    <div className="text-text-base">Country</div>
                    <div>
                      <input
                        className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                        type="text"
                        name="country"
                        value={addAddress.country ? addAddress.country : ''}
                        onChange={handleOnChange}
                        disabled
                      />
                    </div>
                  </section>
                  <section>
                    <div className="text-text-base">State</div>
                    <div>
                      <input
                        className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                        type="text"
                        name="state"
                        value={addAddress.state ? addAddress.state : ''}
                        onChange={handleOnChange}
                        disabled
                      />
                    </div>
                  </section>
                  <section>
                    <div className="text-text-base">City</div>
                    <div>
                      <input
                        className="w-full border border-r-1 p-2 border-gray-300 h-12 focus:outline-1 focus:outline-text-muted"
                        type="text"
                        name="city"
                        value={addAddress.city ? addAddress.city : ''}
                        onChange={handleOnChange}
                        disabled
                      />
                    </div>
                  </section>
                </section>
                <section className="flex flex-col space-y-3 mb-6 items-baseline">
                  <section className="text-lg lg:text-xl text-text-body font-bold pr-4">
                    Type of Address
                  </section>
                  <section className="flex-grow">
                    <input
                      className="align-middle"
                      type="radio"
                      name="addType"
                      value="Home"
                      id="home"
                      defaultChecked={addAddress.addressType === 'Home'}
                      onChange={(e) => {
                        onRadioButtonHandleChange(e.target.value);
                      }}
                    />
                    <label htmlFor="home" className="pl-1 pr-3 text-sm">
                      HOME
                    </label>
                    <input
                      className="align-middle"
                      type="radio"
                      name="addType"
                      value="Office"
                      id="office"
                      defaultChecked={addAddress.addressType === 'Office'}
                      onChange={(e) => {
                        onRadioButtonHandleChange(e.target.value);
                      }}
                    />
                    <label htmlFor="office" className="pl-1 pr-3 text-sm">
                      OFFICE
                    </label>
                    <input
                      className="align-middle"
                      type="radio"
                      name="addType"
                      value="Other"
                      id="other"
                      defaultChecked={addAddress.addressType === 'Other'}
                      onChange={(e) => {
                        onRadioButtonHandleChange(e.target.value);
                      }}
                    />
                    <label htmlFor="other" className="pl-1 pr-3 text-sm">
                      OTHER
                    </label>
                  </section>
                </section>
                <section className="mb-6">
                  <input
                    className="align-middle cursor-pointer"
                    type="checkbox"
                    name="defaultAddress"
                    id="defaultAddress"
                    checked={
                      addAddress.defaultAddress
                        ? addAddress.defaultAddress
                        : false
                    }
                    onChange={(e) => {
                      setAddAddressValues({
                        ...addAddress,
                        defaultAddress: e.target.checked
                      });
                    }}
                  />
                  <label htmlFor="defaultAddress" className="text-xs ml-3">
                    Mark default address
                  </label>
                </section>
                <section className="grid grid-rows-2 lg:grid-cols-2 gap-3 lg:gap-4 mb-6 items-baseline">
                  <div
                    className="row-start-2 lg:row-start-1 flex justify-center align-middle my-auto lg:justify-start space-x-2 text-md text-primary cursor-pointer"
                    onClick={() => {
                      navigate('/');
                    }}>
                    <img
                      src={icons['leftPrimaryArrow']}
                      alt="leftPrimaryArrow"
                    />
                    <span>Return to shop</span>
                  </div>
                  <button
                    className="row-start-1 bg-primary mt-4 lg:mt-0 py-2 text-white  hover:text-white hover:bg-text-title transition-all duration-150 text-sm"
                    onClick={handleNext}>
                    <span className="mx-auto text-xs lg:text-base">
                      {isEdit ? 'EDIT ADDRESS' : 'ADD NEW ADDRESS'}
                    </span>
                  </button>
                </section>
              </section>
            ) : null}

            {/* step 2 */}
            {activeStep === 1 ? (
              <section className="bg-white px-8 py-7">
                <section className="flex justify-between items-center mb-6">
                  <section className="text-xl font-bold">
                    Shipping address
                  </section>
                  <section
                    className="text-sm text-primary cursor-pointer"
                    onClick={() => {
                      setIsEdit(false);
                      addNewAddress();
                    }}>
                    + Add New
                  </section>
                </section>
                {/* list of address */}
                {adressLoading ? (
                  <section className="h-80 overflow-y-scroll border-b">
                    {shippingAddress && shippingAddress.length > 0
                      ? shippingAddress.map((address) => (
                          <section
                            className="mb-4 px-5 py-2 border border-gray-200"
                            key={address.id}>
                            <section
                              className={`flex mt-1 mb-4 ${
                                address.defaultAddress
                                  ? 'justify-between'
                                  : 'justify-end'
                              }`}>
                              {address.defaultAddress ? (
                                <button className="text-xs bg-primary text-white px-6 py-1">
                                  Default
                                </button>
                              ) : null}
                              <input
                                type="radio"
                                className="cursor-pointer"
                                key={address.id}
                                name="selectedAddress"
                                defaultChecked={address.defaultAddress}
                                onChange={() => {
                                  setSelectedAddress(address);
                                }}
                              />
                            </section>
                            <section className="text-text-base text-sm font-bold">
                              {address.fullName}
                            </section>
                            <section className="text-xs text-gray-500">
                              {address.addressLine1 ? (
                                <span>{address.addressLine1}</span>
                              ) : null}
                              <span>{address.addressLine2}</span>
                              {address.landmark ? (
                                <span>,{address.landmark}</span>
                              ) : null}
                              {address.addressType ? (
                                <span> - ( {address.addressType} )</span>
                              ) : null}
                              {address.zipCode ? (
                                <span>
                                  <br />
                                  {address.zipCode}
                                </span>
                              ) : null}
                            </section>
                            <button
                              className="text-primary text-xs"
                              onClick={() => {
                                addressEditClick(address);
                                setIsEdit(true);
                              }}>
                              Edit
                            </button>
                          </section>
                        ))
                      : null}
                  </section>
                ) : (
                  <div>
                    <PaymentSelectionSkeleton />
                  </div>
                )}

                <section className="mt-4 text-xl mb-6 font-bold ">
                  Select payment type
                </section>
                {/* payment option list */}
                <section className="grid lg:grid-cols-2 gap-2 mb-10">
                  {/* razorpay  */}
                  {paymentOptions.razorPaySupported ? (
                    <section
                      onClick={() => {
                        paymentGatewayCall('Razorpay');
                      }}
                      className="grid place-items-center justify-center border px-6 py-4  cursor-pointer">
                      <img src={icons['razorpay']} alt="razorpay" />
                    </section>
                  ) : null}
                  {/* codSupported */}
                  {paymentOptions.codSupported ? (
                    <section
                      onClick={() => {
                        if (getSubTotalPrice() > minimumOrderValue) {
                          paymentGatewayCall('COD');
                        }
                      }}
                      className="flex space-x-2 py-6 border justify-center cursor-pointer">
                      <img src={icons['cod']} alt="cod" />
                      {isLoading ? (
                        <div className="transform">
                          <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-2 h-6 w-6"></div>
                        </div>
                      ) : null}
                      <span className="text-gray-500 font-bold lg:my-auto">
                        Cash on Delivery
                      </span>
                    </section>
                  ) : null}
                </section>
              </section>
            ) : null}

            {/* step 3 */}
            {activeStep === 2 ? (
              <ConfirmationCartDetails
                placedOrderResponse={placedOrderResponse}
                cartData={cartData}
                couponDiscountAmount={couponDiscountAmount}
              />
            ) : null}
          </section>
          <section className="row-start-1 lg:col-span-4 lg:row-start-1 lg:col-start-9 mt-5 lg:mt-0">
            {activeStep === 0 || activeStep === 1 ? (
              <CartDetails
                setCurrentAmount={setCurrentAmount}
                deliveryCharges={
                  freeDeliveryOnPurchaseVal > 0 &&
                  freeDeliveryOnPurchaseVal !== null
                    ? (currentAmountForCheckout > 0
                        ? currentAmountForCheckout
                        : getSubTotalPrice()) > freeDeliveryOnPurchaseVal
                      ? 0
                      : defaultDeliveryChargesVal
                      ? defaultDeliveryChargesVal
                      : 0
                    : defaultDeliveryChargesVal
                    ? defaultDeliveryChargesVal
                    : 0
                }
                cartData={cartData}
                getComment={getComment}
                getCouponDiscountAmount={getCouponDiscountAmount}
                getCouponPromoCode={getCouponPromoCode}
              />
            ) : (
              <ConfirmationUserDetails data={placedOrderResponse} />
            )}
          </section>
        </section>
      </section>

      {/* order success */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOrderSuccessModal}
        onClose={successModalHandleClose}>
        <section className="text-center absolute top-1/2 left-1/2 bg-white px-20 py-8 -translate-x-2/4 -translate-y-2/4">
          <section className="text-base font-semibold pb-10">
            Order is Successfully Placed!
          </section>
          <section className="flex justify-center pb-10">
            <img src={icons['placed']} alt="Placed" />
          </section>
          <section className="text-xs flex justify-center w-60">
            You will be receiving a confirmation email with order details
          </section>
        </section>
      </Modal>

      {/* order fail */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOrderErrorModal}
        onClose={errorModalHandleClose}>
        <section className="text-center absolute top-1/2 left-1/2 bg-white px-20 py-8 -translate-x-2/4 -translate-y-2/4">
          <section className="text-base font-semibold pb-10">
            Payment Failed
          </section>
          <section className="flex justify-center pb-6">
            <img src={icons['paymentFail']} alt="Placed" />
          </section>
          <section className="text-xs flex justify-center w-60 pb-10">
            It seems we have not received money
          </section>
          <button className="text-xs font-semibold border border-blue-500 py-2 px-6 text-blue-500">
            Try Again
          </button>
        </section>
      </Modal>
    </main>
  );
}
