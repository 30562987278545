import { Link, useNavigate } from 'react-router-dom';
import { Ref, useEffect, useState } from 'react';
import { setCategoryMenu, setRightDrawer } from '../../redux/actions/drawer';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion } from '../common/Accordion/Accordion';
import CartPage from '../../screens/Cart/CartPage';
import Drawer from '../common/Drawer/Drawer';
import DropDownList from '../common/DropdownList/DropDownList';
import NavItemLink from './NavItemLink';
import OutsideWrapper from '../common/OutsideWrapper/OutsideWrapper';
import { RootState } from '../../redux/reducers';
import TopOffer from './TopOffer';
import WishListPage from '../../screens/WishList/WishListPage';
import { company_no } from '../../api/api';
import { logout } from '../../redux/actions/auth';
import { openLoginModal } from '../../redux/actions/modals';
import { resetLoginCart } from '../../redux/actions/loginCart';
import { searchProducts } from '../../api/ProductListing';
import { setTopValue } from '../../redux/actions/others';
import { toast } from 'react-toastify';
import { toggleWholesale } from '../../redux/actions/productListing';
import { useDebounce } from 'use-debounce';
import useElementSize from '../../hooks/useElementSize';
import useWindowDimensions from '../../hooks/WindowDimensionsHook';
import { getCompanyInformation } from '../../api/company';
import { config} from '../../config/config';
const Navbar = () => {
  const isWholesale = useSelector((state: RootState) => state.isWholesale);
  const companyInfo = useSelector((state: RootState) => state.companyInfo);
  const drawers = useSelector((state: RootState) => state.drawers);
  const companyDetails = useSelector(
    (state: RootState) => state.companyDetails
  );
  const categories = useSelector((state: RootState) => state.categories);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const [isCategoriesDrawerOpen, setIsCategoriesDrawerOpen] = useState(false);
  const [isDropDownVisible, setisDropDownVisible] = useState(false);
  const [dropownData, setdropownData] = useState<any>([]);
  const [searchBarVisible, setsearchBarVisible] = useState(false);
  const { width } = useWindowDimensions();
  const counts = useSelector((state: RootState) => state.counts);
  const userCart = useSelector((state: RootState) => state.cart);
  /**setsearchInput - function to set state of input */
  const [searchInput, setsearchInput] = useState('');
  /**setisRighDrawerOpen - function to open and close right drawer on smaller screens  */
  const [isRightDrawerOpen, setisRightDrawerOpen] = useState(false);
  const [freeDeliveryOnPurchaseAmt , setFreeDeliveryOnPurchaseAmt] = useState(0);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  /**
   * navigateToProduct - function to pass down to child dropdown
   * @param {string} path - path to navigate to
   */

  //freeDeliveryOnPurchase value get the  value from Admin Panel
  const getFreeDelivaryOnPurchaseValue = async () => {
    const {freeDeliveryOnPurchase}  = await getCompanyInformation(config.COMPANY_NO);
    setFreeDeliveryOnPurchaseAmt(freeDeliveryOnPurchase);
   };
  useEffect(()=>{
    getFreeDelivaryOnPurchaseValue()
  },[])
  const navigateToProduct = (path: string) => {
    navigate(path);
    setisDropDownVisible(false);
  };

  const [squareRef, { height }] = useElementSize();
  useEffect(() => {
    dispatch(setTopValue(height));
  }, [height]);

  useEffect(() => {
    if (width > 1300) {
      setsearchBarVisible(false);
    }
  }, [width]);

  /**debounced search term */
  const debouncedSearchCall = useDebounce(searchInput, 400);

  /**useEffect - makes an api call after designated debounce time to search products  */
  useEffect(() => {
    if (typeof debouncedSearchCall[0] === 'string' && debouncedSearchCall[0]) {
      searchProducts(
        company_no,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'productName',
        'ASC',
        10,
        0,
        isWholesale,
        debouncedSearchCall[0]
      )
        .then((res) => {
          if (res['product']) {
            setdropownData([...res['product']]);
            if (debouncedSearchCall[0] === '') {
              setisDropDownVisible(false);
            } else {
              setisDropDownVisible(true);
            }
          } else {
            setisDropDownVisible(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
    }
  }, [debouncedSearchCall[0], isWholesale]);

  /**
   * changeHandler - function to handle change event of input element
   * @param event - React ChangeEvent
   */
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchInput(event.target.value);
    if (event.target.value === '') {
      setisDropDownVisible(false);
    }
  };

  return (
    <div
      className="flex flex-col fixed top-0 w-full z-50"
      id="navbar"
      ref={squareRef}>
      { !isLoggedIn && freeDeliveryOnPurchaseAmt > 0 && freeDeliveryOnPurchaseAmt !== null?
        <div className="bg-primary text-white py-1 text-center text-sm lg:text-base md:text-base ">
        <span> FREE SHIPPING ACROSS INDIA FOR ORDERS ABOVE ₹{freeDeliveryOnPurchaseAmt}</span>
      </div> 
      :null}
      <nav className="w-full bg-white flex items-center transition-all duration-100 ease-linear px-4 3xl:px-20 min-h-[56px] 2xl:min-h-[100px] shadow-md">
        <ul className="flex-1 flex justify-start items-center h-full">
          <li
            className="flex items-center relative cursor-pointer"
            onClick={() => {
              if (drawers?.isCategoryMenuOpen) {
                dispatch(setCategoryMenu(false));
              } else {
                dispatch(setCategoryMenu(true));
              }
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="12"
              viewBox="0 0 18 12"
              className="cursor-pointer w-6 xl:w-8 h-6 xl:h-8 lg:px-0 xl:px-6px">
              <g
                id="Group_16559"
                data-name="Group 16559"
                transform="translate(2729 -9948)">
                <rect
                  id="Rectangle_29961"
                  data-name="Rectangle 29961"
                  width="18"
                  height="2"
                  rx="1"
                  transform="translate(-2729 9948)"
                  fill="#2c2c2c"
                />
                <rect
                  id="Rectangle_29962"
                  data-name="Rectangle 29962"
                  width="15"
                  height="2"
                  rx="1"
                  transform="translate(-2729 9953)"
                  fill="#2c2c2c"
                />
                <rect
                  id="Rectangle_29963"
                  data-name="Rectangle 29963"
                  width="12"
                  height="2"
                  rx="1"
                  transform="translate(-2729 9958)"
                  fill="#2c2c2c"
                />
              </g>
            </svg>
            <span className="capitalize text-text-title text-base ml-6 cursor-pointer hidden xl:block">
              categories
            </span>
          </li>
          <li className="hidden lg:flex ml-20">
            <NavItemLink title="home" to="/" />
            <span
              onClick={() => {
                dispatch(toggleWholesale());
                !isWholesale && navigate('/products');
              }}
              className={`px-1 mx-4 text-center text-base cursor-pointer capitalize hover:text-primary flex items-center ${
                isWholesale
                  ? 'border-b border-primary text-text-title'
                  : 'border-b-0 text-text-muted'
              }`}>
              wholesale
            </span>
            <NavItemLink title="blog" to="/blogs" />
            <NavItemLink title="contact" to="/contact-us" />
          </li>
        </ul>
        <ul className="flex justify-center items-center h-full">
          <li>
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="block">
              <img
                className="h-16 3xl:h-20 w-auto"
                src={companyDetails.companyLogo}
                alt="Company Logo"
              />
            </Link>
          </li>
        </ul>
        <ul className="flex-1 flex justify-end items-center h-full">
          <li className="relative hidden font-futura-light lg:block mr-5">
            <input
              value={searchInput}
              onChange={(e) => changeHandler(e)}
              type="text"
              placeholder="Search for product"
              className="border rounded-full px-6 py-2 placeholder-text-muted focus:outline-none focus:ring-primary focus:ring-1"
            />
            {searchInput === '' ? (
              // <svg
              //   className="absolute top-2 right-2"
              //   id="icon_action_search_24px"
              //   data-name="icon/action/search_24px"
              //   xmlns="http://www.w3.org/2000/svg"
              //   width="24"
              //   height="24"
              //   viewBox="0 0 24 24">
              //   <rect id="Boundary" width="24" height="24" fill="none" />
              //   <path
              //     id="_Color"
              //     data-name=" ↳Color"
              //     d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z"
              //     transform="translate(3 3)"
              //   />
              // </svg>
              <svg
                className="absolute top-2 right-3"
                version="1.1"
                id="icon_action_search_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                x="0px"
                y="0px"
                viewBox="0 0 24 24">
                <circle className="st0" cx="11" cy="11" r="7"/>
                <line
                  className="st0"
                  x1="21.2"
                  y1="21.2"
                  x2="16.8"
                  y2="16.8"
                />
              </svg>
            ) : (
              <svg
                className="absolute top-2 right-3 cursor-pointer"
                onClick={() => {
                  setsearchInput('');
                  setisDropDownVisible(false);
                }}
                id="icon_navigation_close_24px"
                data-name="icon/navigation/close_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <rect id="Boundary" width="24" height="24" fill="none" />
                <path
                  id="_Color"
                  data-name=" ↳Color"
                  d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                  transform="translate(5 5)"
                />
              </svg>
            )}
            <DropDownList
              list={dropownData}
              isVisible={isDropDownVisible}
              onClickNavigate={navigateToProduct}
              extraClasses="origin-top-right absolute right-0"
            />
          </li>
          <li className="flex items-center space-x-4">
            <a href={`tel:${companyInfo.phoneNumber}`} className="group">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                id="call"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  className="group-hover:stroke-primary transition-all duration-150"
                  id="Shape"
                  d="M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407l-1.032-.056a.662.662,0,0,1-.579-.437,9.327,9.327,0,0,1,0-6.5.662.662,0,0,1,.579-.437l1.032-.109a.7.7,0,0,0,.589-.394L7.03,2.446l.331-.662a.708.708,0,0,0,.07-.308.692.692,0,0,0-.179-.467A3,3,0,0,0,4.693.017l-.235.03L4.336.063A1.556,1.556,0,0,0,4.17.089l-.162.04C1.857.679.165,4.207,0,8.585V9.83c.165,4.372,1.857,7.9,4,8.483l.162.04a1.556,1.556,0,0,0,.165.026l.122.017.235.03a3,3,0,0,0,2.558-.993.692.692,0,0,0,.179-.467.708.708,0,0,0-.07-.308Z"
                  transform="translate(3.887 6.093) rotate(-30)"
                  fill="none"
                  stroke="#000"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                />
              </svg>
            </a>
            <span
              className="hidden lg:block group"
              onClick={() => {
                if (isLoggedIn) {
                  navigate('/profile');
                } else {
                  dispatch(openLoginModal());
                }
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#2C2C2C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="cursor-pointer">
                <path
                  className="group-hover:stroke-primary transition-all duration-150"
                  d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle
                  className="group-hover:stroke-primary transition-all duration-150"
                  cx="12"
                  cy="7"
                  r="4"></circle>
              </svg>
            </span>
            <span className="lg:flex flex-row hidden">
              <WishListPage />
              {counts?.wishlistCount?.length && isLoggedIn ? (
                <span className="relative -top-2 right-3 h-4 w-4 inline-flex items-center justify-center text-xxs font-bold leading-none text-red-100 bg-primary rounded-full">
                  {counts?.wishlistCount?.length}
                </span>
              ) : null}
            </span>
            <span className="lg:flex flex-row hidden relative">
              <CartPage />
              {(counts?.cartCount?.ProductQuantity && isLoggedIn) ||
              (!isLoggedIn && userCart?.length) ? (
                <span className="absolute h-4 w-4 inline-flex items-center justify-center text-xxs text-white -top-2 -right-2 bg-primary rounded-full">
                  {isLoggedIn
                    ? counts?.cartCount?.ProductQuantity
                    : userCart?.length}
                </span>
              ) : null}
            </span>

            <svg
              onClick={() => {
                if (drawers?.isRightDrawerOpen) {
                  dispatch(setRightDrawer(false));
                } else {
                  dispatch(setRightDrawer(true));
                }
              }}
              className="block lg:hidden cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="12"
              viewBox="0 0 18 12">
              <path
                id="_Color"
                data-name=" ↳Color"
                d="M17,12H1a1,1,0,0,1,0-2H17a1,1,0,1,1,0,2Zm0-5H1A1,1,0,0,1,1,5H17a1,1,0,1,1,0,2Zm0-5H1A1,1,0,0,1,1,0H17a1,1,0,1,1,0,2Z"
                fill="#001414"
              />
            </svg>
          </li>
        </ul>
      </nav>
      {searchBarVisible ? (
        <section
          className={`flex justify-center bg-opacity-25 z-[90] inset-0 ease-in-out transition-all duration-700 ${
            searchBarVisible
              ? 'translate-y-28 opacity-100'
              : '-translate-y-16 opacity-0'
          }`}>
          <section className="flex w-full mr-5 cursor-pointer flex-row justify-center mx-8">
            <input
              value={searchInput}
              onChange={(e) => changeHandler(e)}
              onFocus={() => {
                if (searchInput) {
                  setisDropDownVisible(true);
                }
              }}
              type="text"
              placeholder="Search for product"
              className="border  px-12 py-2 placeholder-text-muted text-sm focus:outline-none focus:ring-primary focus:ring-1"
            />
            {searchInput === '' ? (
              <svg
                className="relative -top-8 -right-32"
                id="icon_action_search_24px"
                data-name="icon/action/search_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <rect id="Boundary" width="24" height="24" fill="none" />
                <path
                  id="_Color"
                  data-name=" ↳Color"
                  d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z"
                  transform="translate(3 3)"
                />
              </svg>
            ) : (
              <svg
                className="relative -top-8 -right-32 cursor-pointer"
                onClick={() => {
                  setsearchInput('');
                  setisDropDownVisible(false);
                }}
                id="icon_navigation_close_24px"
                data-name="icon/navigation/close_24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <rect id="Boundary" width="24" height="24" fill="none" />
                <path
                  id="_Color"
                  data-name=" ↳Color"
                  d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                  transform="translate(5 5)"
                />
              </svg>
            )}
            <svg
              onClick={() => {
                setsearchBarVisible(false);
                setsearchInput('');
                setisDropDownVisible(false);
              }}
              className="relative top-2 cursor-pointer"
              id="icon_navigation_close_24px"
              data-name="icon/navigation/close_24px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <rect id="Boundary" width="24" height="24" fill="none" />
              <path
                id="_Color"
                data-name=" ↳Color"
                d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                transform="translate(5 5)"
              />
            </svg>
          </section>
          <DropDownList
            list={dropownData}
            isVisible={isDropDownVisible}
            onClickNavigate={navigateToProduct}
            extraClasses="left-16"
            afterRedirect={() => setsearchBarVisible(false)}
          />
        </section>
      ) : null}
      <Drawer
        position="left"
        title="Categories"
        isOpen={drawers?.isCategoryMenuOpen}>
        <div className="w-full">
          {categories && categories.length
            ? categories.map((cat, index) =>
                cat.categoryName !== null ? (
                  <Accordion
                    key={index}
                    className="border-b"
                    category={cat}
                    hasImage
                    content="Subcategories"
                    title={''}
                  />
                ) : null
              )
            : null}
        </div>
      </Drawer>
      <Drawer position="right" title="" isOpen={drawers?.isRightDrawerOpen}>
        <ul className="">
          {isLoggedIn ? (
            <li
              className="p-3 border-b cursor-pointer"
              onClick={() => {
                dispatch(logout());
                dispatch(resetLoginCart());
                dispatch(setRightDrawer(false));
                toast.success('Logged Out Successfully');
              }}>
              Logout
            </li>
          ) : (
            <li
              onClick={() => {
                dispatch(openLoginModal());
                dispatch(setRightDrawer(false));
              }}
              className="p-3 border-b cursor-pointer">
              Sign In
            </li>
          )}

          <li
            onClick={() => {
              dispatch(setRightDrawer(false));
              window.scrollTo(0, 0);
              navigate('/contact-us');
            }}
            className="p-3 border-b cursor-pointer">
            Contact Us
          </li>
          <li
            onClick={() => {
              dispatch(setRightDrawer(false));
            }}
            className="p-3 border-b cursor-pointer">
            Wholesale
          </li>
          <li
            onClick={() => {
              dispatch(setRightDrawer(false));
              window.scrollTo(0, 0);
              navigate('/blog');
            }}
            className="p-3 border-b cursor-pointer">
            Blog
          </li>
        </ul>
      </Drawer>
    </div>
  );
};

export default Navbar;
