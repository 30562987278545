import {
  SET_CATEGORIES,
  SET_COMPANY_DETAILS,
  SET_COMPANY_INFO
} from '../actions/actionTypes';

import { Action } from '../../types/type';

export const companyDetailsReducer = (state = {}, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_COMPANY_DETAILS:
        return { ...action.payload };
    }
    return state;
  }
};

export const companyInfoReducer = (state = {}, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_COMPANY_INFO:
        return { ...action.payload };
    }
    return state;
  }
};

const initState = [
  {
    id: '',
    companyNo: 111111,
    companyName: '',
    categoryName: null,
    status: 'ACTIVE',
    description: '',
    categoryImageURL: '',
    categoryCompressImageUrl: '',
    productCount: null,
    subCategoryExists: false,
    createdAt: '20-Jul-2021 07:11PM',
    updatedAt: '20-Jul-2021 07:11PM',
    sequenceId: null,
    deliveryDays: null
  }
];

export const categoriesReducers = (state = initState, action: Action) => {
  if (typeof action.type !== undefined) {
    switch (action.type) {
      case SET_CATEGORIES: {
        return [...action.payload['category']];
      }
    }
    return state;
  }
};
