import { ai, api } from './api';

import axios from 'axios';

/** company - async function to retrieve company details */
export const company = async (companyId: number) => {
  const result = await axios.get(api + `/companies/${companyId}`,
  {
    headers:{
      Accept: 'application/json,text/plain',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
      }).then((res)=>{
        return res.data;
      }).catch((err)=>{
        throw err;
      })
      return result;
};

/** getCompanyInformation - async function to fetch company information */
export const getCompanyInformation = async (
  companyId: number,
  timeZone?: string
) => {
  const result = await axios.get(api + `/companyInformation?companyNo=${companyId}&browserTimeZone=${timeZone}`,
  {
      headers:{
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
      }).then((res)=>{
        return res.data;
      }).catch((err)=>{
        throw err;
      })
      return result;
};

/** getCategories - async function to get categories for given company */
export const getCategories = async (
  companyId: number,
  page?: number,
  size?: number
) =>{
  const result = await axios.get(api + `/category/v1?companyNo=${companyId}&page=${page || 0}&size=${size || 10}`,
  {
      headers: {
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
      }).then((res)=>{
        return res.data;
      })
      .catch((err) =>{
        throw err;
      })
      return result;
}


/** getSubCategories - async function to get subcategories within a category */
export const getSubCategories = async (
  companyId: number,
  category?: string,
  page?: number,
  size?: number
) => {
  const result = await axios.get(api + `/subCategory/v1?companyNo=${companyId}&category=${category || ''}&page=${page || 0}
                &size=${size || 10}`,
  {
      headers: {
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
      }).then((res)=>{
        return res.data;
      })
      .catch((err)=>{
        throw err;
      })
      return result;
};

/** getCurrency - async function to fetch currency type */
export const getCurrency = async (companyId: number) => {
  const result = await axios.get(api + `companies/currency?companyNo=${companyId}`,
  {
      headers:{ 
        Accept: 'application/json,text/plain',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
      }).then((res)=>{
        return res.data;
      }).catch((err)=>{
        throw err;
      })
      return result;
};

/** getOffers - async function to get offers for given given company */
export const getOffers = async (companyId: number) => {
  const result = await axios.get(api + `/offer/v2/user/banner?companyNo=${companyId}` ,
  {
    headers:{
      Accept: 'application/json,text/plain',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then((res) =>{
      return res.data;
    }).catch((err)=>{
      throw err;
    })
      return result;
};

/**
 * getBestSellers - function to fetch best selling products
 * @param {number} companyNo - company number
 * @param {number} n_most - number of top best sellers
 * @param {number} page_size - total records to fetch
 * @param {number} page_no - page index
 * @param {string} start_date - start date from which records to select
 * @param {string} end_date - end date till which records to select
 * @param {string} category - category of products
 * @returns json data containing best selling products
 */
//Added new api for fetch seller Products.
export const getBestSellers = async (
  companyNo: number,
  isWholesale?: boolean,
  n_most: number = 8,
  page_size: number = 8,
  page_no: number = 0,
  order_status?: string,
  start_date?: string,
  end_date?: string,
  category: string = '',
) => {
  const result = await axios.get(
    ai +
    `/products/most_popular_products/v1?n_most=${n_most}&page_size=${page_size}
      &page_no=${page_no}&order_status=${order_status? order_status : ''}&start_date=${start_date ? start_date : ''}
      &end_date=${end_date ? end_date : ''}&category=${category}&companyNo=${companyNo}&wholesaleProduct=${isWholesale}`,{
        headers:{
          Accept: 'application/json,text/plain'
        }
      }
    ).then((res)=>{
      return res.data
    }).catch((err)=>{
      throw err
    })
    return result;
};

/**
 *
 * @param {number} companyNo - company number
 * @returns all brands for a given company
 */
export const getAllBrands = async (companyNo: number) => {
  const result = await axios.get(api+ `/brand?companyNo=${companyNo}`,
  {
    headers:{
      Accept: 'application/json,text/plain',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then((res)=>{
      return res.data;
    }).catch((err)=>{
      throw err
    })
    return result;
};

/**
 * getPaymentOption - function to get payment options for company
 * @returns json configuration for payment options
 */
export const getPaymentOptions = async () => {
  const result = await axios.get(`${api}/payment-options`, 
  {
      headers: {
      Accept: 'application/json,text/plain',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then((res) => {
      return res.data;
    })
    .catch((err)=>{
      throw err;
    })
    return result;
};

/**
 * getFeaturedProducts - async function to fetch featured products
 * @param {number} companyNo - company No
 * @param {string} order_by - order by a feature
 * @param {string} order_direction - ascending or descending
 * @param {number} page_size - number of products to be returned
 * @param {number} page_no - page index
 * @returns json response
 */
export const getFeaturedProducts = async (
  companyNo: number,
  order_by?: string,
  order_direction?: string,
  page_size: number = 8,
  page_no: number = 0
) => {
    const result = await axios.get(
      `${api}/product/featureProduct?productVisibilityEnabled=${true}&companyNo=${companyNo}`
    ).then((res)=>{
      debugger;
      return res.data;
    }).catch((err)=>{
      throw err;
    })
    return result;
};

/**
 * getConfig - API call to fetch company configuration
 * @param {number} companyNo - company no 
 * @returns json data
 */
export const getConfig = async (companyNo:number) => {
  const result = axios.get(`${ai}/public/get_configuration?company_no=${companyNo}`).then(
  (res)=>
    { 
      return res?.data; 
    }
  )
  return result;
} 

//Updated new api for fetching testimonial data
/**
 * 
 * @param companyNo 
 * @param perPage 
 * @param orderBy 
 * @param orderDirection 
 * @param size
 * @returns 
 */
export const getTestimonials = async (companyNo:number,perPage?:number,orderBy?:string,orderDirection?:string , size?:number) => {
   const results = axios.get(`${api}/companies/testimonials/end-user?companyNo=${companyNo}&page=${perPage || 0}&size=${size || 10}&direction=${orderDirection||'ase'}&order_by=${orderBy || 'createdAt'}`)
  .then((res)=> {
    return res.data;
  }).catch(err => {
    throw err
  }) 

  return results;
}