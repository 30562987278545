import React, { FC } from 'react';

import { CartItem } from '../../../types/type';

type CartItemType = {
  item: CartItem;
  onRemoveItemClick: (item: CartItem) => void;
  onQuantityIncrementClick: (item: CartItem) => void;
  onQuantityDecrementClick: (item: CartItem) => void;
};

const Card: FC<CartItemType> = ({
  item,
  onRemoveItemClick,
  onQuantityIncrementClick,
  onQuantityDecrementClick
}: CartItemType) => {
  return (
    <>
      <main className="flex max-w-sm w-full mx-auto">
        {/* img */}
        <section className="h-1/2 bg-black mr-5 flex-none w-16 my-auto">
          <img
            className=""
            src={item?.productEntity?.compressProductImageUrl}
            alt="product"
          />
        </section>
        {/* details */}
        <section className="flex-grow">
          <section className="flex justify-between pb-2">
            <p className="text-sm text-text-body line-clamp-2">
              {item?.productEntity?.productName}
            </p>
            <button
              onClick={() => {
                onRemoveItemClick(item);
              }}>
              x
            </button>
          </section>
          <section className="flex justify-between">
            <section className="flex justify-between pr-2 pl-2 w-24 border border-gray-300">
              <button
                onClick={() => {
                  onQuantityDecrementClick(item);
                }}>
                -
              </button>
              <span>{item?.quantity}</span>
              <button
                onClick={() => {
                  onQuantityIncrementClick(item);
                }}>
                +
              </button>
            </section>
            <span className="text-gray-600 font-semibold">
              Rs. {item?.totalPrice}
            </span>
          </section>
        </section>
      </main>
      <hr className="m-2 max-w-sm w-full mx-auto" />
    </>
  );
};

export default Card;
