import { CartItem, CartItemUpdate } from '../../types/type';
import {
  getCartCountAction,
  getWishListCountAction
} from '../../redux/actions/actionCreators';
import { getWishListData, updateCartItemQuantity } from '../../api/CartApi';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '../../components/common/Drawer/Drawer';
import { ReactComponent as EmptyWishlist } from '../../assets/images/EmptyWishlist.svg';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { openLoginModal } from '../../redux/actions/modals';
import { setWishList } from '../../redux/actions/drawer';
import { toast } from 'react-toastify';
import { useState } from 'react';

const WishListPage = () => {
  /**
   * @description - state to open and close modal
   */
  const [isOpen, setIsOpenDrawer] = useState(false);
  /**
   * @description - state to manage wishlist items
   */
  const [wishListData, setWishListData] = useState([] as CartItem[]);
  /**
   * @description - isLoggedIn const
   */
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const drawers = useSelector((state: RootState) => state.drawers);
  const dispatch = useDispatch();
  const getWishListItems = async () => {
    getWishListData().then((res) => {
      setWishListData(res);
    });
  };

  // This function use open drawer.
  const openWhishlistDrawer = () => {
    if (isLoggedIn) {
      getWishListItems();
      dispatch(setWishList(true));
    } else {
      dispatch(openLoginModal());
    }
  };

  // This function remove cart item.
  const onRemoveItemClick = async (item: CartItem) => {
    const requestBody: CartItemUpdate = {
      cart: false,
      extra: [],
      id: item.id,
      product: item.product,
      status: true,
      wishlist: true
    };
    updateCartItemQuantityCall(requestBody);
  };

  // This function remove cart item.
  const onAddToCartClick = async (item: CartItem) => {
    const requestBody: CartItemUpdate = {
      cart: true,
      extra: [],
      product: item.product,
      quantity: 1,
      status: true,
      wishlist: false
    };
    updateCartItemQuantityCall(requestBody);
  };

  // This function update cart item quantity.
  const updateCartItemQuantityCall = async (requestBody: CartItemUpdate) => {
    let res = await updateCartItemQuantity(requestBody);
    if (res.status === 200) {
      toast.success('Item added to cart successfully!');
      dispatch(getWishListCountAction());
      dispatch(getCartCountAction());
    } else {
      toast.error('Something went wrong!');
    }
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#2C2C2C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={() => {
          openWhishlistDrawer();
        }}
        className="cursor-pointer group">
        <path
          className="group-hover:stroke-primary transition-all duration-150"
          d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
      </svg>
      <Drawer
        isOpen={drawers?.isWishListOpen}
        title={`Wishlist Products`}
        position={'right'}>
        <section className="max-h-[35rem] overflow-y-scroll">
          {wishListData && wishListData.length > 0 ? (
            wishListData.map((item, index) => {
              return (
                <div key={index}>
                  <main className="grid auto-rows-auto grid-cols-4 max-w-sm w-full mx-auto">
                    {/* img */}
                    <section className="mr-5 col-span-1">
                      <img
                        src={item?.productEntity?.compressProductImageUrl}
                        alt="product"
                        className="w-full h-auto"
                      />
                    </section>
                    {/* details */}
                    <section className="col-span-3">
                      <section className="flex justify-between pb-1">
                        <p className="text-box-text">
                          {item?.productEntity?.productName}
                        </p>
                        <button
                          onClick={() => {
                            onRemoveItemClick(item);
                          }}>
                          x
                        </button>
                      </section>
                      <section>Rs. {item?.productEntity.productPrice}</section>
                      <button
                        className="w-full bg-primary text-white mt-2 px-6 py-2 hover:bg-text-title"
                        onClick={() => {
                          onAddToCartClick(item);
                        }}>
                        Add to cart
                      </button>
                    </section>
                  </main>
                  <hr className="m-2 max-w-sm w-full mx-auto" />
                </div>
              );
            })
          ) : (
            <div className="mt-16 flex flex-col items-center justify-center">
              <EmptyWishlist className="h-28" />
              <h1 className="text-text-title font-bold text-xl mt-6">
                Your Wishlist is Empty!
              </h1>
              <p className="mt-5 text-text-body">
                Explore more and shortlist some item
              </p>
              <Link
                onClick={() => {
                  setIsOpenDrawer(false);
                }}
                to="/products"
                className="px-4 text-center w-full py-3 bg-primary text-white hover:bg-text-title uppercase font-medium mt-28">
                start shopping
              </Link>
            </div>
          )}
        </section>
      </Drawer>
    </>
  );
};

export default WishListPage;
