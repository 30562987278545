import Breadcrumbs from './Breadcrumbs';
import ProductCategory from './ProductCategory';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

interface Props {
  totalRecords?: number;
  productCategory?: string;
  productBrand?: string;
  breadcrumbArray?: string[];
}

const ProductHeader = ({
  totalRecords,
  productCategory,
  breadcrumbArray
}: Props) => {
  const productListingConfig = useSelector(
    (state: RootState) => state.productListingConfig
  );
  return (
    <section className="py-4  px-5 lg:px-20 bg-bg-primary relative">
      <Breadcrumbs
        breadcrumbArray={
          breadcrumbArray
            ? breadcrumbArray
            : ['home', productListingConfig?.selectedCategory]
        }
      />
      <ProductCategory
        productCategory={productCategory}
        totalRecords={totalRecords ? totalRecords : 0}
      />
    </section>
  );
};

export default ProductHeader;
