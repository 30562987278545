import { CartItemUpdate, Extra } from '../types/type';

import { api } from './api';
import axios from 'axios';
import { config } from '../config/config';

/** getCartDataList - This function use to get all cart item list. */
export const getCartDataList = async () => {
  const result = await axios.get(`${
        config.BASE_URL
      }/cart?currentDate=${getDate()}&currentTime=${getTime()}`,
      {
        headers: {
              Accept: 'application/json,text/plain',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
      })
      .then((res) =>{
        return res.data;
      })
      .catch((err)=>{
        throw err;
      })
      return result
};

/** updateCartItemQuantity - This function use to update quantity of cart item. */
export const updateCartItemQuantity = async (requestBody: CartItemUpdate) => {
  const response = await axios.post(
    `${config.BASE_URL}/cart/v1`,
    {
      ...requestBody
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
  );
  return response;
};

/** getDate - This function use to get date in YYYY/MM/DD format. */
export const getDate = () => {
  const date = new Date().toISOString();
  const dateTimeObject = date.split('T');
  return dateTimeObject[0];
};

/** getTime - This function use to get time in hh/ss. */
export const getTime = () => {
  const currentTime =
    (new Date().getHours() < 10 ? '0' : '') +
    new Date().getHours() +
    ':' +
    (new Date().getMinutes() < 10 ? '0' : '') +
    new Date().getMinutes();
  return currentTime;
};

/** getCartDataList - This function use to get all wish list item. */
export const getWishListData = async () => {
  const result = await axios.get(`${config.BASE_URL}/cart/wishlist`,
  {
    headers: {
          Accept: 'application/json,text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
  })
  .then((res)=>{
    return res.data
  })
  .catch((err)=>{
    throw err
  })
  return result;
};

/**
 * addToWishList - adds product to wishlist
 * @param {string} product - product id
 * @returns json response
 */
export const addToWishlist = async (
  product: string,
  wishlist: boolean = true
) => {
  const requestBody = {
    wishlist: wishlist,
    cart: false,
    product: product,
    quantity: 1,
    status: true,
    extra: []
  };
  const result = axios.post(`${config.BASE_URL}/cart/v1`,
    requestBody,
    {
      headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
    }
  ).then((res)=>{
    return res.data
  })
  .catch((err)=>{
    throw err
  })
  return result
};

/**
 * addToCart - adds product to cart
 * @param {string} product - product id
 * @param quantity - quantity of products
 * @returns json response
 */
export const addToCart = async (
  product: string,
  quantity = 1,
  extra?: Array<Extra>
) => {
  const requestBody = {
    replace: true,
    cart: true,
    product: product,
    quantity: quantity,
    status: true,
    extra: extra || []
  };
  const result = await axios.post(`${api}/cart/v1`,
      requestBody,
    {
      headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
    })
    .then((res)=>{
      return res.data
    })
    .catch((err)=>{
      throw err
    })
    return result;
};

/**
 * getCartCount - async function to fetch items in cart
 * @returns json data of items in cart
 */
export const getCartCount = async () => {
  const result = await axios.get(`${api}/cart/v1/cartcount`,{
    headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
  })
  .then((res) =>{
    return res.data
  })
  .catch((err)=>{
    throw err
  })
  return result;
};

/**
 * getWishListcount - async function to fetch wishlist count
 * @returns json data of wishlist
 */
export const getWishListCount = async () => {
  const result = await axios.get(`${api}/cart/wishlist`,
  {
    headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }}
  )
  .then((res)=>{
    return res.data;
  })
  .catch((err)=>{
    throw err
  })
  return result;
};

/**
 * resetCart - api call to completely reset the cart of the user
 * @returns json response
 */
export const resetCart = async () => {
  const result = await axios.put(`${api}/cart/resetcart`,{},
  {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
  })
  .then((res) =>{
    return res.data;
  })
  .catch((err) =>{
    throw err;
  })
  return result;
};
