import { ButtonProps } from "../../../types/type"

const Button = (props: ButtonProps) => {
    return (
        <button
            className={props.className}
            onClick={()=>props.onClick()}
        >
            {props.title}
        </button>
    )
}

export default Button
